var myObjectPath = require("object-path");

export const WITH_EMITCHANGE = true;
export const WITHOUT_EMITCHANGE = false;

export default class SingleStore {

    constructor() {
        this.storeName = 'generic';
        this.syncMaster = undefined;
        this.data = undefined;
        this.emitChangeInfos = {
            'delete': false,
            'update': false,
        };
        this.onSyncInfo = this.onSyncInfo.bind(this);

        // --- To check if the store is filled or empty
        this.isFilled = false;

        // --- List of listeners on changements
        this.listeners = [];

        // --- to prepare requests ---
        this.ask = {};

        // --- isWorking ?
        this.isWorking = false;

        // --- Debug in case of ---
        this.debugFunc = undefined;
    }


    setDebugFunc(f) {
        this.debugFunc = f;
    }

    debug(type, message, data) {
        if (!this.debugFunc) return false;
        this.debugFunc(type, message, data)
    }

    getField(f) {
        return myObjectPath.get(this.data, f);
    }

    get _id() {
        return this.getField('_id');
    }

    /**
     * Return true if the store is initialised
     * 
     */
    get filled() {
        return this.data ? true : false;
    }

    /**
     * return true if the store is filled
     * but without any data
     * 
     */
    get empty() {
        return !this.filled;
    }

    /**
     * 
     * add a Listener on all changements, new ids, or updates
     * 
     * @param {function} func 
     * 
     */
    addListener(func) {
        this.debug('SingleStore', `Adding listener to ${this.name}`);
        this.listeners.push(func);
    }
    addChangeListener(func) {
        return this.addListener(func);
    }

    removeListener(func) {
        const index = this.listeners.indexOf(func);
        if (index > -1) this.listeners.splice(index, 1);
    }
    removeChangeListener(func) {
        return this.removeListener(func);
    }

    emitChange() {

        // --- No changements ???
        if ((this.emitChangeInfos['delete'] === false) &&
            (this.emitChangeInfos['update'] === false)) return;

        this.debug('SingleStore', `emitChange `, this.emitChangeInfos);

        const a = {
            'delete': this.emitChangeInfos['delete'],
            'update': this.emitChangeInfos['update']
        }

        // this.debug('SingleStore', `${this.name} emitchange`, this.listeners);

        this.emitChangeInfos = {
            'delete': false,
            'update': false,
        };

        for (const func of this.listeners) {
            func(a);
        }

        return true;
    }

    /**
     * called by the SyncMaster when some updates comes
     * from another user.
     * 
     * @param {object} syncInfos 
     */
    async onSyncInfo(syncInfos) {

        this.debug('SingleStore', `SingleStore onSyncInfo`, syncInfos);

        this.emitChangeInfos = {
            'delete': false,
            'update': false,
        };

        this.debug('SingleStore', `SingleStore Got update from others for ${this.storeName}`, syncInfos);
        if (syncInfos.delete) {
            this.emitChangeInfos.delete = true;
            this.data = undefined;
            this.emitChange();
            return true;
        }
        if (syncInfos.update) {
            this.emitChangeInfos.update = true;
            this.data = await this.syncMaster.httpGet(`/${this.storeName}`);
            this.emitChange();
            return true;
        }

        return false;
    }

    getStoreByName( name ) {
        return this.syncMaster.stores[name] ;
    }

    /**
     * link with the syncMaster to get updates
     * from server.
     * 
     * @param {SyncMaster} syncMaster 
     */
    registerTo(syncMaster) {
        this.syncMaster = syncMaster;
        this.syncMaster.onSyncInfo[this.storeName] = this.onSyncInfo;
        this.syncMaster.stores[this.storeName] = this;
    }

    getStoreByName( name ) {
        return this.syncMaster.stores[name] ;
    }


    postAction(name, _ids, data) {
        return this.action({
            'auth': true,
            'method': 'post',
            'name': name,
            '_ids': _ids,
            'data': data,
        });
    }
    putAction(name, _ids, data) {
        return this.action({
            'auth': true,
            'method': 'put',
            'name': name,
            '_ids': _ids,
            'data': data,
        });
    }
    getAction(name, _ids, data) {
        return this.action({
            'auth': true,
            'method': 'get',
            'name': name,
            '_ids': _ids,
            'data': data,
        });
    }
    deleteAction(name, _ids, data) {
        return this.action({
            'auth': true,
            'method': 'delete',
            'name': name,
            '_ids': _ids,
            'data': data,
        });
    }

    postAnonAction(name, _ids, data) {
        return this.action({
            'auth': false,
            'method': 'post',
            'name': name,
            '_ids': _ids,
            'data': data,
        });
    }
    putAnonAction(name, _ids, data) {
        return this.action({
            'auth': false,
            'method': 'put',
            'name': name,
            '_ids': _ids,
            'data': data,
        });
    }
    getAnonAction(name, _ids, data) {
        return this.action({
            'auth': false,
            'method': 'get',
            'name': name,
            '_ids': _ids,
            'data': data,
        });
    }
    deleteAnonAction(name, _ids, data) {
        return this.action({
            'auth': false,
            'method': 'delete',
            'name': name,
            '_ids': _ids,
            'data': data,
        });
    }


    async action(d) {
        this.isFilled = true;
        this.isWorking = true;

        const element = await this.httpAction(d)
            .catch((e) => {
                this.isWorking = false;
                throw e;
            });

        this.isWorking = false;
        // if ( typeof element === 'object' ) this.data = element;
        return element;
    }

    httpPost(url, data, auth) {
        return this.syncMaster.httpDirectPost(url, {
            vue: 0
        }, data, auth);
    }
    httpGet(url, data, auth) {
        return this.syncMaster.httpDirectGet(url, {
            vue: 0
        }, data, auth);
    }
    httpPut(url, data, auth) {
        return this.syncMaster.httpDirectPut(url, {
            vue: 0
        }, data, auth);
    }
    httpDelete(url, auth) {
        return this.syncMaster.httpDirectDelete(url, auth);
    }

    httpAction(d) {
        if (!this.syncMaster) throw new Error(`${this.storeName} not registerd to a syncManager !`)
        const sIds = Array.isArray(d._ids) ? d._ids.join(',') : d._ids;

        const url = d._ids ? `/schmluxaction/${this.storeName}/${d.name}/${sIds}` : `/schmluxaction/${this.storeName}/${d.name}`;

        switch (d.method) {
            case 'post':
                return this.syncMaster.httpPost(url, {
                    vue: 0
                }, d.data, d.auth);
            case 'get':
                return this.syncMaster.httpGet(url, {
                    vue: 0
                }, d.auth);
            case 'put':
                return this.syncMaster.httpPut(url, {
                    vue: 0
                }, d.data, d.auth);
            case 'delete':
                return this.syncMaster.httpDelete(url, d.auth);
            default:
                throw new Error(`Unknown method ${d.method}`)
        }
    }

    addDeleteToEmitChange() {
        this.emitChangeInfos.delete = true;
    }
    addUpdateToEmitChange() {
        this.emitChangeInfos.update = true;
    }

    async drop(emitChange = WITHOUT_EMITCHANGE) {
        await this.syncMaster.httpGet(`/schmlux/${this.storeName}`);
        this.data = undefined;
        this.addDeleteToEmitChange();
        if (emitChange) this.emitChange();
        return true;
    }

    /**
     * Fill the Store
     */
    async getElement() {
        if (this.data) return this.data;
        if (!this.syncMaster) throw new Error(`${this.storeName} not registerd to a syncManager !`)
        this.data = await this.syncMaster.httpGet(`/${this.storeName}`);
        this.addUpdateToEmitChange();
        return this.data;
    }

    httpPatch(fields) {
        if (!this.syncMaster) throw new Error(`${this.storeName} not registerd to a syncManager !`)
        return this.syncMaster.httpPut(`/${this.storeName}`, {}, fields);
    }

    async update(fields, emitChange = WITHOUT_EMITCHANGE) {
        const d = await this.httpPatch(fields);
        if (!d) return false;
        this.data = d;
        this.addUpdateToEmitChange();
        if (emitChange) this.emitChange();
        return true;
    }
}