require('socket.io-client');
import req from 'request-promise-native';
import urlPath from 'url';

export default class SyncMaster {
    /**
     * build the manager for all users for all stores
     *
     */
    constructor(socketIoClient, loggedPrefix, anonymousPrefix, globalURL, handleUnauthorized) {

        this.http = this.http.bind(this);
        this.onSocket = this.onSocket.bind(this);
        this.reconnect = this.reconnect.bind(this);
        this.quitSocket = this.quitSocket.bind(this);
        this.leaveStores = this.leaveStores.bind(this);

        this.onSyncInfo = {};
        this.onLeave = {};
        this.stores = {};
        this.loggedPrefix = loggedPrefix;
        this.anonymousPrefix = anonymousPrefix;

        this.globalURL = globalURL; // ? globalURL : document.location.href;

        this.handleUnauthorized = handleUnauthorized ? handleUnauthorized : () => {
            return false;
        };

        this.getCustomHeader = undefined;

        // --- In case of not in the browser, must manage cookies --
        this.authCookie = undefined;
        this.socketIoClient = socketIoClient;

        // --- Debug in case of ---
        this.debugFunc = undefined;
    }

    /**
     * Get handler to add custom header to request.
     *
     * @param {Function} handler
     */
    setGetCustomHeader(handler) {
        this.getCustomHeader = handler;
    }

    setUnauthorizedHandler(handler) {
        this.handleUnauthorized = handler;
    }

    setDebugFunc(f) {
        this.debugFunc = f;
    }

    debug(type, message, data) {
        if (!this.debugFunc) return false;
        this.debugFunc(type, message, data)
    }

    startSocket() {
        // if ( ! this.authCookie ) throw new Error(`no cookies`);
        const url = this.globalURL ?
            `${this.globalURL}${this.loggedPrefix}/schmlux/sync` :
            urlPath.resolve(document.location.href, `${this.loggedPrefix}/schmlux/sync`);

        this.debug('SyncMaster', `start socket to ${url} `, this.authCookie);
        this.socket = this.socketIoClient(url, {
            extraHeaders: {
                Cookie: this.authCookie ? this.authCookie[0] : undefined
            }
        });

        this.socket.on('news', this.onSocket);
        this.socket.on('disconnect', this.reconnect);
        this.socket.on('quit', this.quitSocket);
    }

    checkSocket(s) {
        return new Promise((resolve) => {
            this.socket.on('test', (msg) => {
                resolve(msg);
            });
            this.socket.emit('test', s);
        })
    }

    /**
     * Reconnect socket
     * in case of lost connection with the server
     *
     * @param {string} reason
     */
    reconnect(reason) {
        if (reason === 'io server disconnect') {
            this.debug('SyncMaster', `reconnection`);
            return this.socket.connect();
        }
    }

    quitSocket() {
        this.debug('SyncMaster', `Quit socket`);
        const proms = [];
        this.leaveStores(proms);

        Promise.all(proms)
            .then(() => {
                if (this.socket) {
                    this.debug('SyncMaster', `Quit socket close`);
                    return this.socket.close();
                }
            })
            .catch(() => {
                if (this.socket) {
                    this.debug('SyncMaster', `Quit socket close`);
                    return this.socket.close();
                }

            })

    }

    leaveStores(proms) {
        for (const storeName in this.onLeave) {
            proms.push(this.onLeave[storeName]());
        }
    }

    /**
     * disconnect the socket
     */
    disconnect() {
        if (this.socket) {
            this.debug('SyncMaster', `Disconnect socket`, this.authCookie);
            this.socket.emit('disconnect');
            return this.socket.close();
        }
    }

    onSocket(data) {
        this.debug('SyncMaster', `socket got news`, data);
        for (const storeName in data) {
            if (this.onSyncInfo[storeName]) this.onSyncInfo[storeName](data[storeName]);
        }
        return true;
    }

    http(path, method, query, data, auth = true) {
        const headers = {
            'content-type': 'application/json',
            "Cookie": this.authCookie ? this.authCookie[0] : undefined
        };

        if (this.getCustomHeader) {
            const o = this.getCustomHeader();
            for (const key in o) {
                headers[key] = o[key];
            }
        }


        // --- Add the query string to the URL ---
        const queryString = this.queryToString(query);

        const pref = auth ? this.loggedPrefix : this.anonymousPrefix;

        let url;
        if (path[0] === '/') {
            url = path;
            url = this.globalURL ?
                `${this.globalURL}${pref}${path}` :
                urlPath.resolve(document.location.href, `${pref}${path}`);
        } else {
            url = path;
        }


        // let url = path[0] == '/' ? `${this.globalURL}${pref}${path}` : path;
        if (queryString) url += `?${queryString}`;

        this.debug('SyncMaster', `${method} to ${url} with auth=${auth}`, this.authCookie);

        return req({
            "url": url,
            "method": method,
            "datatype": "application/json",
            "contentType": "application/json",
            "headers": headers,
            "credentials": 'same-origin',
            "json": true,
            "resolveWithFullResponse": true,
            "body": data
        })
            .then((response) => {
                // --- store the auth cookie --
                if ((this.globalURL) && (response.headers["set-cookie"])) {
                    this.authCookie = response.headers["set-cookie"];
                }
                return (response.body);
            })
            .catch(async (e) => {
                this.debug('SyncMaster', `error from server `, e);
                if (e.statusCode === 404)
                    return undefined;
                // Bof bof...
                if (e.statusCode === 401 && e.response.headers["www-authenticate"] === "2FA") {
                    if (!this.handleUnauthorized) {
                        throw e;
                    }
                    return await this.handleUnauthorized(async () => {
                        return await this.http(path, method, query, data, auth);
                    });
                }
                throw e;
            })
    }

    initAuthStores() {
        return this.httpGet('/init');
    }

    httpPost(path, query, data = undefined, auth = true) {
        return this.http(path, 'POST', query, data, auth);
    }

    httpPut(path, query, data = undefined, auth = true) {
        return this.http(path, 'PUT', query, data, auth);
    }

    httpGet(path, query, auth = true) {
        return this.http(path, 'GET', query, undefined, auth);
    }

    httpDelete(path, auth = true) {
        return this.http(path, 'DELETE', undefined, undefined, auth);
    }

    httpDirectPost(path, query, data = undefined, auth = true) {
        const p = (typeof document === 'undefined') ? this.globalURL : document.location.href;
        return this.http(urlPath.resolve(p, path), 'POST', query, data, auth);
    }

    httpDirectPut(path, query, data = undefined, auth = true) {
        const p = (typeof document === 'undefined') ? this.globalURL : document.location.href;
        return this.http(urlPath.resolve(p, path), 'PUT', query, data, auth);
    }

    httpDirectGet(path, query, auth = true) {
        const p = (typeof document === 'undefined') ? this.globalURL : document.location.href;
        return this.http(urlPath.resolve(p, path), 'GET', query, undefined, auth);
    }

    httpDirectDelete(path, auth = true) {
        const p = (typeof document === 'undefined') ? this.globalURL : document.location.href;
        return this.http(urlPath.resolve(p, path), 'DELETE', undefined, undefined, auth);
    }

    /**
     * return a query object as parameters in URL
     *
     * @param {object} query
     */
    queryToString(query = {}) {
        const arr = [];
        for (const key in query) {
            let s = "";
            const v = query[key];
            if (Array.isArray(v)) {
                s = `${key}=${v.join(',')}`;
                arr.push(s);
                continue;
            }
            if (v === true) {
                s = `${key}=`;
                arr.push(s);
                continue;
            }
            if (v === false) {
                continue;
            }
            s = `${key}=${v}`;
            arr.push(s);
        }
        if (arr.length === 0) return undefined;
        return arr.join('&');
    }

}