
const STACKSIZE = 30;
var stack = [];

/**
 * 
 * Push the message into the stack.
 * the stack is used in cas of error to follow how the error
 * append.
 * 
 * @param {String} type type of message (error, log, warn)
 * @param {String} message 
 * @param {*} data 
 */
export const appendStack = ( type, message, data ) => {
  stack.push({
    "type" : type,
    "message" : message,
    "data" : data
  });
  if ( stack.length > STACKSIZE ) stack.shift();
}


// =========================================================================================
// ============ lynvictus Errors ===========================================================
// =========================================================================================
export class LynError extends Error {
  constructor(message, props = undefined) {
    super(message);
    this.name = "LynError";
    this.props = props;
    this.debugStack = stack;
    this.message = message;
    console.error("LynError (" + message + ")", props);
    console.error("LynError stack", this.stack );
  }

  get content() {
    return ({
      "name": this.name,
      "code": undefined,
      "props": this.props,
      "debugStack": this.debugStack,
      "message": this.message
    });
  }

}