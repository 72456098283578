import {
  objectGet
} from './Tools.js'
import {
  Checker,
  OWNER
} from './Checker.js';
import {
  ANONYMOUS_ACTOR
} from './Constants.js';

// ---- For debugging ------
import Debug from './DebugObj'


/*

      example 

      {
        'global' : {
          'init' : () => { return 'READY' },
        },
        'READY' : {
          'displayName' : 'ready',
          'exitNotify' :undefined,
          'enterNotify' : undefined,
          'exitAction' : undefined,
          'enterAction' : undefined,
          'enterRight' : undefined,
          'LOCK' : {
            'actionName' : 'Lock',
            'icon' : 'rights',
            'enterRight' : this.canLock,
            'exitNotify' : undefined,
            'enterNotify' : undefined,
            'exitAction' : undefined,
            'enterAction' : undefin
        },
        'LOCK' : {
          'exitNotify' :undefined,
          'exitAction' : undefined,
          'enterAction' : undefined,
          'enterRight' : undefined,
          'displayName' : 'locked',
          'READY' : {
            'actionName' : 'Unlock',
            'icon' : 'free',
            'enterRight' : this.canUnLock,
            'exitNotify' : undefined,
            'enterNotify' : undefined,
            'exitAction' : undefined,
            'enterAction' : undefined,
          }
        },
      });

*/

const CHECK_NEW_STATE = new Checker({
  'right': 'function',
  'enterNotify': 'function',
  'exitNotify': 'function',
  'enterAction': 'function',
  'exitAction': 'function',
  'label': 'string',
  'confirm': 'string',
  'finish': 'string',
  'leave': 'function'
}, "newStateChecker");

const CHECK_STATE = new Checker({
  'enterRight': 'function',
  'enterNotify': 'function',
  'exitNotify': 'function',
  'enterAction': 'function',
  'exitAction': 'function',
  'displayName': 'string',
}, "stateChecker");


export class WorkFlow {
  constructor(name, states) {

    this.name = name;
    
    new Checker({
      'global': {
        'visible': ['in', 'function', 'boolean', 'null'],
        'init': ['in', 'function+', 'string+', 'null'],
        'import': ['in', 'function+', 'string+', 'null']
      }
    }, "WorkFlow").checkThrow(states);

        // --- Set the debug --
        this.deb = new Debug('WorkFlow');
        this.l = this.deb.l;
        this.log = this.deb.log;
        this._false = this.deb._false;
        this._true = this.deb._true;
        // --- Set the debug --


    for (let state in states) {
      if (!this.isUpperCase(state)) continue;
      CHECK_STATE.checkThrow(states[state])
      for (let newState in states[state]) {
        if (!this.isUpperCase(newState)) continue;
        CHECK_NEW_STATE.checkThrow(states[state][newState])
      }
    }

    // --- The current state 
    this.state = undefined;

    // -- moving the 'global' part into a specific attribut of this object
    this.global = objectGet(states, 'global', {});

    // -- states descriptions
    this.states = states;
    //delete( this.states.global );

    // -- The date of entering this state
    this.stateDate = undefined;
    this.history = [];

    this.changeState = this.changeState.bind(this);

    //this.log(`workflow ${this.name} creation`, arguments )
  }

  isUpperCase(str) {
    if (!str) return false;
    return str === str.toUpperCase();
  }

  /**
   * 
   * Initialize the workflow (set its initial state)
   * 
   * @param {object} obj 
   * @param {object} data 
   * @param {string} comment 
   */
  async init(obj, data, comment) {
    let initFunc = objectGet(this.global, 'init');
    if (!initFunc) return ("no global.init in this workflow");
    this.log(`workflow ${this.name} init`, arguments )

    let state;
    if (typeof (initFunc) == 'string') {
      state = initFunc;
    } else {
      state = await initFunc(obj);
    }

    if (!state) return ("global.init doesnt return a state");
    if (!this.states[state]) return ("global.init return an object which is not a state");

    // --- Do actions -- 
    this.doEnterAction(obj, '_INIT_', state, data);

    // --- Notifiy ---
    await this.notifyEnter(obj, '_INIT_', state, data, comment);

    this.log(`workflow ${this.name} init to state `+state )

    this.state = state;
    this.stateDate = Date.now();
    this.stateActor = ANONYMOUS_ACTOR;
    this.history = [];

    return true;
  }


  /**
   * 
   * Import status for object not with the workflow (set its initial state)
   * 
   * @param {object} obj 
   * @param {object} data 
   * @param {string} comment 
   */
  dataImport(obj, owner = ANONYMOUS_ACTOR ) {
    let dataImportFunc = objectGet(this.global, 'dataImport');
    this.log(`dataImport for ${this.name} `, dataImportFunc)
    if (!dataImportFunc) {
      console.warn("no dataImport defined for workflow "+this.name+". I need it.")
      return true;
    }
    this.log(`workflow ${this.name} import ${this.state}`, dataImportFunc )
    if ( this.state ) return true;

    let state;
    if (typeof (dataImportFunc) == 'string') {
      state = dataImportFunc;
    } else {
      state = dataImportFunc(obj);
    }

    if (!state) return ("global.dataImport doesnt return a state");
    if (!this.states[state]) return ("global.dataImport return an object which is not a state");

    this.state = state;
    this.stateDate = Date.now();
    this.stateActor = owner;
    this.history = [];
    this.log(`workflow ${this.name} import done successfuly in state `+this.state )

    return true;
  }


  /**
   * change the state into the workflow and do all
   * actions ( actions, notify, ... ) according to the configuration
   * 
   * @param {object} obj 
   * @param {string} newState 
   * @param {object} data 
   * @param {string} comment 
   */
  async changeState(obj, newState, data, comment, owner = ANONYMOUS_ACTOR) {
    this.log(`workflow ${this.name} changeState`, arguments)

    this.log(`changeState for ${this.name} `, this);


    if (!this.isUpperCase(newState)) return ("state " + newState + " must be uppercase.");

    //--- the state doesnt exists
    if (!this.states[newState]) return ("state " + newState + " doesnt exists");

    //--- not allow to go to this state
    if (!this.isAllowedToChangeToState(newState)) return ("cannot move from state " + this.state + " to " + newState + " !");

    // -- Can i have right to change state
    if (!this.canChangeState(obj, this.state, newState)) return ("you doesnt have right to change state " + this.state + " to " + newState + " !");

    // --- do some exit actions ---
    await this.doExitAction(obj, this.state, newState, data);
    await this.doEnterAction(obj, this.state, newState, data);


    // --- Notify users -----
    await this.notifyExit(obj, this.state, newState, data, comment);
    await this.notifyEnter(obj, this.state, newState, data, comment);

    if (this.state) {
      this.history.push({
        state: this.state,
        stateDate: this.stateDate,
        stateActor: this.stateActor
      });
    }

    this.state = newState;
    this.stateDate = Date.now();
    this.stateActor = owner;

    this.log('State changed for ${this.name}, new state id ' + this.state);
    return true;
  }

  /**
   * 
   * @param {string} state 
   * check if can move to this new State from the current one
   */
  isAllowedToChangeToState(state) {
    return objectGet(this.states, this.state + '.' + state) ? true : false;
  }

  canChangeState(obj, oldState, newState) {
    let func = objectGet(this.states, oldState + '.' + newState + '.right');

    if (func) return func(obj, oldState, newState);

    func = objectGet(this.states, newState + '.right');
    if (func) return func(obj, oldState, newState);
    return true;
  }

  doExitAction(obj, oldState, newState, data) {
    let func = objectGet(this.states, oldState + '.' + newState + '.exitAction');
    if (func) {
      func(obj, data);
      return true;
    }

    func = objectGet(this.states, newState + '.exitAction');
    if (func) func(obj, data);
    return true;
  }

  doEnterAction(obj, oldState, newState, data) {
    this.log(`doEnterAction`, arguments)
    let func = objectGet(this.states, oldState + '.' + newState + '.enterAction');
    if (func) {
      func(obj, oldState, newState, data);
      return true;
    }

    func = objectGet(this.states, newState + '.enterAction');
    if (func) func(obj, oldState, newState, data);
    return true;
  }

  async notifyExit(obj, oldState, newState, data, comment) {
    let func = objectGet(this.states, oldState + '.' + newState + '.exitNotify');
    if (func) {
      await func(obj, data, comment);
      return true;
    }

    func = objectGet(this.states, newState + '.exitNotify');
    if (func) await func(obj, data, comment);
    return true;
  }

  async notifyEnter(obj, oldState, newState, data, comment) {
    let func = objectGet(this.states, oldState + '.' + newState + '.enterNotify');
    if (func) {
      await func(obj, data, comment);
      return true;
    }

    func = objectGet(this.states, newState + '.enterNotify');
    if (func) await func(obj, data, comment);
    return true;
  }

  /*
   *  ██████╗ ███████╗████████╗████████╗███████╗██████╗ ███████╗
   * ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔════╝██╔══██╗██╔════╝
   * ██║  ███╗█████╗     ██║      ██║   █████╗  ██████╔╝███████╗
   * ██║   ██║██╔══╝     ██║      ██║   ██╔══╝  ██╔══██╗╚════██║
   * ╚██████╔╝███████╗   ██║      ██║   ███████╗██║  ██║███████║
   *  ╚═════╝ ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚═╝  ╚═╝╚══════╝
   */


  isVisible(obj) {    
    this.log(`workflow ${this.name} isVisible check `, obj )

    if (!this.global) return true;
    let v = this.global.visible;
    if (v === false) return false;
    if (v === true) return true;
    if (!v) return true;
    let result = v(obj);
    this.log(`workflow ${this.name} isVisible = `+result, obj )
    return result;

  }

  getAvailableActions(obj) {
    if (! this.isVisible(obj)) return [];
    let current = this.states[this.state];
    if (!current) return [];
    //let defaultRight = current.enterRight;

    let a = [];
    for (let nextState in current) {
      // --- States must be in uppercase
      if (!this.isUpperCase(nextState)) continue;
      let action = {
        "_id": nextState,
        "displayName": this.states[nextState].displayName,
        "leave": current[nextState].leave,
        "label": current[nextState].label,
        "icon": current[nextState].icon ? current[nextState].icon : this.states[nextState].icon,
        'enabled': this.canChangeState(obj, this.state, nextState),
        'action': (obj, data, comment) => {
          return this.changeState(obj, nextState, data, comment)
        },
        "finish": current[nextState].finish,
        "confirm": current[nextState].confirm,
      };
      a.push(action);
    }
    return a;
  }


  asImportantActionToDo( obj ) {
    if (! this.isVisible(obj)) return false;
    let current = this.states[this.state];
    if (!current) return false;
    for (let nextState in current) {
      // --- States must be in uppercase
      if (! this.isUpperCase(nextState)) continue;
      if (! this.canChangeState(obj, this.state, nextState) ) continue;
      if ( current[nextState].important ) return true;
    }
    return false;
  }

  isInState(s) {
    if (Array.isArray(s)) return s.indexOf(this.state) > -1 ? true : false;
    if (s == this.state) return true;
    return false;
  }

  setState(data, obj) {
    this.log(`setState from db for ${this.name} `, data);
    if (!data) return false;
    let r = new Checker({
      'state': 'string+',
      'stateDate': 'int',
      'stateActor': OWNER,
      'history': [{
        'state': 'string+',
        'stateDate': 'int',
        'stateActor': OWNER,
      }]
    }, 'workFlow setState').check(data);

    if (r !== true) {
      this.log( `setState for ${this.name} not regular : ${r}`, data)
      return (r);
    }
    if (!this.isUpperCase(data.state)) return ("state " + data.state + " must be uppercase.");

    //--- the state doesnt exists
    if (!this.states[data.state]) return ("state " + data.state + " doesnt exists");
    let oldState = this.state
    this.state = data.state;
    this.stateDate = data.stateDate ? data.stateDate : Date.now();
    this.stateActor = data.stateActor ? data.stateActor : ANONYMOUS_ACTOR;
    this.history = data.history ? data.history : [];
    this.log(`setState from db for ${this.name} return `, this);
    this.doEnterAction(obj, oldState, this.state);
    return true;
  }

  get stateDisplayName() {
    let current = this.states[this.state];
    return objectGet(current, 'displayName', this.state);
  }

  getDisplayedStatus(obj) {
    if (!this.isVisible(obj)) {
      return false;
    }
    let current = this.states[this.state];
    let visible = objectGet(current, 'visible', true);
    if (visible === false) return false;
    return ({
      icon: objectGet(current, 'icon'),
      text: objectGet(current, 'displayName'),
      tooltip: objectGet(current, 'tooltip'),
    })
  }

  getState() {
    return ({
      state: this.state,
      stateDate: this.stateDate,
      stateActor: this.stateActor,
      history: this.history,
    })
  }

}