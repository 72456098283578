// ---- Autogenerate by translate.js (BW) ----------
// automatically translated with Deepl and corresponding API

// --------------
// -------------- Part inter
// --------------
export const inter = {
    "de": {
        "Chats": "Diskussionen",
        "App config": "App-Konfiguration",
        "Send messages": "Diskussionen",
        "Status": "Satzung",
        "createUser": "Benutzer erstellen",
        "createFileset": "Tresore erstellen",
        "createQuorumGroup": "Erstellen von Quoren",
        "createGroup": "Gruppen erstellen",
        "manage": "Vorhandene Berechtigungen ändern",
        "Reply": "Antwort",
        "Home": "Startseite",
        "New repository": "Neuer Kofferraum",
        "New url": "Neue Einladung",
        "URLs": "Einladungen",
        "GCU": "Allgemeine Nutzungsbedingungen",
        "Administration": "Verwaltung",
        "Configuration": "Konfiguration",
        "Quorums": "Beschlussfähigkeit",
        "Settings": "Einstellungen",
        "Size": "Größe",
        "About": "Ein Vorschlag",
        "Vault": "Coffre",
        "Vaults": "Kaffees",
        "Help": "Helfer",
        "Guest": "Gast",
        "Enter": "Geben Sie  ein.",
        "Decrypt": "Entziffern Sie",
        "Delete": "Löschen Sie",
        "Logout": "Abmelden",
        "Later": "Später",
        "Refuse": "Verweigerer",
        "Close": "schließen",
        "Accept": "Akzeptieren Sie",
        "Sort by": "Sortieren nach",
        "Sorted by": "sortiert nach",
        "Edit": "bearbeiten",
        "Send a customized notification email": "Senden Sie eine personalisierte E-Mail-Benachrichtigung",
        "Edit parameters": "Einstellungen ändern",
        "Name": "Nom",
        "Expire": "Verfällt am",
        "Properties": "Eigenschaften",
        "Change password": "Kennwörter",
        "Identity": "Identität",
        "Last Update": "Zuletzt geändert",
        "Expiration date": "Verfallsdatum",
        "Creation date": "Erstellungsdatum",
        "Last connection": "Letzte Anmeldung",
        "Users": "Benutzer",
        "Rights": "Berechtigungen",
        "Rename": "umbenennen",
        "Cut": "Schnitt",
        "Paste": "Coller",
        "Copy": "Kopie",
        "Open": "Öffnen Sie",
        "File ready to be moved": "Datei bereit zum Verschieben",
        "Manage rights": "Verwaltung der Rechte von",
        "Yes": "Oui",
        "No": "Nicht",
        "Cancel": "Abbruch",
        "no informations": "Keine Informationen",
        "Loading": "Laden von",
        "Dataroom crypted": "Verschlüsselter Safe",
        "Owner": "Eigentümer",
        "Details": "Einzelheiten",
        "Ok": "Ok",
        "Preferences": "Präferenzen",
        "Comments": "Kommentare",
        "General": "Allgemein",
        "Date": "Datum",
        "Crypted": "Zahlenangaben",
        "Preview": "Aperçu",
        "Use secondary click to use available actions": "Verwenden Sie die rechte Maustaste, um weitere Aktionen zu erhalten.",
        "Logged": "Verbunden",
        "Logs": "Protokolle",
        "Loading and decrypting file": "Laden und Entschlüsseln der Datei",
        "Crypted thread": "Verschlüsselte Konversation",
        "Act as": "Handeln als",
        "Go back": "Geh zurück",
        "Next": "Nächste",
        "Share": "Teilen Sie",
        "Shares": "Gemeinsame Nutzung von",
        "Select people": "Personen auswählen",
        "Add custom email": "Eine personalisierte E-Mail hinzufügen",
        "Subject": "Sujet",
        "Copy to me": "Kopieren Sie mich",
        "No action required": "Keine Maßnahmen erforderlich",
        "Prev": "Vorherige",
        "Message": "Nachricht",
        "Share with people(s)": "Mit Menschen teilen",
        "Invite": "Laden Sie  ein.",
        "Theme": "Thema",
        "Themes": "Themenbereiche",
        "Tchat": "Diskussionen",
        "There is currently no selected thread": "Derzeit gibt es keine ausgewählten Diskussionen.",
        "Chose someone in the list below": "Wählen Sie jemanden aus der folgenden Liste",
        "Select a friend": "Wählen Sie einen Freund",
        "No member(s)": "Die Auswahl ist leer.",
        "Select a user": "Wählen Sie einen Benutzer",
        "Dashboard": "Dashboard",
        "Delete my account ?": "Mein Konto löschen?",
        "Delete my account": "Mein Konto löschen",
        "Delete the fileset": "Löschen Sie den Safe",
        "New password": "Neues Kennwort",
        "Change": "Wechsler",
        "Everybody": "Alle",
        "No deposit limit": "Keine Begrenzung des Stammes",
        "No limit": "Überschreiten von Grenzwerten",
        "Me": "Moi",
        "Deposit remains": "Verbleibende Truhen",
        "Drop here your files or press the '+' button to add them": "Ziehen Sie Dateien nach unten oder drücken Sie die '+'-Taste, um Dateien hinzuzufügen.",
        "Subject of the deposit": "Gegenstand des Stammes",
        "For": "Gießen Sie",
        "Number of deposits": "Anzahl der Truhen",
        "Send Link to User ?": "Den Link direkt versenden?",
        "Create": "Erstellen Sie",
        "Url deleted": "Einladung gelöscht",
        "Saved": "Gespeichert",
        "Downloaded": "Heruntergeladen",
        "Decyphering ok": "Erfolgreiche Entzifferung",
        "Quorum group deleted": "Gruppe mit Quorum gelöscht",
        "Send": "Senden Sie an",
        "Encrypt and send": "Verschlüsseln und senden",
        "Refuse access": "Zugang verweigern",
        "Accept access": "Zugang gewähren",
        "Accept access with quorum": "Akzeptieren des Zugangs mit dem Quorum \"{name}\"",
        "Refuse access with quorum": "Zugang mit Quorum \"{name}\" verweigern",
        "Ask for access": "Zugang beantragen",
        "Cancel request": "Beenden Sie die Anwendung",
        "Remove": "Löschen Sie",
        "Fileset deleted": "\"{name}\" gelöscht",
        "Are you sure to delete fileset ?": "Sind Sie sicher, dass Sie \"{name}\" löschen wollen?",
        "Are you sure to delete file ?": "Sind Sie sicher, dass Sie die \"{name}\"-Datei löschen möchten?",
        "Are you sure to delete QuorumGroup ?": "Sind Sie sicher, dass Sie die Beschlussfähigkeitsgruppe \"{name}\" löschen wollen?",
        "Password": "Kennwort",
        "Password confirmation": "Bestätigung des Passworts",
        "Password for this key": "Passwort für diesen Schlüssel",
        "You need to change your password": "Es ist Zeit, Ihr Passwort zu ändern",
        "User created": "Benutzer erstellt",
        "New user": "Neuer Benutzer",
        "Username": "Anmeldung",
        "No account yet ?": "Noch kein Konto?",
        "Login": "Anmeldung",
        "Login LDAP": "Se-Verbinder (LDAP)",
        "Welcome to lynvictus": "Willkommen bei Lynvictus",
        "Please follow this steps for creating an account. It takes only few seconds.": "Bitte folgen Sie diesen wenigen Schritten, um ein Konto zu erstellen. Es wird nur wenige Sekunden dauern.",
        "Choose a password": "Wählen Sie ein Passwort",
        "Email": "E-Mail-Adresse",
        "OR": "OU",
        "By": "Par",
        "CREATE AN ACCOUNT": "ERSTELLUNG D'UN COMPTE",
        "Create an account": "Ein Konto erstellen",
        "Back to login": "Zurück zum Anmeldeformular",
        "Back to home": "Zurück zur Startseite",
        "LOGIN TO YOUR ACCOUNT": "ANSCHLUSS EINES",
        "I read and I accept terms and conditions": "Ich habe die allgemeinen Nutzungsbedingungen gelesen und akzeptiere sie.",
        "Already have an account ?": "Sie haben bereits ein Konto?",
        "Verify your identity by Email": "Verifizierung Ihrer Identität",
        "Log and work": "Anmelden bei",
        "A email has been sent": "Eine E-Mail wurde gesendet an",
        "Please check your email and click on the verification link.": "Beobachten Sie Ihre Mail und klicken Sie auf den PrüfungLink. Wenn Sie diese E-Mail nicht erhalten haben, überprüfen Sie bitte zuerst Ihren Junk-Mail-Ordner.",
        "Congratulation": "Bravo",
        "Your email is validated. Log to start !": "Ihre E-Mail wurde überprüft. Jetzt einloggen!",
        "Error": "Erreur",
        "Your email cannot be validate !": "Ihre E-Mail kann nicht verifiziert werden!",
        "Never": "Jamais",
        "You can paste some text that will be encrypted too": "Sie können einen Text einfügen, der ebenfalls verschlüsselt wird.",
        "Send a confirmation email to me": "Senden Sie mir eine Bestätigungs-E-Mail",
        "To": "Adresse",
        "Anonymous files transfered achieved": "Anonyme Übertragung an \"{name}\" abgeschlossen",
        "Ready": "Leihgabe",
        "Sharing": "Teilen Sie",
        "Share with": "Teilen Sie mit",
        "Secreting": "Geheimnisse",
        "Global information": "Allgemeine Informationen",
        "Select administrators": "Auswahl der Direktoren",
        "Quorum": "Quorum",
        "Quorum group created": "Quorum-Gruppe eingerichtet",
        "Group name": "Name der Gruppe",
        "User login": "Benutzer-ID",
        "User email": "E-Mail des Benutzers",
        "User password": "Benutzer-Passwort",
        "User passwordConfirmation": "Bestätigung des Passworts",
        "Description": "Beschreibung",
        "Send files to": "An \"{name}\" senden",
        "Actor": "Schauspieler",
        "Files": "Fichiers",
        "Object": "Objet",
        "All users": "Alle Benutzer",
        "All groups": "Alle Gruppen",
        "All quorum groups": "Alle Quorum-Gruppen",
        "All filesets": "Alle Tresore",
        "Application": "Bewerbung",
        "Generic": "Allgemeines",
        "All": "Tout",
        "Sorry, quorum group not found": "Entschuldigung, Beschlussfähigkeitsgruppe nicht gefunden",
        "Save": "Registrieren Sie",
        "Untitle": "Ohne Titel",
        "Untitled": "Ohne Titel",
        "User": "Benutzer",
        "user": "Benutzer",
        "Group": "Gruppe",
        "Quorum group": "Quorum-Gruppe",
        "Fileset": "Einzahlung",
        "Invite guest(s)": "Gast(e) hinzufügen",
        "Are you sure to destroy your account ?": "Sind Sie sicher, dass Sie Ihr Konto vernichten wollen?",
        "Unknown": "Unbekannt",
        "Unknown fileset": "Einzahlung gelöscht",
        "Sorry, unable to display this file": "Tut mir leid, diese Datei kann nicht angezeigt werden",
        "Give rights": "Rechte geben",
        "Manage": "Tous",
        "Download": "herunterladen",
        "Add file": "Dateien hinzufügen oder ändern",
        "Add comments": "Kommentare hinzufügen oder ändern",
        "Add share": "Teilen Sie",
        "Add Guest": "Einen Benutzer einladen",
        "Create users": "Benutzer erstellen",
        "Create filesets": "Tresore erstellen",
        "Create groups": "Gruppen erstellen",
        "Create quorum groups": "Erstellen von Quorum-Gruppen",
        "Create a new share link": "Erstellen Sie einen neuen Freigabe-Link",
        "Lock": "Sperre",
        "UnLock": "Schalten Sie  frei.",
        "Migrate": "Migrer",
        "Add users": "Benutzer verwalten",
        "This is this link to send": "Hier ist der Link zum Kopieren in einer E-Mail",
        "Confirmation": "Bestätigung",
        "Link sent": "Link gesendet",
        "Link": "Pfandrecht",
        "Notification deleted": "Nachricht gelöscht",
        "Problems or questions ? call us at": "Problem oder Frage? Rufen Sie uns an unter: \"{tel}\" oder per E-Mail: \"{email}\".",
        "Are you sure to delete this thread ?": "Sind Sie sicher, dass Sie diese Diskussion löschen wollen?",
        "All threads deleted": "Alle Diskussionen wurden gelöscht.",
        "Are you sure to delete all threads ?": "Sind Sie sicher, dass Sie alle Diskussionen löschen wollen?",
        "Delete the current thread": "Löschen Sie diese Diskussion",
        "Delete all threads": "Alle Diskussionen löschen",
        "Share removed": "Freigabe gelöscht",
        "Access requested": "Zugang erbeten",
        "Access request canceled": "Zugriffsanforderung gelöscht",
        "Access refused": "Antrag auf Zugang verweigert",
        "Access accepted": "Antrag auf Zugang akzeptiert",
        "Download file": "Datei-Download",
        "Name of the fileset": "Name des Tresors",
        "Name of the user": "Name des Benutzers",
        "Name of the file": "Name der Datei",
        "File(s) added or modified": "Datei(en) hinzugefügt oder geändert",
        "Fileset created": "Truhe erstellt",
        "Are you sure to delete directory ?": "Sind Sie sicher, dass Sie das Verzeichnis \"{name}\" und seinen Inhalt löschen wollen?",
        "Are you sure to delete folder ?": "Sind Sie sicher, dass Sie das Tresorverzeichnis \"{name}\" und seinen Inhalt löschen wollen?",
        "New folder": "Neues Tresorfach-Verzeichnis",
        "Folder created": "Brustverzeichnis erstellt",
        "Folder": "Tresorfach-Verzeichnis",
        "Number of files": "{Dateien} Datei(en)",
        "Share(s) added": "Aktie(n) hinzugefügt",
        "Automatic ask for access": "Automatische Zugriffsanfrage",
        "Answer to a access request": "Antwort auf eine Zugangsanfrage",
        "Answer the access request in the contextual menu": "Sie können auch auf weitere Optionen im Kontextmenü zugreifen",
        "Automatic Ask for cryptographic access": "Bitten Sie \"{giverName}\" um Zugriff auf den \"{filesetName}\"-Tresor?",
        "User wants cryptographic access": "Der Benutzer \"{wanterName}\" fragt \"{wantedName}\" nach dem Zugriff auf den Tresor \"{filesetName}\".",
        "Ask cryptographic access": "Fragen Sie \"{giverName}\" nach dem Zugriff auf den \"{filesetName}\"-Tresor?",
        "Do you agree, please verify his identify ?": "Akzeptieren Sie (Bitte überprüfen Sie Ihre Identität, bevor Sie akzeptieren)?",
        "Or go directly in the fileset for this operation": "Oder gehen Sie direkt in den Tresorraum, um diese Operation durchzuführen...",
        "Quorum Group initialisation phase 1": "Einleitung der Beschlussfähigkeitsgruppe (Phase 1)",
        "initialise your cryptographic secret": "Als Verwalter der Quorum-Gruppe \"{name}\"} müssen Sie Ihre kryptographischen Geheimnisse initialisieren. Sind wir dabei?",
        "Quorum Group initialisation phase 2": "Einleitung der Beschlussfähigkeitsgruppe (Phase 2)",
        "you must do the last step of initialisation": "Als Verwalter der Quorum-Gruppe \"{name}\"} müssen Sie einen abschließenden Initialisierungsschritt durchführen. Ist es eingeschaltet?",
        "Wrong passphrase": "Schlechte Passphrase",
        "Change passphrase": "Ändern Sie die Passphrase",
        "Passphrase close to be strong": "Die Passphrase ist nahe daran, stark zu sein...",
        "Passphrase good but not enough": "Die Passphrase ist fast ausreichend",
        "Passphrase not so bad... ok": "Die geheime Phrase ist gar nicht so schlecht... Ok",
        "Passphrase too weak, do better": "Die Passphrase ist zu schwach, machen Sie es besser...",
        "Passphrase too weak": "Die Passphrase ist zu schwach",
        "Passphrase realy too weak": "Die Passphrase ist wirklich zu schwach",
        "Passphrase cannot be empty": "Die Passphrase kann nicht leer sein.",
        "Passphrase initialization": "Initialisierung der Passphrase",
        "Passphrase dropped": "Passphrasensperre",
        "Forgot my passphrase": "Ich habe meine Passphrase vergessen",
        "New passphrase": "Neue Passphrase",
        "Current passphrase": "Aktueller Geheimsatz",
        "Current password": "Aktuelles Kennwort",
        "Initialize passphrase": "Initialisierung der Passphrase",
        "Keys initialized": "Die Schlüssel werden initialisiert",
        "Set": "initialisieren",
        "The list is empty": "Die Liste ist leer.",
        "You are not allowed to see this content": "Sie sind nicht berechtigt, diesen Inhalt anzuzeigen.",
        "Create a new user account": "Erstellen Sie ein neues Benutzerkonto",
        "Create a new quorum group": "Erstellen Sie eine neue Beschlussfähigkeitsgruppe",
        "Created by": "Nach {Name}",
        "Shared with": "Geteilt mit {Name}",
        "You dont want another account ?": "Wollen Sie kein neues Konto?",
        "Authenticate with google": "Sie können sich mit Ihrem Google-Konto authentifizieren!",
        "Auth with Google": "Verbindung mit Google",
        "No, I am sorry": "Nein, tut mir leid.",
        "I remember": "Ich erinnere mich.",
        "Enter a new password": "Geben Sie ein neues Passwort ein",
        "Enter a name": "Geben Sie einen Namen ein",
        "You will be able to change this name at anytime in the vault properties": "Sie können diesen Namen jederzeit in den Eigenschaften des Tresors ändern.",
        "I forget my password": "Ich habe mein Passwort vergessen.",
        "Password reinitialization": "Passwort zurücksetzen",
        "Few steps to reinitialize your password": "Bitte folgen Sie diesen Schritten, um Ihr Passwort zurückzusetzen",
        "A email has been sent. Please check your email and click on the link to choose a new password": "Eine E-Mail wurde an \"{email}\" geschickt. Bitte überprüfen Sie Ihre Mailbox und klicken Sie auf den Link, um ein neues Passwort zu wählen.",
        "Password reinitialized": "Passwort zurücksetzen",
        "Enter your email": "Geben Sie Ihre E-Mail-Adresse ein",
        "reinitConfirm": "Warnung: Wenn Sie Ihr Passwort ändern, sind alle nicht freigegebenen Tresore unzugänglich.",
        "Well done": "Es ist erledigt.",
        "Add": "hinzufügen",
        "Chat": "Discuter",
        "Emails": "Mails",
        "Need Passphrase": "Sie brauchen Ihre Passphrase",
        "Need Password": "Sie benötigen Ihr Passwort",
        "I_Need_Passphrase": "Bitte geben Sie Ihr Passwort ein, um Ihre Daten zu entschlüsseln. Wir werden einen Ihrer folgenden Schlüssel verwenden:",
        "I_Need_Password": "Bitte geben Sie Ihr Passwort ein, um Ihre Daten zu entschlüsseln. Wir werden einen Ihrer folgenden Schlüssel verwenden:",
        "Your email is validated": "Ihre E-Mail wurde erfolgreich verifiziert.",
        "You can close this tab": "Sie können diese Registerkarte oder dieses Fenster schließen.",
        "Valid": "Valider",
        "In progress": "Laufend",
        "Cancel in progress": "Annullierung in Arbeit",
        "actions required": "Aktion(en) anfordern",
        "Email deleted": "Mail {email} gelöscht",
        "Are you sure to drop email ?": "Sind Sie sicher, dass Sie \"{email}\" löschen wollen?",
        "User deleted": "Benutzer \"{login}\" gelöscht",
        "User locked": "Benutzer \"{login}\" gesperrt",
        "User unlocked": "Benutzer \"{login}\" freigeschaltet",
        "An email has been sent": "Eine E-Mail wurde an \"{email}\" geschickt.",
        "Are you sure to delete User ?": "Sind Sie sicher, dass Sie den Benutzer \"{login}\" löschen wollen?",
        "Are you sure to lock User ?": "Sind Sie sicher, dass Sie den Benutzer \"{login}\" sperren?",
        "Are you sure to unlock User ?": "Sind Sie sicher, dass Sie den Benutzer \"{login}\" freischalten wollen?",
        "locked": "Gesperrt",
        "LoginName": "Benutzer",
        "Group deleted": "Gruppe \"{name}\" gelöscht",
        "File deleted": "Datei \"{name}\" gelöscht",
        "Groups": "Gruppen",
        "Request in progress": "Anfrage in Bearbeitung",
        "Are you sure to delete Group ?": "Sind Sie sicher, dass Sie die Gruppe \"{name}\" löschen wollen?",
        "Sorry, group not found": "Tut mir leid, die Band wurde nicht gefunden.",
        "Removed user from group ?": "Sind Sie sicher, dass Sie den Benutzer \"{login}\" aus der Gruppe \"{group}\" entfernen wollen?",
        "User removed from group": "Der Benutzer \"{login}\" wurde aus der Gruppe \"{group}\" entfernt.",
        "Click to chat with...": "Klicken Sie zum Chatten mit...",
        "Click to add a user": "Klicken Sie zum Hinzufügen eines Benutzers",
        "Click to add a friend": "Klicken Sie, um einen Korrespondenten hinzuzufügen",
        "New quorum": "Neue Quorum-Gruppe",
        "New group": "Neue Gruppe",
        "Last modification": "Datum der letzten Änderung",
        "Group created": "Gruppe erstellt",
        "See logs": "Sehen Sie die Protokolle",
        "Add a user": "Einen Benutzer hinzufügen",
        "User added to group": "Benutzer \"{login}\" zu \"{group}\" hinzugefügt",
        "Group name changed": "Name geändert in \"{name}\".",
        "Name changed": "Name geändert in \"{name}\".",
        "Description changed": "Beschreibung geändert in \"{name}\".",
        "Group description changed": "Modifizierte Gruppenbeschreibung",
        "Fileset description changed": "Beschreibung des modifizierten Dateisatzes",
        "Expiration motified": "Ausatmen in {Ausatmen}",
        "Share expiration motified": "modifiziertes Auslaufen der gemeinsamen Nutzung",
        "Devices": "Geräte",
        "Selected": "Ausgewählt",
        "Display Name": "Angezeigter Name",
        "DisplayName changed": "Der Anzeigename wurde in \"{displayName}\" geändert.",
        "The key cannot be registered !": "Der Schlüssel kann nicht registriert werden!",
        "No u2f key named": "Den Notenschlüssel U2F \"{name}\" einstellen",
        "Passphrase": "Passphrase",
        "Fido autentication successfull": "Erfolgreiche Fido-Authentifizierung",
        "Avatar changed": "Modifizierter Avatar",
        "Avatar": "Avatar",
        "u2f key registered": "registrierter U2F-Schlüssel",
        "u2f key deleted": "U2F-Schlüssel \"{deviceName}\" gelöscht",
        "u2f key selected": "U2F-Schlüssel \"{deviceName}\" ausgewählt",
        "Are you sure to drop u2f key ?": "Sind Sie sicher, dass Sie den U2F-Schlüssel \"{deviceName}\" löschen wollen?",
        "New u2f device": "Neuer U2F-Schlüssel",
        "Your key is registered": "Ihr Schlüssel ist registriert",
        "Empty": "Video",
        "This field can't be empty": "Dieses Feld darf nicht leer sein.",
        "Fido autentication": "Authentifizierung Fido",
        "Email not verified": "E-Mail nicht verifiziert",
        "Fido device not confirmed": "U2F-Taste nicht bestätigt",
        "Access validation in progress": "Validierung Ihrer Identität und des Zugriffs im Gange",
        "Your access is under validation": "Ihre Identität und Ihre Zugangsberechtigung zu diesem Safe müssen von folgenden Personen überprüft werden",
        "You may ask somebody else to access this repository": "Wenn Sie nicht warten möchten, können Sie den Zugang zu einer anderen Person beantragen (über die Registerkarte \"Freigaben\").",
        "Dataroom not decypherable": "Nicht entzifferbarer Safe",
        "Please ask someone for access": "Bitte beantragen Sie Zugang zu diesem Tresorfach",
        "Decrypt and enter": "Entziffern und eingeben",
        "You must agree to the terms of use": "Sie müssen die allgemeinen Nutzungsbedingungen akzeptieren.",
        "I agree to the terms of use": "Ich akzeptiere die allgemeinen Nutzungsbedingungen",
        "Write here a new message": "Schreiben Sie unten eine neue Nachricht:",
        "as_send_you_crypted_notification": "hat Ihnen {n} verschlüsselte Benachrichtigung(en) gesendet",
        "Edit the current message": "Ändern Sie die folgende Meldung :",
        "Reinitialisation": "Zurücksetzen",
        "crypted notification": "Verschlüsselte Benachrichtigung",
        "crypted message": "Verschlüsselte Nachricht",
        "List": "Liste",
        "small": "Klein",
        "medium": "Medium",
        "big": "Großartig",
        "View list": "Listenansicht",
        "View tiles": "Symbol-Ansicht",
        "Sort ascending": "Aufsteigend sortieren",
        "Sort descending": "Absteigend sortieren",
        "Start date": "Startdatum",
        "End date": "Enddatum",
        "You can chose a period to show between theses two dates": "Sie können einen Zeitraum zwischen diesen beiden Daten wählen",
        "You need more rights to see this content": "Sie haben keine Berechtigungen zum Anzeigen dieses Inhalts.",
        "Elements count to show": "Anzahl der anzuzeigenden Elemente",
        "Show more elements": "Weitere Artikel anzeigen",
        "The start date must be prior to the end date": "Das Startdatum muss vor dem Enddatum liegen.",
        "Unlimited": "Unbegrenzt",
        "Storage utilization on your instance": "Verwendung des Speicherplatzes auf Ihrer Instanz",
        "Usage statistics": "Nutzungsstatistik",
        "Percent": "Prozentsatz",
        "Users with ": "Benutzer, die den meisten Speicherplatz nutzen",
        "Heaviest filesets": "Größte Aktenkammern",
        "Last created users": "Zuletzt erstellte Benutzer",
        "Users with largest data storage": "Benutzer, die den meisten Speicherplatz nutzen",
        "Wanted Accepted": "Zugang gewährt",
        "Wanted Refused": "Okay. Zugang verweigert.",
        "Wanted Asked": "Zugriffsanfrage übermittelt",
        "New file": "Neue Datei",
        "Open logs": "Offene Protokolle",
        "A quorum need an action": "Die Beschlussfähigkeit erfordert Maßnahmen",
        "A fileset need an action": "Ein Fileset erfordert Maßnahmen",
        "Number of use": "Anzahl der Verwendungen",
        "Search": "Rechercher",
        "No result found": "Keine Ergebnisse gefunden",
        "My emails": "Gleiche E-Mails",
        "My logs": "Monatsprotokolle",
        "My password": "Mein Passwort",
        "My account": "Mein Konto",
        "Device without name, you may had a new one": "Unbenanntes Gerät, Sie sollten eines hinzufügen",
        "Change name": "Ändern Sie den Namen",
        "Change description": "Beschreibung modifizieren",
        "When the keystore is set on `don't care`, you will be able to encrypt data for a receiver even if you don't know him": "Wenn der Schlüsselverwalter auf \"Keine Sorge\" eingestellt ist, können Sie Ihre Daten für einen Korrespondenten verschlüsseln, auch wenn Sie ihn nicht kennen.",
        "Keys": "Schlüssel",
        "Signed keys": "Signierte Schlüssel",
        "Signed by": "Unterzeichnet von",
        "Signed since": "Unterzeichnet seit",
        "The keys you signed": "Die von Ihnen unterzeichneten Schlüssel",
        "Private key is in memory ?": "Der private Schlüssel ist im Speicher?",
        "Private key in memory up to": "Der private Schlüssel wird im Speicher gespeichert, bis",
        "Release": "Kostenlos",
        "Finger print": "Numerisches Impressum",
        "Drop": "Einreichen",
        "Click to upload a new file": "Klicken Sie zum Senden einer Datei",
        "Create a new Quorum with these devices": "Erstellen eines neuen Quorums mit diesen Geräten",
        "You are on this device": "Sie befinden sich auf diesem Gerät",
        "Unknown Key Or Removed Key": "Schlüssel unbekannt oder gelöscht",
        "Unknown user": "Unbekannter Benutzer",
        "Unknown type": "Tippen Sie inconnu",
        "An old key which isn't existing anymore": "Ein alter Schlüssel, der nicht mehr existiert...",
        "Signature not found": "Unterschrift nicht gefunden",
        "Creation date not found": "Erstellungsdatum nicht gefunden",
        "Don't care": "Kein Grund zur Sorge",
        "Normal": "Normal",
        "Paranoid": "Paranoiker",
        "Show debug functions": "Debugging-Funktionen anzeigen",
        "Should be greater than the application trust level": "Muss größer als das Standard-Konfidenzniveau sein",
        "Use care when changing the following settings": "Seien Sie vorsichtig, wenn Sie die folgenden Einstellungen ändern, Sie könnten Ihr Konto unbrauchbar machen, wenden Sie sich an den Support, wenn Sie Fragen haben.",
        "Advanced search on users": "Erweiterte Benutzersuche",
        "Advanced search on vaults": "Erweiterte Tresorfachsuche",
        "No item(s) selected yet": "Keine(s) ausgewählte(s) Element(e)",
        "Vaults shared with": "Gemeinsame Tresore mit",
        "Remove existing filters": "Vorhandene Filter löschen",
        "Send a custom email": "Senden Sie eine personalisierte Einladungs-E-Mail",
        "My friends": "Beziehungen zwischen den Mächten",
        "Friends": "Beziehungen",
        "New friend": "Neue Beziehung",
        "Trusted connection": "Beziehung bestätigt",
        "Which keys will be used to sign ?": "Mit welchen Schlüsseln wird unterschrieben?",
        "Sign friend": "Bestätigen Sie eine Beziehung",
        "Are you sure to signed this friend ?": "Sind Sie sicher, dass Sie diese Beziehung bestätigen wollen?",
        "Friend deleted": "Beziehung gelöscht",
        "Are you sure to delete Friend ?": "Sind Sie sicher, dass Sie diese Beziehung beenden wollen?",
        "Add friend": "Eine Beziehung hinzufügen",
        "AAdvanced search on groups": "Erweiterte Gruppensuche",
        "Friend signed": "Beziehung bestätigt",
        "Continue": "Fortsetzung",
        "Signed By": "Bestätigt durch",
        "Back to": "Zurück zu \"{elName}\"",
        "must be a string": "Muss Text sein",
        "Create a new url": "Erstellen Sie eine neue Einladung",
        "Link name": "Name des Links",
        "must be filled": "Muss ausgefüllt werden",
        "Recipient email": "E-Mail des Empfängers",
        "must be an email": "Muss eine E-Mail sein",
        "must be a number": "Es muss eine Zahl sein",
        "Current folder": "Aktuelle Datei",
        "Are you sure to delete Url": "Sind Sie sicher, dass Sie diese Einladung löschen möchten?",
        "Folder deleted": "Gelöschtes Verzeichnis",
        "No notifications": "Keine Benachrichtigung",
        "You will be able to change this name at anytime in the folder properties": "Sie können diesen Namen jederzeit in den Ordnereigenschaften ändern.",
        "No share user selected": "Kein Benutzer für die Freigabe ausgewählt",
        "Rename folder": "Benennen Sie den Ordner um",
        "Fileset renamed": "Umbenanntes Depot",
        "by": "Par",
        "by ": "Par",
        "Rename FileSet": "Den Stamm umbenennen",
        "filset closed": "Geschlossene Box",
        "fileset open": "Box öffnen",
        "Move": "Verschieben Sie",
        "FileSet Moved": "Verlegter Stamm",
        "validate": "Valider",
        "file validated": "Validierte Datei",
        "Type": "Geben Sie  ein.",
        "Role": "Rolle",
        "share deleted": "Freigabe gelöscht",
        "Are you sure to delete share ?": "Sind Sie sicher, dass Sie diese Aufteilung entfernen wollen?",
        "QuorumGroup deleted": "Gruppe mit Quorum gelöscht",
        "Are you sure to delete quorum group ?": "Sind Sie sicher, dass Sie diese Quorumgruppe abschaffen wollen?",
        "Login name": "Anmeldename",
        "lock": "Bloquer",
        "Are you sure to lock": "Sind Sie sicher, dass Sie blockieren?",
        "Are you sure to delete user ?": "Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?",
        "Update": "Aktualisierung",
        "Member of": "Mitglied von",
        "No group(s)": "Keine Gruppe(n)",
        "Quorum groups": "Quorum-Gruppen",
        "Advanced search on quorums": "Erweiterte Quorum-Suche",
        "Quorum Group name": "Name der Beschlussfähigkeitsgruppe",
        "Threshold": "Schwelle",
        "Members": "Mitglieder",
        "Are you sure to delete group ?": "Sind Sie sicher, dass Sie diese Gruppe löschen wollen?",
        "IP limitation": "IP-Begrenzung",
        "Email limitation for invitation": "E-Mail-Beschränkung für Einladungen",
        "Groups of Managers": "Management-Gruppe",
        "Add configuration for a new group": "Hinzufügen einer Konfiguration für eine neue Gruppe",
        "password for verification": "Passwort zur Verifizierung",
        "Your trust level": "Ihr Grad an Vertrauen",
        "Default": "Standardmäßig",
        "Send test mail": "Senden Sie eine Test-E-Mail",
        "Add a new mail": "Eine E-Mail hinzufügen",
        "Folder name": "Name der Datei",
        "Visibility": "Visibilité",
        "Not visible if empty": "Nicht sichtbar Sie sahen",
        "Visible for members below": "Sichtbar für die Mitglieder unten",
        "Clear": "Löschen Sie",
        "Click": "Klicken Sie auf",
        "Or drop your files here": "Oder ziehen Sie Ihre Dateien hierher",
        "Rename file": "Benennen Sie die Datei um",
        "Move to": "Verschieben Sie \"{elName}\" auf",
        "Fileset Moved": "Verlegter Rüssel",
        "Element moved": "Verdrängtes Element",
        "Advanced search on files": "Erweiterte Dateisuche",
        "Create a new group": "Erstellen Sie eine neue Gruppe",
        "No group selected": "Keine Gruppe ausgewählt",
        "Select a group": "Wählen Sie eine Gruppe",
        "Deleting done": "Löschung vorgenommen",
        "Repository created": "Truhe erstellt",
        "New fileset": "Neues Depot",
        "Rename url": "Benennen Sie die Einladung um",
        "Are you sure to delete Url ?": "Sind Sie sicher, dass Sie diese Einladung löschen möchten?",
        "Number of available deposits": "Anzahl der verfügbaren Stämme",
        "Given by": "Gewährt von",
        "in folder": "Im Tresorraum-Verzeichnis",
        "Not authorized": "Nicht erlaubt",
        "Can create new filesets": "Sie können Tresore erstellen",
        "Can create directories": "Sie können Tresorraumverzeichnisse erstellen",
        "Can modify this rights": "Sie können diese Rechte ändern",
        "Can delete this directory": "Sie können dieses Tresorverzeichnis löschen",
        "Can modify subdir rights": "Sie können die Berechtigungen der Unterverzeichnisse des Tresors ändern",
        "Your current rights": "Ihre derzeitigen Rechte",
        "Avoid validation for": "Vermeidet die Validierung für",
        "Validators for new shares": "Validatoren für neue Aktien",
        "Validators for content": "Inhaltsprüfer",
        "Recovering friends": "Mitglieder der Sammlung",
        "Back": "zurück",
        "New share": "Neue Freigabe",
        "New": "Neu",
        "Url created & Link sent": "Einladung und E-Mail gesendet",
        "edge error": "Sie verwenden Microsoft Edge, das einige der Sicherheitsfunktionen nicht mehr enthält, die für die einwandfreie Funktion unserer Anwendung unerlässlich sind. Wir haben daher unsere Unterstützung für den Edge-Browser eingestellt.",
        "Please use another browser": "Bitte verwenden Sie einen anderen Firefox- oder Chrome-Browser.",
        "Critical error": "Kritischer Fehler",
        "Show previous path": "Vorherige Pfade anzeigen",
        "More infos": "Mehr Informationen",
        "Protect your key": "Schützen Sie Ihren Schlüssel",
        "Not Permitted to login at this time": "Es ist nicht erlaubt, sich während dieses Zeitfensters anzumelden.",
        "Not permited to logon at this workstation": "Es ist nicht erlaubt, sich an dieser Station anzumelden.",
        "Password expired": "Passwort abgelaufen",
        "Account disabled": "Konto deaktiviert",
        "Account expired": "Abgelaufenes Konto",
        "User must reset password": "Sie müssen Ihr LDAP-Passwort ändern.",
        "User account locked": "Gesperrtes Konto",
        "Invalid username/password": "Ungültiger Benutzername/Passwort",
        "Invalid password": "Ungültiges passwort",
        "Fake": "Simulierte Aktion",
        "Did something": "Etwas getan.",
        "Your account is created and ready to use": "Ihr Konto ist erstellt und bereit",
        "Your account is activated and ready to use": "Ihr Konto ist aktiviert und einsatzbereit",
        "You can give access for recovering": "Sie können den Zugang für die Sammlung bereitstellen",
        "recover": "Sammlung",
        "Some notifications": "Benachrichtigungen",
        "Decrypt in progress": "Entschlüsselung läuft",
        "Adding share(s)": "Gemeinsame Nutzung im Gange",
        "My Two Factors": "Meine zweistufigen Validierungen",
        "TwoFactors": "Validierungen in zwei Schritten",
        "Add a new Phone": "Ein Telefon hinzufügen",
        "You are going to remove a 2FA device, are you sure?": "Sie sind dabei, ein Mittel zur Authentifizierung zu entfernen, sind Sie sicher?",
        "Your phone has been added.": "Ihr Telefon wurde hinzugefügt.",
        "Confirm phone": "Bestätigen Sie Ihr Telefon",
        "Add a phone to your account": "Ein Telefon hinzufügen",
        "Add a key to your account": "Einen Schlüssel hinzufügen",
        "Invalid code": "Code ungültig macht",
        "Two Factor Authentication": "Zweistufige Validierung",
        "Please follow the instructions to ensure your identity": "Bitte befolgen Sie diese Anweisungen, um Ihre Identität sicherzustellen.",
        "No Two Factor Authentication is set, please add one": "Es ist keine Zwei-Faktor-Authentifizierung definiert, bitte fügen Sie eine hinzu.",
        "Enter the code": "Geben Sie den Code ein",
        "Too many attempts": "Zu viele Versuche, bitte versuchen Sie es später noch einmal.",
        "Device already set": "Bereits registriertes Gerät",
        "Please plug in your key to register": "Bitte geben Sie Ihren Schlüssel ein, um ihn hinzuzufügen",
        "Register": "hinzufügen",
        "Error when registering device": "Fehler beim Hinzufügen des Schlüssels",
        "Invalid key": "Notenschlüssel nicht gültig",
        "Please plug in and active your key": "Bitte geben Sie Ihren Schlüssel ein und aktivieren Sie ihn.",
        "Advanced search on folders": "Erweiterte Suche nach Tresorfachverzeichnissen",
        "New device": "Neues Gerät",
        "Device created": "Gerät erstellt"
    }
};
// --------------
// -------------- Part errors
// --------------
export const errors = {
    "de": {
        "Warning": "Achtung",
        "Failed": "Echec",
        "Administrative size exceeded": "Der maximale Platz ist erreicht. Ihre Datei konnte nicht gespeichert werden. Wenden Sie sich an Ihren Administrator",
        "IEDetect": "Die Leistung von Internet Explorer / Edge reicht derzeit für kryptographische Berechnungen nicht aus. Wir empfehlen Ihnen, einen anderen Browser (z.B. Firefox oder Chrome) zu verwenden.",
        "IEOK": "Trotzdem möchte ich mit I.E. fortfahren.",
        "Must be a valid email": "Muss eine gültige E-Mail-Adresse sein",
        "Password cannot be empty": "Darf nicht leer sein",
        "Password close to be strong": "Beinahe gut zu sein",
        "Password good but not enough": "Okay, aber Sie können es besser machen.",
        "Password not so bad... ok": "Nicht so schlimm... okay.",
        "Password too weak, do better": "zu schwach, besser machen",
        "Password too weak": "unterfinanziert",
        "Password realy too weak": "wirklich zu schwach",
        "Must be filled": "Kann nicht leer sein",
        "Must be filled (1-10)": "muss enthalten (1-10)",
        "Sorry, object not found": "Entschuldigung, Objekt nicht gefunden",
        "Sorry, file not found": "Entschuldigung, Datei nicht gefunden",
        "Sorry, quorum group not found": "Entschuldigung, Beschlussfähigkeitsgruppe nicht gefunden",
        "Sorry, URL not found": "Entschuldigung, Einladung nicht gefunden",
        "Sorry, thread not found": "Tut mir leid, das Gespräch existiert nicht oder nicht mehr.",
        "A chunk is missing": "Ein Stück fehlt",
        "Authentication failed": "Authentifizierung fehlgeschlagen",
        "Auth type not yet supported": "Authentifizierungsart nicht unterstützt",
        "Cannot add files to a crypted fileset": "Dateien können nicht zu einem aktuell verschlüsselten Tresor hinzugefügt werden",
        "Cannot add shares in a non init quorumGroup": "Kann einer nicht initialisierten Quorum-Gruppe keine Anteile hinzufügen",
        "Cannot anonymously modify a fileSet": "Kann einen Safe nicht anonym verändern",
        "Cannot change text of a crypted fileset": "Der Text in einem verschlüsselten Safe kann nicht geändert werden.",
        "Cannot contact database": "Keine Kontaktaufnahme mit der Datenbank möglich",
        "Cannot create an existing user": "Benutzer existiert bereits",
        "Cannot delete an empty element": "Ein leeres Element kann nicht gelöscht werden.",
        "Cannot delete non initialised notification": "Kann eine nicht initialisierte Benachrichtigung nicht löschen",
        "Cannot delete not my notification": "Die Meldung kann nicht vernichtet werden, sie gehört Ihnen nicht.",
        "Cannot change password for non local account": "Das Passwort eines Drittkonto kann nicht geändert werden.",
        "Cannot flush from an empty element": "Unmöglich, ein leeres Element zu schreiben",
        "Cannot get data from an empty element": "Daten aus einem leeren Element können nicht gelesen werden",
        "Cannot mark non initialised notification": "Nicht initialisierte Benachrichtigung kann nicht markiert werden",
        "Cannot mark, not to me": "Wenn Sie nicht punkten können, gehört es Ihnen nicht.",
        "Cannot migrate a non ready quorumGroup": "Unmöglich, eine Gruppe mit einem nicht bereiten Quorum zu migrieren",
        "Cannot remove files from a crypted fileset": "Dateien aus einem verschlüsselten Safe können nicht gelöscht werden",
        "Confirmation string mismatch": "Die Bestätigungszeichenfolge stimmt nicht überein",
        "Credential changement failed": "Änderungen der Kontoeinstellungen sind fehlgeschlagen",
        "Credential type not implemented": "Authentifizierungsart nicht unterstützt",
        "Current user not set": "Aktueller Benutzer leeren",
        "Database error": "Datenbank-Fehler",
        "Database not open": "Datenbank nicht geöffnet",
        "Element not found in Store": "Element nicht im Speicher gefunden",
        "Email already exist": "Die E-Mail existiert bereits",
        "Error CRUD": "CRUD-Fehler",
        "Error while deleting element": "Beim Löschen ist ein Fehler aufgetreten.",
        "Error while flushing element": "Bei der Sicherung ist ein Fehler aufgetreten",
        "File not found": "Datei nicht gefunden",
        "File reader not done": "Dateileser nicht gefunden",
        "FileSet crypted": "Verschlüsselter Safe",
        "FileSet not found": "Safe nicht gefunden",
        "FileSet not in store": "Der Safe existiert nicht im Speicher",
        "FileSet object not filled": "Stammobjekt ist leer",
        "Group already exist": "Die Gruppe existiert bereits",
        "Group object not filled": "Das Gruppenobjekt ist leer",
        "Must be auth first": "Sie müssen authentifiziert sein",
        "Must have valid emails before": "Sie müssen eine gültige E-Mail-Adresse haben.",
        "No credentials defined for user": "Kein Authentifizierungsschema für diesen Benutzer",
        "Cannot delete actually connected device": "Das Gerät, mit dem Sie verbunden sind, kann nicht gelöscht werden.",
        "Error registration u2f key": "U2F-Schlüssel-Registrierungsfehler",
        "No u2f key registered": "Kein U2F-Schlüssel registriert",
        "U2f Authentication Failed": "U2F-Authentifizierung fehlgeschlagen",
        "The selected File mut be an image": "Die ausgewählte Datei muss ein Bild sein.",
        "No credentials type defined for user": "Kein Authentifizierungstyp für den Benutzer definiert",
        "No fileSet _id to save": "Keine Tresor-ID zu speichern",
        "Not admin of quorumGroup": "Nicht Gruppenverwalter mit Quorum",
        "Not an admin": "Kein Verwalter",
        "Notification not found in database": "Benachrichtigung nicht in der Datenbank gefunden",
        "QuorumGroup already exist": "Die Quorum-Gruppe existiert bereits",
        "QuorumGroup no shares for me": "Gruppe mit Quorum ohne Anteil für mich",
        "QuorumGroup not ready for compute Secret": "Quorum-Gruppe nicht bereit, die Geheimhaltung zu berechnen",
        "QuorumGroup number of admins too small": "Die Anzahl der Administratoren ist zu gering",
        "QuorumGroup object not filled": "Die beschlussfähige Themengruppe ist nicht erfüllt",
        "You need to add more users": "Sie müssen mindestens {Zählen} weitere Benutzer hinzufügen",
        "Set or select the minimun number of users": "Definieren oder wählen Sie die Mindestanzahl der Benutzer",
        "Security violation": "Sicherheitsverletzung, \"{login}\" kann nicht das Recht \"{right}\" geben, weil er es selbst nicht hat.",
        "Share not for a group for me": "Teilen Sie nicht für eine Gruppe für mich",
        "No admin privatekey for me in this quorumGroup": "",
        "My keys": "Klischeemonat",
        "Crypting key": "Verschlüsselungsschlüssel",
        "Signing key": "Signaturschlüssel",
        "Quorum key": "Quorum-Schlüssel",
        "Quorum member key": "Schlüssel zur Beschlussfähigkeit",
        "Revoked": "Widerrufen",
        "InProgress1": "Dieser Schlüssel wartet auf die erste Bestätigung der Beschlussfähigkeit.",
        "InProgress2": "Diese Taste wartet auf eine zweite Beschlussfähigkeitsaufforderung.",
    }
};

// --------------
// -------------- Part notifications
// --------------
export const notifications = {
    "de": {
        "Sorry": "Entschuldigung",
        "Cool": "",
        "Hey": "",
        "fileset_updated": "den Stamm modifiziert",
        "fileset_updateFiles": "hinzugefügte oder modifizierte {Dateinamen} Dateien in",
        "fileset_removeFile": "hat {Pfad} gelöscht in",
        "fileset_createPath": "hat den Ordner {path} in",
        "fileset_removePath": "hat den {Pfad}-Ordner und seinen Inhalt in",
        "fileset_commentAdd": "einen Kommentar hinzugefügt in",
        "fileset_commentRemove": "einen Kommentar gelöscht in",
        "fileset_commentChange": "einen bestehenden Kommentar in",
        "fileset_deleted": "den Safe entfernt",
        "fileset_dropFromShare": "Ihnen den Zugang zum Tresorraum verweigert",
        "fileset_newShare": "den Safe mit Ihnen geteilt",
        "fileset_addGuest": "lädt Sie zum Zugang zum Safe ein",
        "fileset_wanted": "wünscht Zugang zum Tresor",
        "fileset_acceptWanted": "hat zugestimmt und gewährt Ihnen Zugang zum Schließfach",
        "fileset_cancelWanted": "Ihnen den Zugang zum Schließfach verweigert",
        "url_to_fileset": "Eine Ihrer Einladungen wurde verwendet, um den sicheren \"{name}\" zu erstellen.",
        "welcome": "Willkommen an Bord im Namen unseres gesamten Teams!",
        "Account locked": "Ihr Konto ist gesperrt!",
        "Account unlocked": "Ihr Konto ist freigeschaltet",
        "email_confirmed": "Ihre E-Mail \"{email}\" wurde bestätigt, danke!",
        "mail_error": "Entschuldigung, beim Senden einer E-Mail an \"{email}\" ist ein Fehler aufgetreten.",
        "unknown_notif": "Unbekannte Nachricht!",
        "fileset_updated_text": "\"{user}\" hat den Tresor \"{name}\" geändert.",
        "fileset_deleted_text": "Es tut mir leid, aber \"{user}\" hat den \"{name}\"-Safe gelöscht.",
        "fileset_dropFromShare_text": "Es tut mir leid, aber \"{user}\" hat Sie aus dem Tresorzugang \"{name}\" entfernt.",
        "fileset_newShare_text": "\"{user}\" hat den \"{name}\"-Safe mit Ihnen geteilt.",
        "fileset_addGuest_text": "\"{user}\" lädt Sie zum Zugriff auf den Tresor \"{name}\" ein.",
        "fileset_wanted_text": "\"{user}\" möchte auf den Tresor \"{name}\" zugreifen.",
        "fileset_acceptWanted_text": "\"{user}\" hat sich bereit erklärt, Ihnen Zugang zu dem sicheren \"{name}\" zu gewähren.",
        "fileset_cancelWanted_text": "Es tut mir leid, aber \"{user}\" hat Ihnen den Zugang zum \"{name}\"-Tresor verweigert.",
        "welcome_text": "Willkommen an Bord im Namen unseres gesamten Teams!",
        "locked_text": "Ihr Konto ist gesperrt!",
        "unlocked_text": "Ihr Konto ist freigeschaltet",
        "email_confirmed_text": "Ihre E-Mail \"{email}\" wurde bestätigt, danke!",
        "mail_error_text": "Entschuldigung, beim Senden einer E-Mail an \"{email}\" ist ein Fehler aufgetreten.",
        "unknown_notif_text": "Unbekannte Nachricht!",
    }
};


export const mail = {
    "de": {
        "A new crypted message": "Eine neue sichere Nachricht von {Name}",
        "You have received a crypted message": "Sie haben gerade eine verschlüsselte Nachricht von {Name} erhalten.",
        "Hi": "Hallo {Name,",
        "Confirm": "Bestätiger",
        "Copy past the following link into your browser": "Kopieren Sie den folgenden Link in Ihren Browser",
        "Click the button to confirm": "Klicken Sie zur Bestätigung",
        "Here to direct access": "Hier für direkten Zugang",
        "Here to confirm": "Klicken Sie hier, um zu bestätigen",
        "You have received crypted messages": "Sie haben {n} verschlüsselte Nachrichten erhalten",
        "new crypted messages": "{n} verschlüsselte Nachrichten für Sie",
        "one message from": "Eine Nachricht von {Name} an, {Datum}",
        "FileSet Modifications": "{n} verschlüsselte sichere Modifikationen",
        "FileSet Modification": "Der sichere \"{fname}\" wurde geändert",
        "FileSet Modification content": "{name} hat des Tresors \"{fname}\" am {date} geändert.",
        "FileSet Modifications content": "Kürzlich wurden {n} Änderungen an verschlüsselten Tresoren vorgenommen",
        "one fileset Modification": "Der Tresor \"{fname}\" wurde am {Datum} durch {name} modifiziert.",
        "Email validation": "Validierung der E-Mail-Adresse \"{email}\"",
        "Last step to register this email into you Lynvictus account": "Wir benötigen eine Bestätigung für die Adresse \"{email}\".",
        "This email is erased from your lynvictus account": "Die E-Mail \"{email}\" wurde von Ihrem Konto gelöscht.",
        "Your email is not longer valid for your account": "Ihre E-Mail ist für Ihr Konto \"{login}\" nicht mehr gültig.",
        "This is a test email": "Dies ist eine Testmail.",
        "You have nothing to do": "Sie müssen nichts tun. Der Link ist nur für Sie, um zu überprüfen, ob der Zugang funktioniert.",
        "Link": "Pfandrecht",
        "Account nearly created": "Ihr Konto \"{login}\" wird demnächst eingerichtet.",
        "Last step to create your account": "Letzter Schritt zur Erstellung Ihres Kontos",
        "Please confirm your email with the following link": "Bitte bestätigen Sie Ihre E-Mail, indem Sie dem Link",
        "Reinitialisation of your Password": "Setzen Sie Ihr Passwort zurück",
        "It seems that you have forgotten your password...": "Es scheint mir, dass Sie Ihr Passwort vergessen haben...",
        "Please confirm your new password by clicking the following link": "Bitte bestätigen Sie Ihr Passwort, indem Sie auf den folgenden Link klicken.",
        "FIDO registration key": "FIDO-Schlüssel-Registrierung \"{deviceName}\"",
        "Last step to register this FIDO Key": "Letzter Schritt zur Registrierung des FIDO-Schlüssels \"{deviceName}\" für Ihr Konto \"{login}\".",
        "Please confirm the key": "Bitte bestätigen Sie die FIDO-Taste",
        "Direct access": "Direkter Zugang",
        "Some modifications": "Änderungen wurden an \"{fName}\" vorgenommen.",
        "Subject_WELCOME": "Willkommen bei {Name}",
        "Content_WELCOME_1": "Willkommen an Bord!",
        "Content_WELCOME_2": "Ihr Konto \"{name}\" ist bereit!",
        "Content_ENCRYPTED": "Verschlüsselte Informationen sind verfügbar.",
        "Content_FILESET_DIRADD": "{ownerName} hat den Ordner \"{path}\" im Safe \"{fName}\" erstellt",
        "Content_FILESET_FILEADD": "{ownerName} fügte die \"{fileNames}\"-Dateien zum \"{fName}/{path}\"-Tresor hinzu.",
        "Content_FILESET_FILEREMOVE": "{ownerName} hat \"{path}\" aus dem Safe \"{fName}\" gelöscht",
        "Subject_NEWSHARE": "Ein neuer \"{fName}\"-Safe ist verfügbar.",
        "Content_NEWSHARE": "{eigentümerName} den \"{fName}\"-Safe mit Ihnen geteilt.",
        "Subject_ADDGUEST": "Vertrauliche Informationen für Sie ({fName})",
        "Content_ADDGUEST_1": "\"{ownerName}\" hat Informationen mit Ihnen geteilt. Der Name der gemeinsam genutzten Datei lautet \"{fName}\".",
        "Content_ADDGUEST_2": "Um die Freigabe zu akzeptieren, melden Sie sich hier an",
        "Subject_ADDREGISTEREDGUEST": "\"{ownerName}\" bietet Ihnen eine Ansicht des Tresors. \"{fName}\"",
        "Content_ADDREGISTEREDGUEST": "\"{ownerName}\" schlägt vor, dass Sie Zugang zum Schließfach \"{fName}\" beantragen.",
        "Subject_TESTEMAIL": "E-Mail-Test",
        "Content_TESTEMAIL_1": "Dies ist ein E-Mail-Test für Sie.",
        "Content_TESTEMAIL_2": "Sie haben nichts zu tun",
        "Subject_DROPFROMSHARE": "Ihr Zugang zum \"{fName}\"-Tresor wurde widerrufen.",
        "Content_DROPFROMSHARE": "Ihr Zugriff auf das Schließfach wurde von {EigentümerName} widerrufen.",
        "Subject_WANTED": "\"{ownerName}\" will Zugang zum Tresorraum \"{fName}\"",
        "Content_WANTED": "\"{ownerName}\" will Zugang zum sicheren \"{fName}\".",
        "Subject_ACCEPTWANTED": "Zugang zum Tresor \"{fName}\" akzeptiert von \"{ownerName}\"",
        "Content_ACCEPTWANTED": "\"{ownerName}\" hat Ihrer Anfrage zugestimmt und gibt Ihnen Zugang zum Tresor \"{fName}\".",
        "Subject_CANCELWANTED": "Zugang zum Tresor \"{fName}\" durch \"{ownerName}\" verweigert",
        "Content_CANCELWANTED": "\"{ownerName}\" hat Ihren Antrag auf Zugang zum {fName} Tresor storniert.",
        "Subject_ACCOUNTCREATED": "Ihr Konto \"{login}\" wird erstellt",
        "Content_ACCOUNTCREATED": "Ihr Konto \"{login}\" wird erstellt. Willkommen!",
        "Subject_ACCOUNTLOCKED": "Ihr Konto \"{login}\" ist gesperrt.",
        "Content_ACCOUNTLOCKED": "Ihr Konto \"{login}\" wurde gesperrt. Wenden Sie sich an Ihren Administrator",
        "Content_ACCOUNTUNLOCKED": "Ihr Konto \"{login}\" wurde freigegeben. Schön, Sie wiederzusehen.",
        "Subject_REINITPASS": "Passwort für \"{name}\" zurückgesetzt",
        "Content_REINITPASS": "Ihr Passwort und Ihre kryptographischen Schlüssel wurden gelöscht. Bitte klicken Sie auf diesen Link, um neue zu generieren.",
        "Subject_EMAILDELETED": "Die E-Mail \"{email}\" wird von Ihrem Konto gelöscht.",
        "Content_EMAILDELETED": "Ihre E-Mail ist nun nicht mehr gültig für Ihr Konto \"{name}\".",
        "Subject_CONFIRMEMAIL": "Ihre E-Mail \"{email}\" wird bald erstellt.",
        "Content_CONFIRMEMAIL_1": "Letzter Schritt zum Hinzufügen dieser E-Mail zu Ihrem Konto \"{login}\".",
        "Content_CONFIRMEMAIL_2": "Bitte bestätigen Sie Ihre E-Mail, indem Sie auf den folgenden Link klicken:",
        "Subject_CONFIRMEDEMAIL": "Ihre E-Mail \"{email}\" wird bestätigt.",
        "Content_CONFIRMEDEMAIL": "Ihre E-Mail \"{email}\" ist bereit für Ihr Konto \"{login}\".",
        "Subject_SIGNUP": "Ihr Konto \"{login}\" ist fast erstellt.",
        "Content_SIGNUP_1": "Letzter Schritt zur Erstellung Ihres Kontos.",
        "Content_SIGNUP_2": "Bitte bestätigen Sie Ihre E-Mail, indem Sie auf den folgenden Link klicken:",
        "Subject_NEW_ACCOUNT_PASSWORD": "Ihr Konto ist bereit",
        "Content_NEW_ACCOUNT_PASSWORD": "Ein neues Konto wurde für Sie erstellt, Ihr temporäres Passwort lautet \"{password}\".",
        "Subject_DROPGUESTFROMSHARE": "Vertrauliche Informationen für Sie wurden gelöscht ({fName})",
        "Content_DROPGUESTFROMSHARE": "\"{ownerName}\" hat Ihren Antrag auf Zugang zum Tresor endgültig abgelehnt. \"{fName}\"",
        "Subject_NEWURL": "Eine Einladung zum Senden von Dateien",
        "Content_NEWURL": "\"{ownerName}\" lädt Sie ein, Dateien über den untenstehenden Link dorthin zu verschieben.",
        "Subject_NEWCHAT": "Eine neue verschlüsselte Nachricht für Sie \"{ownerName}\"",
        "Content_NEWCHAT": "\"{ownerName}\" hat Ihnen eine Nachricht geschickt, bitte folgen Sie dem untenstehenden Link, um sie anzuzeigen.",
    }
};

export const sms = {
    "de": {
        "Verification code": "Hier ist Ihr Validierungscode : \"{otp}\"",
        "A code has been sent to your phone": "Ein Code wurde an Ihr Telefon gesendet",
    }
};

