export const INSTANCE = "UNSETINSTANCE";
export const VERSION = "5.0.2";
export const BRANCH = "master";
export const COMMIT = "25233cef";
export const BUILDER = "alaprevo";
export const BUILDERHOST = "stip-p04";
export const DESCRIPTION = "Lynvictus -- Idunn version";
export const AUTHOR = "Caroline BOYER; Bertrand WALLRICH";
export const LICENSE = "UNSETLICENCE";

// ------------ Default port number ----------------------------
export const DEFAULT_PORT = 3000;

// ---------- every x seconds, look for dead clients and logout corresponding user ----
export const SESSION_TIMEOUT = 60 * 60 * 24;


// ------------- COnfiguration path fir all configs ------------
export const CONFIG_PATH = `${__dirname}/../configs/`;
export const MAIL_PATH = `${__dirname}/../mail/`;

// ------------- URL -------------------------------------------
export const URLMESSAGE = '/rest';

// --------- For search of user on server -----------
export const MIN_LETTERS_FOR_SERVER = 2;

// --------- Folder visibility
export const DIRECTORYVISIBILITY = {
    "FILESETS": 1,
    "MEMBERS": 2,
}

export const ROOTDIR_ID = "111111111111111111111222";
export const ROOTDIR_PATH = `/lynvictus/folder/${ROOTDIR_ID}`;
export const ROOT_ID = "111111111111111111111333";
export const ROOTGROUP_ID = "111111111111111111111444";

// ------------- DataBase Collections ------------
export const DATABASE = {
    "USERS": "Users",
    "GROUPS": "Groups",
    "QUORUMGROUPS": "QuorumGroups",
    "FILESETS": "FileSets",
    "FILES": "Files",
    "NOTIFICATIONS": "Notifications",
    "NEWNOTIFICATIONS": "Notifs",
    "CHUNKS": "Chunks",
    "LOGS": "Logs",
    "SYNC": "Sync",
    "SESSION": "Sessions",
    "URL": "Urls",
    "CONFIG": "Config",
    "FOLDERS": "Folders",
    "DEVICES": "Devices",
    "TODOS": "Todos",
    "PASSPORT_SESSION": "Passport.Sessions"
};

export const SORTORDER = {
    "ASCENDANT": 1,
    "DESCENDANT": -1,
}

// ------------- Authentication types ------------
export const TIMEFORMAT = {
    "DATE_FULL": 1,
    "DATE_SHORT": 2,
    "UTC": 3,
    "HOURS_MINUTES": 4,
    "EXPIRE_DELAY": 5,
    "MONTH_DAY": 6,
    "DATE_ISO": 7,
};

// ------------- Authentication types ------------
export const AUTH = {
    //TODO to remove
    "PRESET": 99,
    "LOCAL": 1,
    "LDAP": 2,
    "GOOGLE": 3,
    "FACEBOOK": 4,
    "AD": 5,
    "OAUTH": 6,
    "PROXY": 7
};
export const AVAILABLE_AUTH = [AUTH.LOCAL, AUTH.GOOGLE];

export const SECONDAUTH = {
    "U2F": 1,
    "SMS": 2
}
export const AVAILABLE_SECONDAUTH = [SECONDAUTH.U2F, SECONDAUTH.SMS];


export const SCREENSIZE = {
    "SMALL": 1, // <=640
    "MEDIUM": 2, //  640 < x <=800
    "NORMAL": 3, // 800 < x <=1024
    "BIG": 4, // x > 1024
};

// -------------- For progressBar -----------------
export const PROGRESSBAR = {
    "INDETERMINATE": 1,
    "DETERMINATE": 2,
}

// ------------- Capabilities types ------------
export const CAP_OBJECT_TYPE = {
    "GENERIC": 1,
    "USER": 2,
    "GROUP": 3,
    "QUORUMGROUP": 4,
    "FILESET": 5,
    "APPLICATION": 6,
    "ALL": 7,
    "LOG": 9,
    "URL": 10,
    "FOLDERS": 11,
};

// ------------- Object types ------------
export const OBJECT_TYPE = {
    "GENERIC": "GEN",
    "USER": "USER",
    "GROUP": "GROUP",
    "QUORUMGROUP": "QGROUP",
    "FILESET": "FSET",
    "APPLICATION": "APP",
    "ALL": "ALL",
    "LOG": "LOG",
    "URL": "URL",
    "NOTIFICATION": "NOTIF",
    "CHAT": "CHAT",
    "FRIEND": "FRN",
    "FOLDER": "FOL",
    "INOD": "INO",
    "FILE": "FILE",
    "DIRECTORY": "DIR",
    "CONFIG": "CFG",
    "CHUNK": "CHUNK",
    "TWOFACTORSTRATEGY": "TWOFACTORSTRATEGY",
    "DEVICE": "DEVICE",
    "SESSION": "SESSION",
    "SHARE": "SHARE",
    "TODO": "TODO",
};
export const OBJECT_PATH = {
    "USER": "user",
    "GROUP": "group",
    "QUORUMGROUP": "quorum",
    "FILESET": "fileset",
    "FRIEND": "friend",
    "FOLDER": "folder",
    "INOD": "file",
    "FILE": "file",
    "DIRECTORY": "file",
    "CONFIG": "config",
    "DEVICE": "device",
    "SHARE": "SHARE",
    "TODO": "todo",
};


export const CAP_AVAILABLE_OBJECT_TYPES = [1, 2, 3, 4, 5, 6, 7];
export const CAP_ACTOR_TYPE = {
    "USER": 1,
    "GROUP": 2,
    "QUORUMGROUP": 3,
    "ALL": 4
};
export const CAP_AVAILABLE_ACTOR_TYPES = [1, 2, 3, 4];

export const SHARES = {
    "UNKNOWN": 0,
    "USER": 1,
    "GUEST": 2,
    "GROUP": 3,
    "QUORUMGROUP": 4
};

//-- the minimum rights for a fileset
export const MINIMUM_RIGHTS = {
    "manage": false,
    "download": false,
    "addShare": false,
    "addFile": false,
    "addText": false,
    "seeLogs": false,
    "seeShares": false,
};

//-- the validator rights for a fileset
export const SHAREVALIDATOR_RIGHTS = {
    "manage": false,
    "download": false,
    "addShare": true,
    "addFile": false,
    "addText": false,
    "seeLogs": true,
    "seeShares": true
}

//-- the validator rights for a fileset
export const CONTENTVALIDATOR_RIGHTS = {
    "manage": false,
    "download": true,
    "addShare": true,
    "addFile": false,
    "addText": false,
    "seeLogs": true,
    "seeShares": false,
};

//-- the validator rights for a fileset
export const RECOVER_RIGHTS = {
    "manage": false,
    "download": false,
    "addShare": true,
    "addFile": false,
    "addText": false,
    "seeLogs": true,
    "seeShares": true,
};

//-- the default rights for a fileset
export const IMPORT_RIGHTS = {
    "manage": false,
    "download": true,
    "addShare": true,
    "addFile": true,
    "addText": true,
    "seeLogs": false,
    "seeShares": true
};


// -- the maximum rights for a fileset
export const MAXIMUM_RIGHTS = {
    "manage": true,
};

export const SHAREROLES = {
    "USER": 0,
    "SHAREVALIDATOR": 1,
    "CONTENTVALIDATOR": 2,
    "RECOVER": 3
}

// ------------- Capabilities types ------------
export const OBJECT_ACTION = {
    "UPDATE": 1,
    "CREATE": 2,
    "DELETE": 3
};


// ------------- Actions / response types ------------
export const ACTIONS = {
    "GLOBAL": {
        "NONE": 1,
        "PING": 2,
        "SYNCSTORES": 3,
        "GETCONFIG": 4,
        "CHECKSPACE": 6,
        "SENDERROR": 7,
    },
    "USER": {
        "BASE": 10,
        "GET": 11,
        "CREATE": 12,
        "UPDATE": 13,
        "DELETE": 14,
        "GETKEYBYUID": 19,
        "ADDTOGROUP": 20,
        "REMOVEFROMGROUP": 21,
        "FIND": 22,
        "PRESET": 23,
        "UPDATEKEYSTORE": 24,
        "FINDINFOS": 25,
    },
    "ACCOUNT": {
        "REFRESH": 30,
        "CREATE": 31,
        "DELETE": 32,
        "UPDATE": 33,
        "CHANGEPASS": 34,
        "CONFIRMCHANGEPASS": 35,
        "LOGOUT": 36,
        "NEEDPRIVATEKEY": 37,
        "LOGIN": 38,
        "SIGNUP": 39,
        "CONFIRM": 40,
        "CONFIRMEMAIL": 41,
        "CREATEUID": 42,
        "DELETEUID": 43,
        "STARTREFRESH": 44,
        "ABORTNEEDPASSWORD": 45,
        "REINITKEYS_WHEN_LOGGED_IN": 46,
        "UPDATEPKEY": 47,
        "REINITKEYS": 48,
        "SUICIDE": 49,
        "ADDEMAIL": 50,
        "DELEMAIL": 51,
        "SENDEMAIL": 52,
        "U2F": {
            "ASK_REGISTER": 53,
            "REPLY_REGISTER": 54,
            "ASK_CHALLENGE": 55,
            "SIGN_CHALLENGE": 56,
            "DROP_KEY": 57,
            "CONFIRM": 58,
            "SELECT": 59,
        },
        "DEVICES": {
            "DROP_KEY": 70,
            "SET_KEY": 71,
            "CONNECT": 72,
        },
        "UPDATEKEYSTORE": 73,
        "GETKEYSTORES": 74,
        "GETMYKEYSTORE": 75,
    },
    "GROUP": {
        "BASE": 60,
        "GET": 61,
        "CREATE": 62,
        "UPDATE": 63,
        "DELETE": 64,
        "UPDATEVALIDATINGSHARESGROUP": 65,
        "FIND": 66
    },
    "URL": {
        "BASE": 80,
        "GET": 81,
        "CREATE": 82,
        "UPDATE": 83,
        "DELETE": 84,
    },
    "TWOFACTORAUTH": {
        "BASE": 90,
        "GET": 91,
        "CREATE": 92,
        "UPDATE": 93,
        "DELETE": 94,
        "REGISTER": 95,
        "REGISTERVALIDATION": 96,
        "ISACTIVE": 97,
        "INITCHALLENGE": 98,
        "RESOLVECHALLENGE": 99
    },
    "FILESET": {
        "BASE": 100,
        "GET": 101,
        "CREATE": 102,
        "UPDATE": 103,
        "DELETE": 104,
        "ADDFILES": 105,
        "DELFILES": 106,
        "SAVE": 107,
        "ANONSAVE": 108,
    },
    "FILES": {
        "BASE": 200,
        "GET": 201,
        "CREATE": 202,
        "UPDATE": 203,
        "DELETE": 204,
        "ADD": 205,
        "UPDATECHUNK": 206,
        "READCHUNK": 207,
        "ANONSAVECHUNK": 208,
    },
    "PROGRESS": {
        "INIT": 301,
        "UPDATE": 302,
        "ABORT": 303,
        "INCREMENT": 304,
        "DELETE": 305,
        "END": 306,
        "CLEAN": 307
    },
    "CHAT": {
        "BASE": 400,
        "GET": 401,
        "CREATE": 402,
        "UPDATE": 403,
        "DELETE": 404,
        "MARK": 405,
        "MARKALL": 406,
        "SETCHECKTIME": 407,
        "DELETEALL": 408,
        "UPDATEALL": 409,
        "DELETETHREAD": 410,
        "READTHREAD": 411,
        "CREATETHREAD": 412,
        "UPDATETHREAD": 413,
        "SAVE": 414,
        "MARKASREAD": 415,
    },
    "QUORUMGROUP": {
        "BASE": 500,
        "GET": 501,
        "CREATE": 502,
        "UPDATE": 503,
        "DELETE": 504,
        "ADDSHARES": 505,
        "ADDPUBLICKEY": 506,
        "UPDATEKEYSTORE": 507,
    },
    "NOTIFICATION": {
        "BASE": 600,
        "GET": 601,
        "CREATE": 602,
        "UPDATE": 603,
        "DELETE": 604,
        "MARKASREAD": 605,
        "CREATENCRYPTED": 606,
    },
    "FRIENDS": {
        "BASE": 700,
        "GET": 701,
        "CREATE": 702,
        "UPDATE": 703,
        "DELETE": 704,
        "ANONFIND": 709,
        "FIND": 710,
        "GETBYGROUP": 711,
    },
    "LOGS": {
        "ADD": 801,
        "GET": 802,
    },
    "SERVERCONFIG": {
        "UPDATE": 811,
        "GET": 812,
    },
    "HELP": {
        "GETFILE": 803,
    },
    "MESSAGES": {
        "GET": 804,
    },
    "THREADS": {
        "BASE": 900,
        "GET": 901,
        "SAVE": 902,
        "UPDATE": 903,
        "DELETE": 904,
        "APPENDMESSAGE": 905,
    },
    "STATS": {
        "ALLUSERS": 1001,
        "FILSETSSIZE": 1002,
        "USERSSIZE": 1003,
        "STORAGE": 1004,
        "ALLUSERSBYSTORAGE": 1005
    },
    "DIRECTORY": {
        "BASE": 1100,
        "GET": 1101,
        "CREATE": 1102,
        "UPDATE": 1103,
        "DELETE": 1104,
    },
    "ALL": {
        "WORKFLOW": 1200,
    }


};
export const RESPONSES = {
    "OK": 100,
    "ERROR": 666,
};

export const CRYPTOMESSAGE = {
    "CRYPTCHUNK": 1,
    "DECRYPTCHUNK": 2,
    "SETKEY": 3,
    "ISFREE": 4,
};

export const QUORUMGROUP_STATUS = {
    "NULL": 0,
    "READY": 1,
    "SHARING": 2,
    "SETSECRET": 3
};

// ------------ The anonymous actor ----------
export const ANONYMOUS_ACTOR = {
    "_id": "0",
    "login": "ANONYMOUS",
    "logged": true
};

// ---------- Flags --------------------------
export const FLAGS = {
    "NONE": 0b00, //  --- no flags
    "QUIET": 0b01, //  --- not send error in
    "THROW": 0b10, //  --- send a throw in
};

// ----------- AUTOACTIONS ---------------------------
export const AUTOACTIONS = {
    "AUTO_ASK_WANTED": 1,
    "ASK_WANTED": 2,
    "REPLY_WANTED": 3,
    "QUORUM_SHARE": 4,
    "QUORUM_SETSECRET": 5,
};

export const TODO_TYPE = {
    "MUSTACCEPTWANTED": "MUSTACCEPTWANTED",
    "MUSTACCEPTWANTEDQUORUM": "MUSTACCEPTWANTEDQUORUM",
    "MUSTVALIDFILE": "MUSTVALIDFILE",
    "MUSTINITQUORUM": "MUSTINITQUORUM",
    "MUSTINITSHAREQUORUM": "MUSTINITSHAREQUORUM",
    "MUSTUNLOCKUSER": "MUSTUNLOCKUSER",
    "MUSTCONFIREMAIL": "MUSTCONFIREMAIL",
};


export const NOTIF_TYPE = {
    "NEW_ACCOUNT_PASSWORD": "NEW_ACCOUNT_PASSWORD",
    "URL_TO_FILESET": "URL_TO_FILESET",
    "FILESET_DELETED": "FILESET_DELETED",
    "ACCOUNTUNLOCKED": "ACCOUNTUNLOCKED",
    "ACCOUNTLOCKED": "ACCOUNTLOCKED",
    "GENERIC": "GENERIC",
    "WELCOME": "WELCOME",
    "ENCRYPTED": "ENCRYPTED",
    "FILESET_DIRADD": "FILESET_DIRADD",
    "FILESET_FILEADD": "FILESET_FILEADD",
    "FILESET_FILEREMOVE": "FILESET_FILEREMOVE",
    "NEWSHARE": "NEWSHARE",
    "ADDGUEST": "ADDGUEST",
    "ADDREGISTEREDGUEST": "ADDREGISTEREDGUEST",
    "DROPFROMSHARE": "DROPFROMSHARE",
    "WANTED": "WANTED",
    "ACCEPTWANTED": "ACCEPTWANTED",
    "CANCELWANTED": "CANCELWANTED",
    "TESTEMAIL": "TESTEMAIL",
    "CONFIRMEMAIL": "CONFIRMEMAIL",
    "CONFIRMEDEMAIL": "CONFIRMEDEMAIL",
    "EMAILDELETED": "EMAILDELETED",
    "REINITPASS": "REINITPASS",
    "SIGNUP": "SIGNUP",
    "NEWURL": "NEWURL",
    "NEWCHAT": "NEWCHAT",
    "ACCEPTWANTEDQUORUM": "ACCEPTWANTEDQUORUM",
    "REFUSEWANTEDQUORUM": "REFUSEWANTEDQUORUM",
};

export const NOTIFYML = {
    "NEW_ACCOUNT_PASSWORD": "newAccountPassword.yml",
    "URL_TO_FILESET": "urlToFileset.yml",
    "FILESET_DELETED": "filesetDeleted.yml",
    "ACCOUNTUNLOCKED": "accountUnlocked.yml",
    "ACCOUNTLOCKED": "accountLocked.yml",
    "GENERIC": "generic.yml",
    "WELCOME": "welcome.yml",
    "ENCRYPTED": "encrypted.yml",
    "FILESET_DIRADD": "filesetDirAdded.yml",
    "FILESET_FILEADD": "filesetFileAdded.yml",
    "FILESET_FILEREMOVE": "filesetFileRemoved.yml",
    "NEWSHARE": "newShare.yml",
    "ADDGUEST": "addGuest.yml",
    "ADDREGISTEREDGUEST": "addRegisteredGuest.yml",
    "DROPFROMSHARE": "dropFromShare.yml",
    "WANTED": "wanted.yml",
    "ACCEPTWANTED": "acceptWanted.yml",
    "CANCELWANTED": "cancelWanted.yml",
    "TESTEMAIL": "testMail.yml",
    "CONFIRMEMAIL": "confirmEmail.yml",
    "CONFIRMEDEMAIL": "confirmedEmail.yml",
    "EMAILDELETED": "deletedEmail.yml",
    "REINITPASS": "reinitPass.yml",
    "SIGNUP": "signup.yml",
    "NEWURL": "newUrl.yml",
    "NEWCHAT": "newChat.yml",
    "ACCEPTWANTEDQUORUM": "acceptWantedQuorum.yml",
    "REFUSEWANTEDQUORUM": "refuseWantedQuorum.yml",
};


// ------------- For notification Structure -----------
export const NOTIFICATIONS = {
    "USER_TO_USER_MESSAGE": "USER_TO_USER_MESSAGE",
    "UTU_CRYPTED_MESSAGE": "UTU_CRYPTED_MESSAGE",
    "UPDATEFILESET": "UPDATEFILESET",
    "FILESET_DELETED": "FILESET_DELETED",
    "FILESET_DELETED_FOR_GUEST": "FILESET_DELETED_FOR_GUEST",
    "FILESET_COMMENTADD": "FILESET_COMMENTADD",
    "FILESET_COMMENTREMOVE": "FILESET_COMMENTREMOVE",
    "FILESET_FILEADD": "FILESET_FILEADD",
    "FILESET_FILEREMOVE": "FILESET_FILEREMOVE",
    "FILESET_DIRADD": "FILESET_DIRADD",
    "FILESET_DIRREMOVE": "FILESET_DIRREMOVE",
    "DROPFROMSHARE": "DROPFROMSHARE",
    "DROPGUESTFROMSHARE": "DROPGUESTFROMSHARE",
    "NEWSHARE": "NEWSHARE",
    "ADDGUEST": "ADDGUEST",
    "ADDREGISTEREDGUEST": "ADDREGISTEREDGUEST",
    "WANTED": "WANTED",
    "ACCEPTWANTED": "ACCEPTWANTED",
    "CANCELWANTED": "CANCELWANTED",
    "ACCOUNTCREATED": "ACCOUNTCREATED",
    "CONFIRMEMAIL": "CONFIRMEMAIL",
    "SIGNUP": "SIGNUP",
    "REINITPASS": "REINITPASS",
    "MAILERROR": "MAILERROR",
    "TESTEMAIL": "TESTEMAIL",
    "EMAILDELETED": "EMAILDELETED",
    "CONFIRMU2F": "CONFIRMU2F",
    "THREAD": "THREAD",
    "URL_TO_FILESET": "URL_TO_FILESET",
    "NEW_ACCOUNT_PASSWORD": "NEW_ACCOUNT_PASSWORD"
};

// ----------- Delay for sending notification on email or whatever in seconds
export const NOTIFICATIONSDELAY = {
    "UTU_CRYPTED_MESSAGE": 5,
    "UPDATEFILESET": 86400,
};

// ------------- For notification Structure -----------
export const FILESETNOTIFICATIONS = {
    "COMMENTADD": "COMMENTADD",
    "COMMENTREMOVE": "COMMENTREMOVE",
    "COMMENTCHANGE": "COMMENTCHANGE",
    "FILEADD": "FILEADD",
    "FILEREMOVE": "FILEREMOVE",
    "DIRADD": "DIRADD",
    "DIRREMOVE": "DIRREMOVE",
};

export const DESKTOPNOTIFICATIONSPERMISSIONS = {
    "DENIED": "denied",
    "GRANTED": "granted"
}

export const USER_CREATION_STATES = {
    "NAME": 'creation',
    "INITIATE": "INITIATE",
    "PENDING": "PENDING",
    "INVITED": "INVITED",
    "ACTIVE": "ACTIVE",
    "PASSWORD": "PASSWORD"
};

export const MAIL_POOLING_INTERVAL = 2000;
export const MAIL_DIGEST_INTERVAL = 84400000; // 1 day


//export const DIRECTORY = true;
export const FILESET_NEW_NAME = "untitled";

// ------------- language by default ------------------
export const DEFAULT_LANG = 'en';

// ------------- chunk size for files -----------------
//export const FILE_CHUNK_SIZE = 512000;
export const FILE_CHUNK_SIZE = 512000 * 10;
//export const FILE_CHUNK_SIZE = 256000;
//export const FILE_CHUNK_SIZE = 64000;

// ------------- Listener Interval (in milisecond) ----
export const LISTENER_TIMER = 3000;
export const PKEY_LISTENER_TIMER = 10000;
export const PKEY_DELAY = 299999;

// ------------- Time of objects in Graveyard (in milisecond) ----
export const GRAVEYARD_DELAY = 120000;
//export const GRAVEYARD_DELAY = 10000;


// ------------- mails and sms saved folder --------
export const TEMP_SUBFOLDER_NAME = "lynvictus";

// --------- Nomber of iterations for PBKDF2 ----------
export const PBKDF2_ITERATION = 10000;
export const PBKDF2_HASH = 'sha256';

export const IE_PBKDF2_ITERATION = 100;
export const IE_PBKDF2_HASH = 'sha256';


// ------------- El gamal generators ------------------
//export const DEFAULT_MODP_GROUP = 'modp14';   // --- 2048 ---
export const DEFAULT_MODP_GROUP = 'modp23'; // --- 2048 ---
//export const DEFAULT_MODP_GROUP = 'modp5';   //  for testing
//export const DEFAULT_MODP_GROUP = 'modp16';   // --- 4096 ---

//render the next following items
export const LIST_RENDER_GAP = 30;

//default theme
export const DEFAULT_THEME = {
    "primary": {
        "main": '#2c353e',
        "light": '#565d64',
        "dark": '#1e252b',
        "contrastText": '#ffffff'
    },
    "secondary": {
        "main": "#f50057",
        "light": "#f73378",
        "dark": "#ab003c",
        "contrastText": "#ffffff"
    },
    "error": {
        "main": "#f44336",
        "light": "#e57373",
        "dark": "#d32f2f",
        "contrastText": "#fff"
    },
    "speedDial":{
        "speedDialBackground": "#2c353e",
        "speedDialBackgroundHover": "#1e252b",
        "speedDialText": "#ffffff",
        "speedDialIcons": "#676767",
        "speedDialActionBackground": "#e6e6e6",
    },
    "others": {
        "selectedTabs": "#d64040",
        "subHeader": "#f1f2f3",
        "subHeaderText": "#000000de",
        "dialogHeader": "#808080",
        "dialogHeaderText": "#000000de",
        "selectedItems": "green",
        "menuIcons": "#000000de",
        "tipsBackground":'#2c353e',
        "tipsText": '#ffffff',
    },
    "radiuses": {
        "buttons": "5px",
        "icons": "50%"
    },
    "background": {
        "default": "#fcfcfc",
        "paper": "#ffffff",
        "paperContrastText": "#000000de",
    },
    "fonts": {
        "mainColor": "#000000",
        "customFont": "\"Roboto\", \"helvetica\", \"Arial\", sans-serif"
    }
};

// SELECT OPERATORS
export const SELECT_OPERATORS = {
    "EQUALS": "eq",
    "NOT_EQUALS": "ne",
    "REGEX": "regex",
    "EXISTS": "exists",
    "NOT_EXISTS": "nexists",
    "LESSER_OR_EQUALS": "lte",
    "LESSER_THAN": "lt",
    "GREATER_OR_EQUALS": "gte",
    "GREATER_THAN": "gt",
    "OR": "or",
    "AND": "and",
    "IN": "in",
    "SELF": "self",
};

//DOUBLE AUTHENTICATION DEFAULT ACTIONS
export const DOUBLE_AUTHENTICATION_DEFAULT_ACTIONS = {
    "create": 'create',
    "read": 'read',
    "update": 'update',
    "delete": 'delete'
}

// DOUBLE AUTHENTICATION ACTION
// It describe all action linked to an action name.
// For example, the action 'update' of a fileset is linked to the action 'update' of a chunk.
// storeName: {
//     actionName:{
//        concernedStore:[actionList]
//     }
// }
export const DOUBLE_AUTHENTICATION_ACTIONS = {
    "chunks": {
        "savechunk": {
            filesets: ['update']
        }
    }
}