import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Zoom from '@material-ui/core/Zoom';
import PropTypes from 'prop-types';
import React from 'react';
import { MyComponent } from '../../MyComponent.jsx';
import { translate } from '../../../../../SharedLibs/Langs.js';

const styles = {
	errorTitle : {
		fontSize : 35,
		fontWeight: "bold"
	}
}

class InfoDisplay extends MyComponent {

	constructor ( props ) {
		super ( props );

		this.setMessage = this.setMessage.bind(this);
		this.closeMessage = this.closeMessage.bind(this);

		this.state = {
			"open" : false,
			"message": undefined,
			"title": undefined,
		};
	}

	closeMessage() {
		this.setState({ "title": undefined, "message": undefined, 'open': false });
	}

	/**
	* Set the way to contact me
	*/
	componentDidMount() {
		this.props.global.registerFunction('info', this.setMessage)
	}

	/**
	* Unregister the setMessage function
	*/
	componentWillUnmount() {
		this.props.global.registerFunction('info', undefined )
	}


	/**
	* Add a message to display
	*
	*/
	setMessage( title, message ) {
		let t = title ? title : translate("Information");
		this.setState({ "title": t, "message": message, 'open': true });
	}


	render() {
		return (
			<Dialog
				id="information"
				open={ this.state.open }
				TransitionComponent={Zoom}
				onClose={this.closeMessage}
			>
					
				<DialogTitle>
					<span className="infoTitle">{this.state.title}</span>
				</DialogTitle>	
				<DialogContent>
					{ typeof this.state.message === 'string' ?
						<div id="infoText" className="text" dangerouslySetInnerHTML={ { "__html" : this.state.message } } />
						:
						this.state.message
					}
				</DialogContent>
				<DialogActions>
					<Button 
						id="infoSubmit"
						variant="contained"
						color='secondary'
						
						onClick={this.closeMessage}
					>
						{ translate ("Ok") }
					</Button>
				</DialogActions>	
				</Dialog>
			);
		}



	}


	InfoDisplay.propTypes = {
		'global': PropTypes.object,
		'classes': PropTypes.object,
	};
  
	export default withStyles(styles)(InfoDisplay)