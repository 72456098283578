// ---- Autogenerate by translate.js (BW) ----------

// --------------
// -------------- Part inter
// --------------

export const inter = {
    "root": {
        "Rename fileset": "Rename vault",
        "Rename file or folder": "Rename file or folder",
        "Add": "Add",
        "App config": "App config",
        "Send messages": "Send messages",
        "Status": "Status",
        "createUser": "Create users",
        "createFileset": "Create vaults",
        "createQuorumGroup": "Create quorums",
        "createGroup": "Create groups",
        "Reply": "Reply",
        "Home": "Home",
        "Chats": "Chats",
        "Devices": "Devices",
        "Selected": "Selected",
        "Guest": "Guest",
        "Chat": "Chat",
        "Rename": "Rename",
        "Cut": "Cut",
        "Paste": "Paste",
        "Copy": "Copy",
        "Open": "Open",
        "Loading content": "Loading content",
        "File ready to be moved": "File ready to be moved",
        "New repository": "New repository",
        "Click to chat with...": "Click to chat with...",
        "Click to add a user": "Click to add a user",
        "Click to add a friend": "Click to add a friend",
        "New URL": "New URL",
        "New quorum": "New quorum",
        "New group": "New group",
        "URLs": "Invitations",
        "GCU": "GCU",
        "Configuration": "Configuration",
        "Administration": "Administration",
        "Last modification": "Last modification",
        "Access validation in progress": "Identity and access validation",
        "Your access is under validation": "Your identity and the right to access this repository must be validated by",
        "You may ask somebody else to access this repository": "If you dont want to wait, you may ask somebody else to access this repository (on the 'Shares' tab)",
        "Quorums": "Quorums",
        "Emails": "Emails",
        "Description": "Description",
        "Properties": "Properties",
        "Settings": "Settings",
        "Size": "Size",
        "Edit parameters": "Edit parameters",
        "Dataroom not decypherable": "Dataroom not decypherable",
        "Please ask someone for access": "You have been invited to this vault, you can accept the invitation in the alert center",
        "Please ask an administrator": "Please ask an administrator",
        "About": "About",
        "Vault": "Vault",
        "Vaults": "Vaults",
        "Help": "Help",
        "Logout": "Logout",
        "Preview": "Preview",
        "Decrypt": "Decrypt",
        "Decrypt and enter": "Decrypt and enter",
        "Later": "Later",
        "Refuse": "Refuse",
        "Close": "Close",
        "Accept": "Accept",
        "Unknown": "Unknown",
        "Unknown fileset": "Removed vault",
        "Sort by": "Sort by",
        "Sorted by": "sorted by",
        "Name": "Name",
        "Expire": "Expire",
        "Need Passphrase": "Need your passphrase",
        "Need Password": "Need your password",
        "I_Need_Passphrase": "Please enter your password to decrypt your data. We will use one of your following key : ",
        "I_Need_Password": "Please enter your password to decrypt your data. We will use one of your following key : ",
        "Change password": "Change password",
        "Last Update": "Last Update",
        "Expiration date": "Expiration date",
        "Creation date": "Creation date",
        "Last connection": "Last connection",
        "Users": "Users",
        "Rights": "Rights",
        "Manage rights": "Manage rights of",
        "Yes": "Yes",
        "No": "No",
        "Cancel": "Cancel",
        "no informations": "no informations",
        "Loading": "Loading",
        "Dataroom crypted": "Dataroom crypted",
        "Owner": "Owner",
        "Details": "Details",
        "Ok": "Ok",
        "Preferences": "Preferences",
        "Comments": "Comments",
        "General": "General",
        "Date": "Date",
        "Crypted": "Crypted",
        "Logs": "Logs",
        "Loading and decrypting file": "Loading and decrypting file",
        "Crypted thread": "Crypted thread",
        "Act as": "Act as",
        "Go back": "Go back",
        "Next": "Next",
        "Share": "Share",
        "Shares": "Shares",
        "Select people": "Select people",
        "Add custom email": "Add custom email",
        "Subject": "Subject",
        "Copy to me": "Copy to me",
        "Prev": "Prev",
        "Message": "Message",
        "Share with people(s)": "Share with people(s)",
        "Invite": "Invite",
        "Delete": "Delete",
        "Theme": "Theme",
        "Themes": "Themes",
        "Dashboard": "Dashboard",
        "Delete my account ?": "Delete my account ?",
        "Delete my account": "Delete my account",
        "Delete the fileset": "Delete the vault",
        "New password": "New password",
        "No, I am sorry": "No, I am sorry",
        "No action required": "No action required",
        "I remember": "I remember",
        "Enter": "Enter",
        "Enter a new password": "Enter a new password",
        "Enter a name": "Enter a name",
        "You will be able to change this name at anytime in the vault properties": "You will be able to change this name at anytime in the vault properties",
        "Change": "Change",
        "Everybody": "Everybody",
        "No deposit limit": "no deposit limit",
        "No limit": "no limit",
        "Me": "Me",
        "Deposit remains": "Deposit remains",
        "Drop here your files or press the '+' button to add them": "Drop here your files or press the '+' button to add them",
        "Subject of the deposit": "Subject of the deposit",
        "For": "For",
        "Number of deposits": "Number of deposits",
        "Send Link to User ?": "Send Link to User ?",
        "Create": "Create",
        "URL deleted": "URL deleted",
        "Saved": "Saved",
        "Downloaded": "Downloaded",
        "Decyphering ok": "Decyphering ok",
        "Quorum group deleted": "Quorum group deleted",
        "Group deleted": "Group \"{name}\" deleted",
        "User deleted": "User \"{login}\" deleted",
        "File deleted": "File \"{name}\" deleted",
        "User locked": "User \"{login}\" locked",
        "User unlocked": "User \"{login}\" unlocked",
        "Send": "Send",
        "Encrypt and send": "Encrypt and send",
        "Groups": "Groups",
        "Request in progress": "Request in progress",
        "An email has been sent": "An email has been sent to \"{email}\"",
        "Refuse access": "Refuse access",
        "Accept access": "Accept access",
        "Accept access with quorum": "Accept access with quorum \"{name}\"",
        "Refuse access with quorum": "Refuse access with quorum \"{name}\"",
        "Ask for access": "Ask for access",
        "Cancel request": "Cancel request",
        "Remove": "Remove",
        "fileset deleted": "\"{name}\" deleted",
        "Are you sure to delete fileset ?": "Are you sure to delete \"{name}\" ?",
        "Are you sure to delete file ?": "Are you sure to delete file \"{name}\" ?",
        "Are you sure to delete User ?": "Are you sure to delete User \"{login}\" ?",
        "Are you sure to delete Group ?": "Are you sure to delete Group \"{name}\" ?",
        "Are you sure to lock User ?": "Are you sure to lock User \"{login}\" ?",
        "Are you sure to unlock User ?": "Are you sure to unlock User \"{login}\" ?",
        "Are you sure to delete QuorumGroup ?": "Are you sure to delete QuorumGroup \"{name}\" ?",
        "Password": "Password",
        "Password confirmation": "Confirmation du mot de passe",
        "You need to change your password": "You need to change your password",
        "Password for this key": "Password for this key",
        "Username": "Username",
        "User login": "User login",
        "User email": "User email",
        "User password": "User password",
        "User created": "User created",
        "New user": "New user",
        "Tchat": "Tchat",
        "There is currently no selected thread": "There is currently no selected thread",
        "Chose someone in the list below": "Chose someone in the list below",
        "Select a friend": "Select a friend",
        "No member(s)": "No member(s)",
        "Select a user": "Select a user",
        "Use secondary click to use available actions": "Use secondary click to use available actions",
        "The list is empty": "The list is empty",
        "You are not allowed to see this content": "You are not allowed to see this content",
        "Create a new user account": "Create a new user account",
        "Create a new quorum group": "Create a new quorum group",
        'Create a new share link': 'Create a new share link',
        "User passwordConfirmation": "User password confirmation",
        "No account yet ?": "No account yet ?",
        "I forget my password": "I forgot my password",
        "Password reinitialization": "Password reinitialization",
        "Few steps to reinitialize your password": "Please follow this steps to reinitialize your password",
        "Login": "Login",
        "Login LDAP": "Login LDAP",
        "Display": "Display",
        "Display Name": "Display Name",
        "DisplayName changed": "Visual name changed to \"{displayName}\"",
        "Locked": "Locked",
        "LoginName": "Login",
        "Welcome to lynvictus": "Welcome to lynvictus",
        "Please follow this steps for creating an account. It takes only few seconds.": "Please follow this steps for creating an account. It takes only few seconds.",
        "Choose a password": "Choose a password",
        "Email": "Email",
        "Already have an account ?": "Already have an account ?",
        "Verify your identity by Email": "Verify your identity by Email",
        "Log and work": "Log and work",
        "A email has been sent": "A email has been sent to ",
        "Please check your email and click on the verification link.": "Please check your email and click on the verification link. If you do not receive this email, please also check your spam or bulk email folder.",
        "A email has been sent. Please check your email and click on the link to choose a new password": "A email has been sent to \"{email}\". Please check your email and click on the link to choose a new password.",
        "Congratulation": "Congratulation",
        "Your email is validated. Log to start !": "Your email is validated. Log to start !",
        "Your email is validated": "Your email has successfully been verified.",
        "You can close this tab": "You can close this tab.",
        "Error": "Error",
        "Your email cannot be validate !": "Your email cannot be validate !",
        "The key cannot be registered !": "The key cannot be registered !",
        "Never": "Never",
        "You can paste some text that will be encrypted too": "You can paste some text that will be encrypted too",
        "Send a confirmation email to me": "Send a confirmation email to me",
        "To": "To",
        "Password reinitialized": "Password reinitialized",
        "Anonymous files transfered achieved": "Anonymous files transfered to \"{name}\" achieved",
        "Ready": "Ready",
        "Sharing": "Sharing",
        "Secreting": "Secreting",
        "Global information": "Global information",
        "Select administrators": "Select administrators",
        "Quorum": "Quorum",
        "Quorum group created": "Quorum group created",
        "Group created": "Group created",
        "Group name": "Group name",
        "Send files to": "Send files to \"{name}\"",
        "Actor": "Actor",
        "Files": "Files",
        "Object": "Object",
        "All users": "All users",
        "All groups": "All groups",
        "All quorum groups": "All quorum groups",
        "All filesets": "All vaults",
        "Application": "Application",
        "Generic": "Generic",
        "All": "All",
        "Sorry, quorum group not found": "Sorry, quorum group not found",
        "Sorry, group not found": "Sorry, group not found",
        "Save": "Save",
        "Untitle": "Untitle",
        "Untitled": "Untitled",
        "User": "User",
        "Group": "Group",
        "Quorum group": "Quorum group",
        "Fileset": "Dataroom",
        "Invite guest(s)": "Invite guest(s)",
        "Are you sure to destroy your account ?": "Are you sure to destroy your account ? It will delete all your documents.",
        "Sorry, unable to display this file": "Sorry, unable to display this file",
        "Give rights": "Give rights",
        "Manage": "All",
        "Download": "Download",
        "Add file": "Add or modify file(s)",
        "Add comments": "Add or modify text",
        "Add share": "Share",
        "Add Guest": "Share to guest",
        "See logs": "See logs",
        "Edit": "Edit",
        "Send a customized notification email": "Send a customized notification email",
        "Logged": "Logged",
        "See": "View",
        "Crypting and saving fileset": "Crypting and saving fileset",
        "Create users": "Create users",
        "Create filesets": "Create vaults",
        "Create groups": "Create groups",
        "Create quorum groups": "Create quorum groups",
        "Lock": "Lock",
        "UnLock": "UnLock",
        "Migrate": "Migrate",
        "Add users": "Manage users",
        "Add a user": "Add a user",
        "This is this link to send": "Here is the link to copy into a email",
        "Confirmation": "Confirmation",
        "Enter your email": "Enter your email",
        "reinitConfirm": "Be careful, your access to all non-shared data will be lost during the procedure",
        "Well done": "Well done",
        "Link sent": "Link sent",
        "Link": "Link",
        "No u2f key named": "No u2f key named \"{name}\"",
        "Notification deleted": "Notification deleted",
        "Thread deleted": "Thread deleted",
        "Delete the current thread": "Delete the current thread",
        "Delete all threads": "Delete all threads",
        "Are you sure to delete this thread ?": "Are you sure to delete this thread ?",
        "All threads deleted": "All threads deleted",
        "Are you sure to delete all threads ?": "Are you sure to delete all threads ?",
        "Removed user from group ?": "Removed user \"{login}\" from group \"{group}\" ?",
        "User removed from group": "User \"{login}\" removed from group \"{group}\"",
        "User added to group": "User \"{login}\" added to group \"{group}\"",
        "Share removed": "Share removed",
        "Access requested": "Access requested",
        "Access request canceled": "Access request canceled",
        "Access refused": "Refused access",
        "Access accepted": "Accepted access",
        "Download file": "Download file",
        "Name of the fileset": "Name of the vault",
        "Name of the user": "Name of the user",
        "Name of the file": "Name of the file",
        "File(s) added or modified": "File(s) added or modified",
        "Fileset created": "Fileset created",
        "Are you sure to delete directory ?": "Are you sure to delete directory \"{name}\" and all its content ?\"",
        "Are you sure to delete folder ?": "Are you sure to delete vault's folder \"{name}\" ?",
        "New folder": "New folder",
        "Folder created": "Folder created",
        "Folder": "Folder",
        "Number of files": "{files} file(s)",
        "Share(s) added": "Share(s) added",
        "Automatic ask for access": "Automatic ask for access",
        "Answer to a access request": "Answer to a access request",
        "Answer the access request in the contextual menu": "Answer the access request with more options in the contextual menu",
        "Automatic Ask for cryptographic access": "\"Ask to \"{giverName}\" for access to \"{filesetName}\" ?",
        "User wants cryptographic access": "User \"{wanterName}\" ask \"{wantedName}\" for access to \"{filesetName}\".",
        "Ask cryptographic access": "Ask \"{giverName}\" access to \"{filesetName}\" ?",
        "Or go directly in the fileset for this operation": "Or go directly in the vault for this operation.",
        "Do you agree, please verify his identify ?": "Do you agree, please verify his identify ?",
        "Quorum Group initialisation phase 1": "Quorum Group initialisation phase 1",
        "initialise your cryptographic secret": "As an administrator of quorum group \"{name}\", you must initialise your cryptographic secret. Let's go ?",
        "Quorum Group initialisation phase 2": "Quorum Group initialisation phase 2",
        "you must do the last step of initialisation": "As an administrator of quorum group \"{name}\", you must do the last step of initialisation. Let's go ?",
        "Wrong passphrase": "Wrong passphrase",
        "Change passphrase": "Change passphrase",
        "Passphrase close to be strong": "Passphrase close to be strong",
        "Passphrase good but not enough": "Passphrase good but not enough",
        "Passphrase not so bad... ok": "Passphrase not so bad... ok",
        "Passphrase too weak, do better": "Passphrase too weak, do better",
        "Passphrase too weak": "Passphrase too weak",
        "Passphrase realy too weak": "Passphrase realy too weak",
        "Passphrase cannot be empty": "Passphrase cannot be empty",
        "Passphrase initialization": "Passphrase initialization",
        "Passphrase dropped": "Passphrase dropped",
        "Passphrase": "Passphrase",
        "Fido autentication successfull": "Fido autentication successfull",
        "New passphrase": "New passphrase",
        "Current passphrase": "Current passphrase",
        "Current password": "Current password",
        "Initialize passphrase": "Initialize passphrase",
        "Keys initialized": "Keys initialized",
        "Crypting key": "Crypt key",
        "Signing key": "Sign key",
        "Quorum key": "Quorum key",
        "Quorum member key": "Quorum member key",
        "Revoked": "Revoked",
        "InProgress1": "This quorum key is waiting for a first quorum approvable",
        "InProgress2": "This quorum key is waiting for a second quorum approvable",
        "Cancel in progress": "Cancel in progress",
        "Set": "Set",
        "Valid": "Valid",
        "In progress": "In progress",
        "Created by": "By {name}",
        "OR": "OR",
        "By": "By",
        "actions required": "action(s) required",
        "CREATE AN ACCOUNT": "CREATE AN ACCOUNT",
        "Create an account": "Create an account",
        "Back to login": "Back to login",
        "Back to home": "Back to home",
        "I read and I accept terms and conditions": "I read and I accept terms and conditions",
        "LOGIN TO YOUR ACCOUNT": "LOGIN TO YOUR ACCOUNT",
        "Problems or questions ? call us at": "Problems or questions ? call us at tel : \"{tel}\" or mail : \"{email}\"",
        "Shared with": "Shared with {name}",
        "You dont want another account ?": "You dont want to create another account ?",
        "Authenticate with google": "You can authenticate yourself with your google account !",
        "Auth with Google": "Connexion with Google",
        "Email deleted": "Email \"{email}\" deleted",
        "Are you sure to drop email ?": "Are you sure to drop \"{email}\" ?",
        "Group name changed": "Group name changed to \"{name}\"",
        "Name changed": "Name changed to \"{name}\"",
        "Description changed": "Description changed to \"{name}\"",
        "Avatar changed": "Avatar changed",
        "Avatar": "Avatar",
        "Identity": "Identity",
        "Group description changed": "Group description changed",
        "Fileset description changed": "Fileset description changed",
        "Expiration motified": "New expiration {expire}",
        "Share expiration motified": "Share expiration motified",
        "u2f key registered": "u2f key registered",
        "u2f key deleted": "u2f key \"{deviceName}\" deleted",
        "u2f key selected": "u2f key \"{deviceName}\" selected",
        "Are you sure to drop u2f key ?": "Are you sure to erase u2f key \"{deviceName}\" ?",
        "New u2f device": "New u2f device",
        "Your key is registered": "Your key is registered",
        "Empty": "Empty",
        "This field can't be empty": "This field can't be empty",
        "Fido autentication": "Fido autentication",
        "Email not verified": "Email not verified",
        "Fido device not confirmed": "Fido device not confirmed",
        "as_send_you_crypted_notification": "as sent you {n} crypted notification(s)",
        "Write here a new message": "Write here a new message",
        "Edit the current message": "Edit the current message",
        "Reinitialisation": "Reinitialisation",
        "crypted notification": "Crypted notification",
        "crypted message": "Crypted message",
        "List": "List",
        "small": "Small",
        "medium": "Medium",
        "big": "Big",
        "View list": "View list",
        "View tiles": "View tiles",
        "Sort ascending": "Sort ascending",
        "Sort descending": "Sort descending",
        "Start date": "Start date",
        "End date": "End date",
        "You can chose a period to show between theses two dates": "Chose a period to show between theses two dates",
        "You need more rights to see this content": "You need more rights to see this content",
        "Elements count to show": "Elements count to show",
        "Show more elements": "Show more elements",
        "The start date must be p,rior to the end date": "The start date must be prior to the end date",
        "Unlimited": "Unlimited",
        "Storage utilization on your instance": "Storage utilization on your instance",
        "Usage statistics": "Usage statistics",
        "Percent": "Percent",
        "Heaviest filesets": "Heaviest vaults",
        "Last created users": "Last created users",
        "Users with largest data storage": "Users with largest data storage",
        "Wanted Accepted": "Access given",
        "Wanted Refused": "Ok. Access denied",
        "Wanted Asked": "Access authorisation given",
        "A quorum need an action": "A quorum needs an action",
        "A fileset need an action": "A vault needs an action",
        "Number of use": "Number of use",
        "Search": "Search",
        "No result found": "No result found",
        "My emails": "My emails",
        "My logs": "My logs",
        "My password": "My password",
        "My account": "My account",
        "Invitations": "Invitations",
        "My keys": "My keys",
        "Device without name, you may had a new one": "Device without name, you may had a new one",
        "Change name": "Change name",
        "Change description": "Change description",
        "When the keystore is set on `don't care`, you will be able to encrypt data for a receiver even if you don't know him": "When the keystore is set on `don't care`, you will be able to encrypt data for a receiver even if you don't know him",
        "Keys": "Keys",
        "Signed keys": "Signed keys",
        "Signed by": "Signed by",
        "Signed since": "Signed since",
        "The keys you signed": "The keys you signed",
        "Private key is in memory ?": "Private key is in memory ?",
        "Private key in memory up to": "Private key in memory up to",
        "Release": "Release",
        "Finger print": "Finger print",
        "Drop": "Drop",
        "Click to upload a new file": "Click to upload a new file",
        "Create a new Quorum with these devices": "Create a new Quorum with these devices",
        "You are on this device": "You are on this device",
        "Unknown Key Or Removed Key": "Unknown Key Or Removed Key",
        "Unknown user": "Unknown user",
        "Unknown type": "Unknown type",
        "An old key which isn't existing anymore": "An old key which isn't existing anymore",
        "Signature not found": "Signature not found",
        "Creation date not found": "Creation date not found",
        "Don't care": "Don't care",
        "Normal": "Normal",
        "Paranoid": "Paranoid",
        "Show debug functions": "Show debug functions",
        "Should be greater than the application trust level": "Should be greater than the application trust level",
        "Use care when changing the following settings": "Use care when changing the following settings, you can make your account unusable, contact the support if you have some questions",
        "Advanced search on users": "Advanced search on users",
        "Advanced search on vaults": "Advanced search on vaults",
        "No item(s) selected yet": "No item(s) selected yet",
        "Vaults shared with": "Vaults shared with",
        "Remove existing filters": "Remove existing filters",
        "Send a custom email": "Send a custom email",
        "Friends": "Connections",
        "New friend": "New connection",
        "Trusted connection": "Trusted connection",
        "Which keys will be used to sign ?": "Which keys will be used to sign ?",
        "Sign friend": "Trust a connection",
        "Are you sure to signed this friend ?": "Are you sure to trust this connection ?",
        "Friend deleted": "Connection deleted",
        "Are you sure to delete Friend ?": "Are you sure to delete this connection ?",
        "Add friend": "Add a connection",
        "Advanced search on groups": "Recherche avancée sur les groupes",
        "Friend signed": "Connection trusted",
        "Continue": "Continue",
        "Signed By": "Signed by",
        "Back to": "Back to \"{elName}\"",
        "must be a string": "Must be a string",
        "You will be able to change this name at anytime in the folder properties": "You will be able to change this name at anytime in the folder properties",
        "No share user selected": "No share user selected",
        "Fileset renamed": "Fileset renamed",
        "by": "By",
        "by ": "By ",
        "Rename fileSet": "Rename fileSet",
        "fileset closed": "Vault closed",
        "fileset open": "Vault opened",
        "Move": "Move",
        "FileSet Moved": "Vault moved",
        "validate": "Validate",
        "file validated": "File validated",
        "Type": "Type",
        "Role": "Role",
        "share deleted": "Share deleted",
        "Share deleted": "Share deleted",
        "Are you sure to delete share ?": "Are you sure to delete share ?",
        "Login name": "Login name",
        "lock": "lock",
        "Are you sure to lock": "Are you sure to lock",
        "Are you sure to delete user ?": "Are you sure to delete user ?",
        "Update": "Update",
        "Member of": "Member of",
        "No group(s)": "No group(s)",
        "Quorum groups": "Quorum groups",
        "Advanced search on quorums": "Advanced search on quorums",
        "QuorumGroup deleted": "QuorumGroup deleted",
        "Are you sure to delete quorum group ?": "Are you sure to delete quorum group ?",
        "Quorum Group name": "Quorum Group name",
        "Threshold": "Threshold",
        "Members": "Members",
        "Are you sure to delete group ?": "Are you sure to delete group ?",
        "IP limitation": "IP limitation",
        "Email limitation for invitation": "Email limitation for invitation",
        "Groups of Managers": "Groups of Managers",
        "Add configuration for a new group": "Add configuration for a new group",
        "password for verification": "Password for verification",
        "Your trust level": "Your trust level",
        "Default": "Default",
        "Send test mail": "Send test mail",
        "Add a new mail": "Add a new mail",
        "Folder name": "Folder name",
        "Visibility": "Visibility",
        "Not visible if empty": "Not visible if empty",
        "Visible for members below": "Visible for members below",
        "Clear": "Opened",
        "Drop your files here": "Drop your files here",
        "Rename file": "Rename file",
        "Rename folder": "Rename folder",
        "Move to": "Move \"{elName}\" to",
        "Fileset moved": "Fileset moved",
        "Element moved": "Element moved",
        "Click": "Click",
        "Or drop your files here": "Or drop your files here",
        "Advanced search on files": "Advanced search on files",
        "locked": "Locked",
        "Create a new group": "Create a new group",
        "No group selected": "No group selected",
        "Select a group": "Select a group",
        "No notifications": "No notifications",
        "My friends": "My friends",
        "New url": "New url",
        "Folder deleted": "Folder deleted",
        "Fileset deleted": "Fileset deleted",
        "Deleting done": "Deleting done",
        "Repository created": "Repository created",
        "New fileset": "New vault",
        "Rename url": "Rename url",
        "Are you sure to delete Url ?": "Are you sure to delete Url ?",
        "Number of available deposits": "Number of available deposits",
        "Given by": "Given by",
        "in folder": "in folder",
        "Not authorized": "Not authorized",
        "Can create new filesets": "You can create vaults",
        "Can create directories": "You can create directories",
        "Can modify this rights": "You can modify these rights",
        "Can delete this directory": "You can delete this directory",
        "Can modify subdir rights": "You can modify subdir rights",
        "Your current rights": "Your current rights",
        "Avoid validation for": "Avoid validation for",
        "Validators for new shares": "Validators for new persons accessing this safe",
        "Validators for content": "Validators for content",
        "Recovering friends": "Recovering friends",
        "Back": "Back",
        "New share": "New share",
        "New": "New",
        "Url created & Link sent": "Url created & Link sent",
        "browser error": "Your browser is outdated or incompatible with the application.",
        "Please use another browser": "Please upgrade or install Firefox / Chrome / Edge(chromium)",
        "Critical error": "Critical error",
        "Show previous path": "Show previous path",
        "More infos": "More infos",
        "Protect your key": "Protect your key",
        "Fake": "Fake Action",
        "Did something": "Did something",
        "Your account is created and ready to use": "Your account is created and ready to use",
        "Some notifications": "Some notifications",
        "Decrypt in progress": "Decrypt in progress",
        "Adding share(s)": "Adding share(s)",
        "Url created": "Url created",
        "Url updated": "Url updated",
        "Create a new url": "Create a new url",
        "Link name": "Link name",
        "must be filled": "must be filled",
        "Recipient emai": "Recipient emai",
        "must be an emai": "must be an emai",
        "must be a number": "must be a number",
        "My Two Factors": "My two factors",
        "TwoFactors": "Two factor authentication",
        "Add a new Phone": "Add Phone",
        "New device": "New device",
        "You are going to remove a 2FA device, are you sure?": "You are going to remove a 2FA device, are you sure?",
        "Your phone has been added.": "Your phone has been added.",
        "Confirm phone": "Confirm phone",
        "Add a phone to your account": "Add a phone to your account",
        "Add a key to your account": "Add a key to your account",
        "Invalid code": "Invalid code",
        "Two Factor Authentication": "Two Factor Authentication",
        "Please follow the instructions to ensure your identity": "Please follow the instructions to ensure your identity",
        "No Two Factor Authentication is set, please add one": "No Two Factor Authentication is set, please add one",
        "Enter the code": "Enter the code",
        "Too many attempts": "Too many attempts",
        "Device already set": "Device already set",
        "Please plug in your key to register": "Please plug in your key to register",
        "Register": "Register",
        "Error when registering device": "Error when registering device",
        "Invalid key": "Invalid key",
        "Please plug in and active your key": "Please plug in and active your key",
        "Advanced search on folders": "Advanced search on folders",
        "First page": "First page",
        "Previous page": "Previous page",
        "Next page": "Next page",
        "Last page": "Last page",
        "Number of items to display": "Number of items to show",
        "multiConfirm": "Are you sure to \"{actionLabel}\" these elements ?",
        "Select": "Select",
        "Suppression": "Suppression",
        "Friend signing": "Friend signing",
        "cancelWanted": "Cancel request",
        "This is not a valid String": "This is not a valid String",
        "Add a device": "Add a device",
        "No device set, please add one": "No device set, please add one",
        "Personal Settings": "Personal Settings",
        "Maintenance": "Maintenance",
        "Maintenance end : ": "Maintenance end : \"{elName}\"",
        "Logs have been downloaded": "Logs have been downloaded",
        "Decrypt ok ": "Decrypt ok",
        "Canceled": "Cancelled",
        "My devices": "My devices",
        "New Fileset": "New vault",
        "New Folder": "New vault folder",
        "Rename FileSet": "Rename vault",
        "Decrypt ok": "Decrypt ok",
        "already exist": "Name already exists",
        "New directory": "New directory",
        "Directory created": "Directory created",
        "File added created": "File added",
        "Selection mode": "Selection mode",
        "Select Display": "Select Display",
        "Show filesets where I'm": "Show vaults where I'm",
        "In Shared with": "Shared with",
        "Content Validator only": "Content Validator only",
        "In Quorum only": "In Quorum only",
        "Show": "Show",
        "Filesets": "Filesets",
        "Folders": "Folders",
        "Login already exist": "Login already exists",
        "email": "email",
        "Message deleted": "Message deleted",
        "add": "Add",
        "your message is too long": "Your message is too long ({length} / {maxLength})",
        "Fileset name": "Fileset name",
        "Updated": "Updated",
        "Copied to clipboard": "Copied to clipboard",
        "Add an email to your account": "Add an email to your account",
        "30 chars max": "30 chars max",
        "Url renamed": "Url renamed",
        "Already exists": "Name already exists",
        "Are you sure to give access to ?": "Are you sure to give access to {name}?",
        "All logs decrypted": "All logs decrypted",
        "File renamed": "File renamed",
        "Share created": "Share created",
        "Share updated": "Share updated",
        "roles": "roles",
        "rights": "rights",
        "Are you sure to delete thoses shares ?": "Are you sure to delete these shares ?",
        "Ip address": "IP address",
        "Owner name": "Owner name",
        "Object Name": "Object name",
        "Advanced search on logs": "Advanced search on logs",
        "Ip adress": "IP address",
        "validator": "Validator",
        "upload file": "File is uploading",
        "Encrypted notification": "Encrypted notification",
        "unread": "unread",
        "Notifications": "Notifications",
        "There is no message, write an new one in text field below": "There is no message, write an new one in text field below",
        "quorumGroups": "Quorum groups",
        "New quorum group": "New quorum group",
        "Quorum Group created": "Quorum group created",
        "Quorum deleted": "Quorum deleted",
        "Are you sure to delete thoses quorum groups ?": "Are you sure to delete these quorum groups ?",
        "Are you sure to delete thoses users ?": "Are you sure to delete these users ?",
        "Are you sure to delete thoses groups ?": "Are you sure to delete these groups ?",
        "Group updated": "Group updated",
        "Decrypt all logs": "Decrypt all logs",
        "Decrypt all": "Decrypt all",
        "All logs that you have right to decrypt are so": "All logs that you have right to decrypt are so",
        "validated": "validated",
        "Configuration updated": "Configuration updated",
        "Reading file": "Reading file",
        "user": "user",
        "content validator": "Content validator",
        "recover": "recover",
        "verification mismatch": "verification mismatch",
        "No todos": "No todo left",
        "No quorum group": "No quorum group",
        "no log": "No log",
        "No group": "No group",
        "No friend": "No friend",
        "No file": "No file",
        "No device registered": "No device registered",
        "Recipient email": "Recipient email",
        "must be an email": "must be an email",
        "Creating Directory": "Creating Directory",
        "Folder renamed": "Folder renamed",
        "Quorum group updated": "Quorum group updated",
        "Quorumgroups": "Quorum groups",
        "The threshold cannot be more than then number of admins": "The threshold cannot be more than then number of admins",
        "password": "password",
        "Create a new user": "Create a new user",
        "User updated": "User updated",
        "Please remove quorum group from folders configuration before deleting it": "Please remove quorum group from folders configuration before deleting it",
        "Are you sure to delete url ?": "Are you sure to delete url ?",
        "Validated": "Validated",
        "Download all logs": "Download all logs",
        "See shares": "See shares",
        "Access granted": "Access granted",
        "This url is no longer available": "This url is no longer available",
        "Please ask the sender to send you a new one.": "Please ask the sender to send you a new one.",
    }
};

// --------------
// -------------- Part errors
// --------------
export const errors = {
    "root": {
        "Administrative size exceeded": "The maximum size is reached. Your file was not saved. Please contact your administrator",
        "File is already a folder": "Cannot upload file \"{name}\". it is actually a folder",
        "File under saving": "Cannot upload file \"{name}\". it is actually uploading",
        "Warning": "Warning",
        "Failed": "Failed",
        "Cannot delete actually connected device": "Cannot delete actually connected device",
        "Error registration u2f key": "Error registration u2f key",
        "No u2f key registered": "No u2f key registered",
        "U2f Authentication Failed": "U2f Authentication Failed",
        "IEDetect": "Internet Explorer / Edge performances are not good enough today for cryptographic computation." +
            " We advice you to use another web browser (for example, Firefox or Chrome).",
        "IEOK": "Nevertheless, i want to continue with I.E.",
        "Must be a valid email": "Must be a valid email",
        "You must agree to the terms of use": "You must agree to the terms of use",
        "I agree to the terms of use": "I agree to the terms of use",
        "Password cannot be empty": "Password cannot be empty",
        "Password close to be strong": "Password close to be strong",
        "Password good but not enough": "Password good but not enough",
        "Password not so bad... ok": "Password not so bad... ok",
        "Password too weak, do better": "Password too weak, do better",
        "Password too weak": "Password too weak",
        "Password realy too weak": "Password realy too weak",
        "Must be filled": "Must be filled",
        "Must be filled (1-10)": "Must be filled (1-10)",
        "Sorry, object not found": "Sorry, object not found",
        "Sorry, file not found": "Sorry, file not found",
        "Sorry, quorum group not found": "Sorry, quorum group not found",
        "Sorry, URL not found": "Sorry, URL not found",
        "Sorry, thread not found": "Sorry, thread not found",
        "A chunk is missing": "A chunk is missing",
        "The selected File mut be an image": "The selected File mut be an image",
        "Authentication failed": "Authentication failed",
        "Auth type not yet supported": "Auth type not yet supported",
        "Cannot add files to a crypted fileset": "Cannot add files to a crypted vault",
        "Cannot add shares in a non init quorumGroup": "Cannot add shares in a non init quorumGroup",
        "Cannot anonymously modify a fileSet": "Cannot anonymously modify a fileSet",
        "Cannot change text of a crypted fileset": "Cannot change text of a crypted vault",
        "Cannot contact database": "Cannot contact database",
        "Cannot create an existing user": "Cannot create an existing user",
        "Cannot delete an empty element": "Cannot delete an empty element",
        "Cannot delete non initialised notification": "Cannot delete non initialised notification",
        "Cannot delete not my notification": "Cannot delete not my notification",
        "Cannot flush from an empty element": "Cannot flush from an empty element",
        "Cannot get data from an empty element": "Cannot get data from an empty element",
        "Cannot mark non initialised notification": "Cannot mark non initialised notification",
        "Cannot mark, not to me": "Cannot mark, not to me",
        "Cannot migrate a non ready quorumGroup": "Cannot migrate a non ready quorumGroup",
        "Cannot remove files from a crypted fileset": "Cannot remove files from a crypted vault",
        "Confirmation string mismatch": "Confirmation string mismatch",
        "Credential changement failed": "Credential changement failed",
        "Credential type not implemented": "Credential type not implemented",
        "Current user not set": "Current user not set",
        "Database error": "Database error",
        "Database not open": "Database not open",
        "Element not found in Store": "Element not found in Store",
        "Email already exist": "Email already exist",
        "Error CRUD": "Error CRUD",
        "Error while deleting element": "Error while deleting element",
        "Error while flushing element": "Error while flushing element",
        "File not found": "File not found",
        "File reader not done": "File reader not done",
        "FileSet crypted": "FileSet crypted",
        "FileSet not found": "FileSet not found",
        "FileSet not in store": "FileSet not in store",
        "FileSet object not filled": "FileSet object not filled",
        "Group already exist": "Group already exist",
        "Group object not filled": "Group object not filled",
        "Must be auth first": "Must be auth first",
        "Must have valid emails before": "Must have valid emails before",
        "No credentials defined for user": "No credentials defined for user",
        "No credentials type defined for user": "No credentials type defined for user",
        "No fileSet _id to save": "No fileSet _id to save",
        "Not admin of quorumGroup": "Not admin of quorumGroup",
        "Not an admin": "Not an admin",
        "Notification not found in database": "Notification not found in database",
        "QuorumGroup already exist": "QuorumGroup already exist",
        "QuorumGroup no shares for me": "QuorumGroup no shares for me",
        "QuorumGroup not ready for compute Secret": "QuorumGroup not ready for compute Secret",
        "QuorumGroup number of admins too small": "QuorumGroup number of admins too small",
        "QuorumGroup object not filled": "QuorumGroup object not filled",
        "You need to add more users": "You need to add at least {count} more users",
        "Set or select the minimun number of users": "Set or select the minimun number of users",
        "Security violation": "Security violation, \"{login}\" cannot give the right \"{right}\" because he has not this right.",
        "Share not for a group for me": "Share not for a group for me",
        "No admin privatekey for me in this quorumGroup": "No admin privatekey for me in this quorumGroup",
        "Share not for me": "Share not for me",
        "Share not for user": "Share not for user",
        "Share not found in fileset": "Sharing users not found in vault",
        "Signin is forbidden": "Sign in is forbidden",
        "Sign in failed": "Sign in failed",
        "syncGetUniq not return an array": "syncGetUniq not return an array",
        "syncGetUniq return more than one element": "syncGetUniq return more than one element",
        "Template language not found in config": "Template language not found in config",
        "Templates not found in config": "Templates not found in config",
        "Template type not found in config": "Template type not found in config",
        "This store is not a array store. Cannot append": "This store is not a array store. Cannot append",
        "This store is not a array store. Cannot append element": "This store is not a array store. Cannot append element",
        "This store is not a array store. Cannot append simple object": "This store is not a array store. Cannot append simple object",
        "This store is not a object store. Cannot get ids": "This store is not a object store. Cannot get ids",
        "Uid not created": "Uid not created",
        "Uid not deleted": "Uid not deleted",
        "Uid not found in database": "Uid not found in database",
        "Unable to accept wanted": "Unable to accept wanted",
        "Unable to accept wanted because of keys breach": "Unable to accept wanted because of keys breach",
        "Unable to addGuest": "Unable to addGuest",
        "Unable to add share": "Unable to add share",
        "Unable to add share to me": "Unable to add share to me",
        "Unable to add wanted": "Unable to add wanted",
        "Unable to close database": "Unable to close database",
        "Unable to crypt fileset": "Unable to crypt vault",
        "Unable to decrypt FileSet": "Unable to decrypt vault",
        "Unable to initialize fileset": "Unable to initialize vault",
        "Unable to initialize fileset structure": "Unable to initialize vault structure",
        "Unable to refuse wanted": "Unable to refuse wanted",
        "Unable to remove Share": "Unable to remove a sharing user",
        "Unable to remove wanted": "Unable to remove a wanted user",
        "Unable to update FileSet in store": "Unable to update vault in store",
        "Unknown action": "Unknown action",
        "Unknown Group with id": "Unknown Group with id",
        "Unknown QuorumGroup with id": "Unknown QuorumGroup with id",
        "Unknown User with id": "Unknown User with id",
        "User already exist": "User already exist",
        "User has not sent a link": "User has not sent a link",
        "User not find for anonymous share": "User not find for anonymous share",
        "User not found in database": "User not found in database",
        "User not found in FriendsStore": "User not found in FriendsStore",
        "User not found in store": "User not found in store",
        "User not ready": "User not ready",
        "User object not filled": "User object not filled",
        "User with email not found in database": "User with email not found in database",
        "Want to save a clear fileSet ! forbidden": "Want to save a clear fileSet ! forbidden",
        "Worker dont want my key": "Worker dont want my key",
        "Need to release the passphrase first": "Need to release the passphrase first",
        "Cannot change passphrase for local account": "Cannot change passphrase for local account",
        "Cannot change password for non local account": "Cannot change password for non local account",
        "parameter is compliant to anyone from the list": "Parameter \"{name}\" is compliant to anyone from the list",
        "parameter must be object and is null": "Parameter \"{name}\" must be object and is null",
        "parameter must be object and is undefined": "Parameter \"{name}\" must be object and is undefined",
        "parameter must be object and is type": "Parameter \"{name}\" must be object and is type \"{typeOfObj}\"",
        "parameter must be a boolean and is type": "Parameter \"{name}\" must be a boolean and is type \"{typeOfObj}\"",
        "parameter must be a number and is type": "Parameter \"{name}\" must be a number and is type\"{typeOfObj}\"",
        "parameter must be a string and is type": "Parameter \"{name}\" must be a string and is type\"{typeOfObj}\"",
        "parameter must be a email and is type": "Parameter \"{name}\" must be a email and is type\"{typeOfObj}\"",
        "parameter must be a email and is not a regular email": "Parameter \"{name}\" must be a email and is not a regular email",
        "parameter must be an expire and is type": "Parameter \"{name}\" must be an expire and is type\"{typeOfObj}\"",
        "parameter must be an expire and is not a regular expire": "Parameter \"{name}\" must be an expire and is not a regular expire",
        "parameter must be a string conform to a regexp and is type": "Parameter \"{name}\" must be a string conform to a regexp and is type\"{typeOfObj}\"",
        "parameter is not conform to the regexp": "Parameter \"{name}\" is not conform to the regexp",
        "parameter must be an array and is type": "Parameter \"{name}\" must be an array and is type\"{typeOfObj}\"",
        "parameter must be a non null array and is empty": "Parameter \"{name}\" must be a non null array and is empty",
        "bad type array descriptor for": "Bad type array descriptor for \"{name}\"",
        "Dont know how to test": "Dont know how to test \"{name}\"",
        "Email doesnt exist": "Email \"{email}\" doesnt exist",
        "Not enought letters for a search": "Not enought letters for a search",
        "New file": "New file",
        "Open logs": "Open logs",
        "Not Permitted to login at this time": "Not Permitted to login at this time",
        "Not permited to logon at this workstation": "Not permited to logon at this workstation",
        "Password expired": "Password expired",
        "Account disabled": "Account disabled",
        "Account expired": "Account expired",
        "User must reset password": "User must reset password",
        "User account locked": "User account locked",
        "Invalid username/password": "Invalid username/password",
        "Invalid password": "Invalid password",
        "finished": "Finished",
        "move finished": "Move finished",
        "Are you sure to delete thoses elements ?": "Are you sure to delete these elements ?",
        "File downloaded": "File downloaded",
        "Searching": "Searching",
        "You don't have the right to see shares": "You don't have the right to see shares",
        "You don't have the right to see logs": "You don't have the right to see logs",
    }
};

// --------------
// -------------- Part notifications
// --------------
export const notifications = {
    "root": {
        "Sorry": "Sorry",
        "Cool": "",
        "Hey": "",
        "fileset_updated": "has updated",
        "fileset_updateFiles": "has add or modified the files {fileNames} in",
        "fileset_removeFile": "has removed {path} in",
        "fileset_createPath": "has created folder {path} in",
        "fileset_removePath": "has removed folder {path} and its content in",
        "fileset_commentAdd": "has added a new comment in",
        "fileset_commentRemove": "has removed a comment in",
        "fileset_commentChange": "has changed an existing comment in",
        "fileset_deleted": "has deleted",
        "fileset_dropFromShare": "has remove your access to",
        "fileset_newShare": "has shared with you",
        "fileset_addGuest": "has invited you to see",
        "fileset_wanted": "has asked you for an access to",
        "fileset_acceptWanted": "has accepted and give you access to",
        "fileset_cancelWanted": "has refused to give you access to",
        "url_to_fileset": "One of your URL has been used to create the vault \"{name}\".",
        "welcome": "Our team wish you a very nice welcome on board!",
        "Account locked": "Your account is locked!",
        "Account unlocked": "Your account is unlocked",
        "email_confirmed": "Your email \"{email}\" has been confirmed!",
        "mail_error": "an error occured while sending email to \"{email}\"",
        "unknown_notif": "Unknown message!",
        "fileset_updated_text": "\"{user}\" has updated \"{name}\".",
        "fileset_deleted_text": "Sorry, but \"{user}\" has deleted \"{name}\".",
        "fileset_dropFromShare_text": "Sorry, but \"{user}\" has remove your access to \"{name}\".",
        "fileset_newShare_text": "\"{user}\" has shared with you \"{name}\".",
        "fileset_addGuest_text": "\"{user}\" has invited you to see \"{name}\".",
        "fileset_wanted_text": "\"{user}\" has asked you for an access to \"{name}\".",
        "fileset_acceptWanted_text": "\"{user}\" has accepted and give you access to \"{name}\".",
        "fileset_cancelWanted_text": "Sorry, but \"{user}\" has refused to give you access to \"{name}\".",
        "welcome_text": "Our team wish you a very nice welcome on board!",
        "locked_text": "Your account is locked!",
        "unlocked_text": "Your account is unlocked",
        "email_confirmed_text": "Your email has been confirmed!",
        "mail_error_text": "Sorry, an error occured while sending email to \"{email}\"",
        "unknown_notif_text": "Unknown message!",

    }
};


export const mail = {
    "root": {
        "Hi": "Hello {name},",
        "Confirm": "Confirm",
        "This is the revival number": "This is the revival number {revivalNumber}...",
        "Copy past the following link into your browser": "Copy past the following link into your browser",
        "Click the button to confirm": "Click the button to confirm",
        "Here to direct access": "Here to direct access",
        "Here to confirm": "Click here to confirm",
        "A new crypted message": "A new crypted message from {name}",
        "You have received a crypted message": "You just have received a crypted message from {name}",
        "You have received crypted messages": "You have received {n} crypted messages",
        "new crypted messages": "{n} crypted messages for you",
        "one message from": "one message from {name} on, {date}",
        "FileSet Modifications": "{n} modification of dataStores",
        "FileSet Modification": "The dataStore \"{fname}\" has been modified",
        "FileSet Modification content": "{name} has modified the dataStore \"{fname}\" the {date}",
        "FileSet Modifications content": "{n} modifications on datastores has been recently made",
        "one fileset Modification": "The datastore \"{fname}\" has been modified by {name} the {date}",
        "Email validation": "Email validation for \"{email}\"",
        "Last step to register this email into you Lynvictus account": "We need a confirmation for the email \"{email}\".",
        "This email is erased from your lynvictus account": "The email \"{email}\" is erased from your lynvictus account",
        "Your email is not longer valid for your account": "Your email is not longer valid for your account \"{login}\".",
        "This is a test email": "This is a test email.",
        "You have nothing to do": "You have nothing to do. The link is just here to see if html links are visible for you",
        "Link": "Link",
        "Account nearly created": "Your account \"{login}\" is nearly created",
        "Last step to create your account": "Last step to create your account",
        "Please confirm your email with the following link": "Please confirm your email with the following link",
        "Reinitialisation of your Password": "Reinitialisation of your Password",
        "It seems that you have forgotten your password...": "It seems that you have forgotten your password...",
        "Please confirm your new password by clicking the following link": "Please confirm your new password by clicking the following link",
        "FIDO registration key": "FIDO registration key for device \"{deviceName}\"",
        "Last step to register this FIDO Key": "Last step to register this FIDO Key \"{deviceName}\" into you Lynvictus account \"{login}\".",
        "Please confirm the key": "Please confirm the key by the following link",
        "Subject_CONFIRMU2F": "FIDO registration key for device \"{deviceName}\"",
        "Subject_NOT_FOUND": "Email subject not found",
        "Content_CONFIRMU2F": "Last step to register this FIDO Key \"{deviceName}\" into you Lynvictus account \"{login}\".",
        "Subject_URL_TO_FILESET": "A new datastore \"{fName}\" is available for you.",
        "Content_URL_TO_FILESET": "Someone used your url to send you somes datas. The datastore is \"{fName}\".",
        "Subject_FILESET_DELETED": "The datastore \"{fName}\" has been deleted",
        "Content_FILESET_DELETED": "The datastore \"{fName}\" has been deleted by \"{ownerName}\".",
        "Subject_ACCOUNTUNLOCKED": "Your account is now available",
        "Subject_MYNOTIF": "notification from {ownerName}",
        "Content_MYNOTIF": "A new notification from {ownerName} for you",
        "Content_NOT_FOUND": "Strange error, forget it !",
        "Direct access": "Direct Access",
        "Some modifications": "Some modifications in \"{fName}\"",
        "Subject_WELCOME": "Welcome in {name}",
        "Content_WELCOME_1": "Welcome on board !",
        "Content_WELCOME_2": "Your account \"{name}\" is ready  !",
        "Content_ENCRYPTED": "Some encrypted information for you",
        "Content_FILESET_DIRADD": "{ownerName} as create the directory \"{path}\" in \"{fName}\"",
        "Content_FILESET_FILEADD": "{ownerName} as added files \"{fileNames}\" in \"{fName}/{path}\"",
        "Content_FILESET_FILEREMOVE": "{ownerName} as deleted \"{path}\" in \"{fName}\"",
        "Subject_NEWSHARE": "A new datastore \"{fName}\" is available",
        "Content_NEWSHARE": "{ownerName} has shared the datastore \"{fName}\" with you.",
        "Subject_ADDGUEST": "Confidential informations for you ({fName})",
        "Content_ADDGUEST_1": "\"{ownerName}\" shares informations with you. The file sharing name is \"{fName}\".",
        "Content_ADDGUEST_2": "To accept the share, just sign in here",
        "Subject_ADDREGISTEREDGUEST": "\"{ownerName}\" propose you the visibility of the datastore \"{fName}\"",
        "Content_ADDREGISTEREDGUEST": "\"{ownerName}\" propose you to ask for access to the datastore \"{fName}\".",
        "Subject_TESTEMAIL": "Email test",
        "Content_TESTEMAIL_1": "This is an email test for you.",
        "Content_TESTEMAIL_2": "You have nothing to do",
        "Subject_DROPFROMSHARE": "Your access to the datastore \"{fName}\" is revoked",
        "Content_DROPFROMSHARE": "Your acces to the datastore \"{fName}\" has been revoked by \"{ownerName}\".",
        "Subject_WANTED": "\"{ownerName}\" want an access to \"{fName}\"",
        "Content_WANTED": "\"{ownerName}\" ask for an access to the datastore \"{fName}\".",
        "Subject_ACCEPTWANTED": "Acces to datastore \"{fName}\" accepted by \"{ownerName}\"",
        "Content_ACCEPTWANTED": "\"{ownerName}\" has accepted your request and give you access to the datastore \"{fName}\".",
        "Subject_CANCELWANTED": "Acces to datastore \"{fName}\" refused by \"{ownerName}\"",
        "Content_CANCELWANTED": "\"{ownerName}\" has canceled your ask for access to the datastore \"{fName}\".",
        "Subject_ACCOUNTCREATED": "Your account \"{login}\" is created",
        "Content_ACCOUNTCREATED": "Your account \"{login}\" is created. Welcome !",
        "Subject_ACCOUNTLOCKED": "Your account \"{login}\" is locked",
        "Content_ACCOUNTLOCKED": "Your account \"{login}\" has been locked. A security issue ?",
        "Content_ACCOUNTUNLOCKED": "Your account \"{login}\" has been unlocked. Nice to see you again",
        "Subject_REINITPASS": "Password reinitialisation for \"{name}\"",
        "Content_REINITPASS": "Your password and your cryptographic keys are dropped. please click on the following link to set new ones",
        "Subject_EMAILDELETED": "The email \"{email}\" is erased from your account",
        "Content_EMAILDELETED": "Your email is not longer valid for your account \"{name}\".",
        "Subject_CONFIRMEMAIL": "Your email \"{email}\" is nearly created",
        "Content_CONFIRMEMAIL_1": "Last step to add this email to your account \"{login}\".",
        "Content_CONFIRMEMAIL_2": "Please confirm your email by clicking on the following link: ",
        "Subject_CONFIRMEDEMAIL": "Your email \"{email}\" is confirmed",
        "Content_CONFIRMEDEMAIL": "Your email \"{email}\" is ready for your account \"{login}\".",
        "Subject_SIGNUP": "Your account \"{login}\" is nearly created",
        "Content_SIGNUP_1": "Last step to create your account.",
        "Content_SIGNUP_2": "Please confirm your email with the following link: ",
        "Subject_NEW_ACCOUNT_PASSWORD": "Your account is ready",
        "Content_NEW_ACCOUNT_PASSWORD": "A new account has been created for you, the temporary password is the following \"{password}\". ",
        "Subject_DROPGUESTFROMSHARE": "Cancelled confidential informations for you ({fName})",
        "Content_DROPGUESTFROMSHARE": "\"{ownerName}\" finaly refused the proposal of access to the vault \"{fName}\"",
        "Subject_NEWURL": "A link to send information",
        "Content_NEWURL": "\"{ownerName}\" has invited you to upload files, follow the link below to do it",
        "Subject_NEWCHAT": "A new encrypted message from \"{ownerName}\"",
        "Content_NEWCHAT": "\"{ownerName}\" has sent you a message, please follow the link to see it.",
    }
};

export const sms = {
    "root": {
        "Verification code": "Here is your validation code : \"{otp}\"",
        "A code has been sent to your phone": "A code has been sent to your phone",
    }
};