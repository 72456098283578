import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles, Typography } from '@material-ui/core';
import { translate } from '../../../../../SharedLibs/Langs';
import Fade from '@material-ui/core/Fade';


const styles = (/* theme */) => ({
  load: {
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '16%',
    width: 100,
    height: 140,
  },

  gear: {
    position: "absolute",
    // zIndex: -10,
    width: 40,
    height: 40,
    animation: 'spin 5s infinite'
  },

  two: {
    left: 40,
    width: 80,
    height: 80,
    animation: 'spin-reverse 5s infinite'
  },

  three: {
    top: 45,
    left: -10,
    width: 60,
    height: 60
  },

  loadMessage: {
    position: 'absolute',
    bottom: '0px',
    whiteSpace: 'nowrap',
  },

  '@keyframes spin': {
    '50%': {
      transform: "rotate(360deg)"
    }
  },

  '@keyframes spin-reverse': {
    '50%': {
      transform: "rotate(-360deg)"
    }
  },

  lilCircle: {
    position: "absolute",
    borderRadius: "50%",
    boxShadow: "inset 0 0 10px 2px gray, 0 0 50px white",
    width: 100,
    height: 100,
    opacity: ".65"
  },

  blurCircle: {
    position: "absolute",
    top: -19,
    left: -19
  },

  expand: {
    width: "82%",
    height: 3,
    position: "absolute",
  },
  progressbar: {
    width: "82%",
    height: 5,
    background: "#fff"
  }

});

class Loading extends Component {
  constructor(props) {
    super(props);
  }

  renderGears() {
    return (
      <React.Fragment>
        <div className={this.props.classes.gear}>
          <svg id="blue" viewBox="0 0 100 100" fill={this.props.color} >
            <path d="M97.6,55.7V44.3l-13.6-2.9c-0.8-3.3-2.1-6.4-3.9-9.3l7.6-11.7l-8-8L67.9,20c-2.9-1.7-6-3.1-9.3-3.9L55.7,2.4H44.3l-2.9,13.6      c-3.3,0.8-6.4,2.1-9.3,3.9l-11.7-7.6l-8,8L20,32.1c-1.7,2.9-3.1,6-3.9,9.3L2.4,44.3v11.4l13.6,2.9c0.8,3.3,2.1,6.4,3.9,9.3      l-7.6,11.7l8,8L32.1,80c2.9,1.7,6,3.1,9.3,3.9l2.9,13.6h11.4l2.9-13.6c3.3-0.8,6.4-2.1,9.3-3.9l11.7,7.6l8-8L80,67.9      c1.7-2.9,3.1-6,3.9-9.3L97.6,55.7z M50,65.6c-8.7,0-15.6-7-15.6-15.6s7-15.6,15.6-15.6s15.6,7,15.6,15.6S58.7,65.6,50,65.6z"></path>
          </svg>
        </div>
        <div className={`${this.props.classes.gear} ${this.props.classes.two}`}>
          <svg id="pink" viewBox="0 0 100 100" fill={this.props.color}>
            <path d="M97.6,55.7V44.3l-13.6-2.9c-0.8-3.3-2.1-6.4-3.9-9.3l7.6-11.7l-8-8L67.9,20c-2.9-1.7-6-3.1-9.3-3.9L55.7,2.4H44.3l-2.9,13.6      c-3.3,0.8-6.4,2.1-9.3,3.9l-11.7-7.6l-8,8L20,32.1c-1.7,2.9-3.1,6-3.9,9.3L2.4,44.3v11.4l13.6,2.9c0.8,3.3,2.1,6.4,3.9,9.3      l-7.6,11.7l8,8L32.1,80c2.9,1.7,6,3.1,9.3,3.9l2.9,13.6h11.4l2.9-13.6c3.3-0.8,6.4-2.1,9.3-3.9l11.7,7.6l8-8L80,67.9      c1.7-2.9,3.1-6,3.9-9.3L97.6,55.7z M50,65.6c-8.7,0-15.6-7-15.6-15.6s7-15.6,15.6-15.6s15.6,7,15.6,15.6S58.7,65.6,50,65.6z"></path>
          </svg>
        </div>
          <div className={`${this.props.classes.gear} ${this.props.classes.three}`}>
            <svg id="yellow" viewBox="0 0 100 100" fill={this.props.color}>
              <path d="M97.6,55.7V44.3l-13.6-2.9c-0.8-3.3-2.1-6.4-3.9-9.3l7.6-11.7l-8-8L67.9,20c-2.9-1.7-6-3.1-9.3-3.9L55.7,2.4H44.3l-2.9,13.6      c-3.3,0.8-6.4,2.1-9.3,3.9l-11.7-7.6l-8,8L20,32.1c-1.7,2.9-3.1,6-3.9,9.3L2.4,44.3v11.4l13.6,2.9c0.8,3.3,2.1,6.4,3.9,9.3      l-7.6,11.7l8,8L32.1,80c2.9,1.7,6,3.1,9.3,3.9l2.9,13.6h11.4l2.9-13.6c3.3-0.8,6.4-2.1,9.3-3.9l11.7,7.6l8-8L80,67.9      c1.7-2.9,3.1-6,3.9-9.3L97.6,55.7z M50,65.6c-8.7,0-15.6-7-15.6-15.6s7-15.6,15.6-15.6s15.6,7,15.6,15.6S58.7,65.6,50,65.6z"></path>
            </svg>
          </div>
      </React.Fragment>
    )
  }

  render() {

    let message = this.props.message ? this.props.message : translate('Loading');
    let content = this.renderGears();

    if (this.props.error) console.error(this.props.error);

    if (this.props.animation) {
      switch (this.props.animation) {
        case "download":
          content = "download_animation";
          break;
        default:
          break;
      }
    }

    return (
      <React.Fragment>
        {this.props.withProgress &&
          <div className={this.props.classes.progressbar}>
            <span
              className={this.props.classes.expand}
              style={{
                "MozAnimation": `fullexpand ${this.props.withProgress}ms ease-out`,
                "WebkitAnimation": `fullexpand ${this.props.withProgress}ms ease-out`,
                background: this.props.color,
                boxShadow: `0px 0px 10px 1px ${this.props.color}`,
              }}
            >
            </span>
          </div>
        }
        <Fade in={true}>
          <div id="loading" data-testid={'loading'} className={this.props.classes.load} color={this.props.color}>
            {content}
            <Typography variant="subtitle2"
              noWrap={true}
              align="center"
              gutterBottom
              className={this.props.classes.loadMessage}
              style={{ 'color': this.props.color }}
            >
              {message}
            </Typography>
          </div>
        </Fade>
      </React.Fragment>
    )
  }
}

Loading.propTypes = {
  'message': PropTypes.string,
  'animation': PropTypes.string,
  'classes': PropTypes.object,
  'color': PropTypes.string,
  'error': PropTypes.object,
  'withProgress': PropTypes.bool,
};

export default withStyles(styles)(Loading)