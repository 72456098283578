import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { MyComponent } from '../../MyComponent.jsx';
import { translate } from '../../../../../SharedLibs/Langs.js';
import { accountStore } from '../../../stores/AccountStore';


const styles = {
	errorTitle : {
		fontSize : 35,
		fontWeight: "bold"
	}
}

class ErrorDisplay extends MyComponent {

	constructor ( props ) {
		super ( props );

		this.setMessage = this.setMessage.bind(this);
		this.closeMessage = this.closeMessage.bind(this);

		this.state = {
			"open" : false,
			"err" : undefined,
		};
	}

	closeMessage() {
		this.setState({ "err" : undefined, 'open' : false });
	}

	/**
	* Set the way to contact me
	*/
	componentDidMount() {
		this.props.global.registerFunction('error', this.setMessage)
	}

	/**
	* Unregister the setMessage function
	*/
	componentWillUnmount() {
		this.props.global.registerFunction('error', undefined )
	}

	setProgressActionsAsError() {

	}

	/**
	* Add a message to display
	*
	*/
	setMessage( err ) {
		this.setProgressActionsAsError();

		if (err.statusCode == 401 ) {
			accountStore.localLogout();
		}
		this.setState({ "err" : err, 'open' : true });
	}

	getMessage( err) {
		if ( err.error ) return translate(err.error, err.props);
		return translate(err.message, err.props);
	}

	render() {
		let message = undefined;
		const err = this.props.error ? this.props.error : this.state.err;
		const open = this.props.error ? true : this.state.open;
		if ( err ) message = this.getMessage(err);

		if (err) console.error(err);
		return (
			<Dialog
				id="lynError"
				open={ open }
				onClose={this.closeMessage}
				TransitionComponent={Slide}
			>

				<DialogTitle>
					<Typography className={this.props.classes.errorTitle}>{translate("Error")}</Typography>
				</DialogTitle>
				<DialogContent>
					<Typography id="errorMessage" >{message}</Typography>
				</DialogContent>
				<DialogActions>
					<Button
						id="errorSubmit"
						//key="submit"
						color="secondary"
						variant="contained"

						onClick={this.closeMessage}
					>
					{"Ok"}
					</Button>
				</DialogActions>
				</Dialog>
			);
		}
	}


	ErrorDisplay.propTypes = {
		'global': PropTypes.object,
		'classes': PropTypes.object,
		'err': PropTypes.object,
	};

	export default withStyles(styles)(ErrorDisplay)