/* eslint-disable no-unused-vars */

import React from 'react';
import {MyComponent} from './MyComponent.jsx';
//import Loadable from 'react-loadable';
import PropTypes from 'prop-types';


import {withRouter} from 'react-router';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';

import {initLanguage, setLocalLang, translate} from '../../../SharedLibs/Langs.js';
import {hexToHSL, objectGet} from '../../../SharedLibs/Tools.js';
import { ROOTDIR_ID, DEFAULT_THEME } from '../../../SharedLibs/Constants.js';
//import { defaultConfig } from "../../../server/src/stores/Libs/Config.js";
import ErrorDisplay from './M1/Lib/ErrorDisplay.jsx';
import SnackDisplay from './M1/Lib/SnackDisplay.jsx';
import ConfirmDisplay from './M1/Lib/ConfirmDisplay.jsx';
import InfoDisplay from './M1/Lib/InfoDisplay.jsx';
import AskDisplay from './M1/Lib/AskDisplay.jsx';

import {configStore} from '../stores/ConfigStore';
import {accountStore} from '../stores/AccountStore';
import {friendsStore} from '../stores/FriendsStore';

import Paper from '@material-ui/core/Paper';
import {SvgIcon, Typography, withStyles} from '@material-ui/core';
import {IconManager} from './M1/ReactToolBox/IconManager.jsx';
import {manager} from '@lybero/lybcrypt';

import Loadable from 'react-loadable';
import Loading from './M1/Lib/Loading.jsx';
import {setDebug} from '../../../SharedLibs/DebugObj';

const Console = Loadable({
    loader: () => import('./M1/Lib/Console.jsx'),
    loading(props) {
        return (<Loading message="Loading Console"  {...props} />);
    }
});

let Clipboard = require('clipboard');

const specificStyles = () => ({
    img64: {
        width: '64px',
        height: '64px',
        '& span': {
            '& svg': {
                width: '64px',
                height: '64px',
            }
        },
    },
    '@global': {
        body: {
            margin: 0,
            paddingRight: 0,
            display: 'flex',
            height: '100%',
            width: '100%',
            backgroundImage: "url('/wallpaper')",
            backgroundSize: 'auto',
            backgroundRepeat: 'repeat',
        }
    }
});

export const GLOBALPROPS = PropTypes.shape({
    "urlParams": PropTypes.object,
    "logged": PropTypes.bool,
    "lastPlace": PropTypes.string,
    "linkTo": PropTypes.func,
    "logout": PropTypes.func,
    "changePageTitle": PropTypes.func,
    "setUpwardButton": PropTypes.func,
    "registerFunction": PropTypes.func,
    "callFunction": PropTypes.func
});

class GlobalApp extends MyComponent {

    constructor(props) {
        super(props);

        // --- Set the debug names--
        this.deb.setNames('globalapp');

        this.onChange = this.onChange.bind(this);
        this.linkTo = this.linkTo.bind(this);
        this.setFileManager = this.setFileManager.bind(this);
        this.getQuery = this.getQuery.bind(this);
        this.initialisation = this.initialisation.bind(this);
        this.logout = this.logout.bind(this);
        this.getTheme = this.getTheme.bind(this);
        this.registerFunction = this.registerFunction.bind(this);
        this.callFunction = this.callFunction.bind(this);
        this.clientConfigLoaded = this.clientConfigLoaded.bind(this);
        this.getRegisteredFunctions = this.getRegisteredFunctions.bind(this);

        new Clipboard('.copyButton');
        this.urlParams = {};

        this.state = {
            configLoaded: false,
            configLoadError: false,
            logged: false,
            dark: false,
            lastPlace: undefined,
            console: false,
            tempTheme: undefined,
            debug: false,
        };

        this.state.defaultTheme = {
            primary: {
                main: '#1b75bc'//blue
            },
            secondary: {
                main: '#f50057'//pink
            },
            others: {}
        };

        this.functionsRegistered = {}

        //this.state.muiTheme = this.getTheme(this.state.defaultTheme);

    }


    /*darkOrLight(isDark) {
        if (isDark) return this.setState({ "dark": true });
        return this.setState({ "dark": false });
    }*/

    enlightColor(hslColor, amount) {
        let lightColor = {...hslColor};
        let l = lightColor.l;
        if ((l + amount) > 1) {
            lightColor.l = 1
        } else {
            lightColor.l = l + amount
        }
        return lightColor
    }

    darkenColor(hslColor, amount) {
        let darkColor = {...hslColor};
        let l = darkColor.l;
        if ((l - amount) < 0) {
            darkColor.l = 0
        } else {
            darkColor.l = l - amount
        }
        return darkColor
    }

    getInThemeOrDefault(theme, path) {
        let valInTheme = objectGet(theme, path);
        if (valInTheme) return valInTheme;
        return objectGet(DEFAULT_THEME, path)
    }

    getTheme(theme) {
        if (this.urlParams['theme']) {
            this.log(`actual temp theme : `, {themeName: this.urlParams['theme'], themeContent: theme})
        }

        let fontsMainColor = this.getInThemeOrDefault(theme, "fonts.mainColor");
        let iconsRadiuses = this.getInThemeOrDefault(theme, "radiuses.icons");

        //let primaryColorConvertedToHSL = hexToHSL(theme.primary.main);
        let mainTextHSL = hexToHSL(fontsMainColor);

        let hoverMainText = mainTextHSL.l > 0.8 ? this.darkenColor(mainTextHSL, 0.1) : this.enlightColor(mainTextHSL, 0.4);
        let activeMainText = mainTextHSL.l > 0.8 ? this.darkenColor(mainTextHSL, 0.2) : this.enlightColor(mainTextHSL, 0.4);

        let thatTheme = {
            palette: {
                type: this.state.dark ? 'dark' : 'light',
                primary: this.getInThemeOrDefault(theme, "primary"),
                secondary: this.getInThemeOrDefault(theme, "secondary"),
                error: this.getInThemeOrDefault(theme, "error"),
                selectedTabs: this.getInThemeOrDefault(theme, "others.selectedTabs"),
                subHeader: this.getInThemeOrDefault(theme, "others.subHeader"),
                subHeaderText: this.getInThemeOrDefault(theme, "others.subHeaderText"),
                selectedItems: this.getInThemeOrDefault(theme, "others.selectedItems"),
                menuIcons: this.getInThemeOrDefault(theme, "others.menuIcons"),
               /* config: {
                    light: 'hsl(' + primaryColorConvertedToHSL.h + ',' + primaryColorConvertedToHSL.s + '%,85%)',
                    dark: 'hsl(' + primaryColorConvertedToHSL.h + ',' + primaryColorConvertedToHSL.s + '%,50%)'
                },*/
                background: this.getInThemeOrDefault(theme, "background"),
                fonts: {
                    main: fontsMainColor
                },
                dialogHeader: this.getInThemeOrDefault(theme, "others.dialogHeader"),
                dialogHeaderContrastText: this.getInThemeOrDefault(theme, "others.dialogHeaderText"),
                speedDial: {
                    background: this.getInThemeOrDefault(theme, "speedDial.speedDialBackground"),
                    backgroundHover: this.getInThemeOrDefault(theme, "speedDial.speedDialBackgroundHover"),
                    actionBackground: this.getInThemeOrDefault(theme, "speedDial.speedDialActionBackground"),
                    icons: this.getInThemeOrDefault(theme, "speedDial.speedDialIcons"),
                    mainIcon: this.getInThemeOrDefault(theme, "speedDial.speedDialMainIcon"),
                },
                tips:{
                    background: this.getInThemeOrDefault(theme, "others.tipsBackground"),
                    text: this.getInThemeOrDefault(theme, "others.tipsText"),
                }


            },
            shape: {
                borderRadius: this.getInThemeOrDefault(theme, "radiuses.buttons")
            },
            iconRadius: iconsRadiuses,
            typography: {
                useNextVariants: true,
                fontFamily: this.getInThemeOrDefault(theme, "fonts.customFont")
            },
            overrides: {
                MuiTableCell: {
                    body: {
                        color: `${fontsMainColor} !important`
                    },
                    head: {
                        color: fontsMainColor
                    }
                },
                MuiTableSortLabel: {
                    root: {
                        '&:hover': {
                            color: `hsl(${hoverMainText.h}, ${hoverMainText.s * 100}%, ${hoverMainText.l * 100}%)`
                        }
                    },
                    active: {
                        color: `hsl(${activeMainText.h}, ${activeMainText.s * 100}%, ${activeMainText.l * 100}%)`
                    }
                },
                MuiTypography: {
                    body2: {
                        color: fontsMainColor
                    },
                    caption: {
                        color: fontsMainColor

                    }
                },
                MuiButton: {
                    root: {
                        color: fontsMainColor
                    }
                },

                MuiDialogTitle: { // Name of the component ⚛️ / style sheet
                    root: { // Name of the rule
                        backgroundColor: this.getInThemeOrDefault(theme, "others.dialogHeader"), // Some CSS
                        padding: "24px 24px 10px",
                        marginBottom: "10px",
                        '& h6': {
                            color: this.getInThemeOrDefault(theme, "others.dialogHeaderText")
                        }
                    }
                },
                MuiFab: {
                    root: {
                        borderRadius: iconsRadiuses
                    },
                    primary: {
                        backgroundColor: this.getInThemeOrDefault(theme, "primary.main"),
                        color: this.getInThemeOrDefault(theme, "primary.contrastText"),
                        '&:hover': {
                            backgroundColor: this.getInThemeOrDefault(theme, "primary.dark")
                        }
                    },
                    label: {
                        padding: '5px 5px 5px 5px'
                    }
                },
                MuiAvatar: {
                    root: {
                        borderRadius: iconsRadiuses
                    }
                },
                MuiTab: {
                    root: {
                        lineHeight: 'none'
                    }
                }
            }
        };

        return createMuiTheme(thatTheme);
    }

    setFileManager(func) {
        this.fileAddFunc = func;
        this.setState({fileAddFunc: func});
    }

    registerFunction(name, func) {
        this.log(`registerFunction`, {name, func});
        this.functionsRegistered[name] = func;

    }

    callFunction(name, ...args) {
        if (!this.functionsRegistered[name]) {
            this.log(`callFunction without function defined`, {name, args});
            return undefined
        }
        return this.functionsRegistered[name](...args);
    }

    removeFunction(name) {
        if (this.functionsRegistered[name]) {
            delete this.functionsRegistered[name]
        }
    }

    getRegisteredFunctions() {
        return this.functionsRegistered
    }


    async componentDidMount() {

        // --- Set getPublickKeyStores early due to the ask of quorum keyStore at logon
        manager.setDebug(false);
        manager.setLogFunc(() => {
        });
        manager.setGetPublicKeyStores(friendsStore.getPublicKeyStores);
        manager.setSaveKeyStoreFunc(accountStore.saveKeyStore);

        accountStore.addChangeListener(this.onChange);
        configStore.addChangeListener(this.clientConfigLoaded);

        /*
        faire fonction loadConfig
        -> get clientConfig
            + saloperies du theme
            appel en fin de comp did mount + changeListener
            finir par le setState
            afficher les params du onChange
         */

        if (this.urlParams["debug"]) {
            setDebug(this.urlParams["debug"]);
        }

        this.initialisation();
        let r = await configStore.getClientConfig()
            .catch((e) => {
                console.error("error while download config", e);
                this.setState({'configLoadError': true})
            });
        if (r === false) {
            return false;
        }

        // --- Check for already logged ( the page was reloaded ) ---
        await accountStore.checkForAlreadyLogged()
            .catch((e) => {
                return this.clientConfigLoaded();
            });

        return this.clientConfigLoaded();
    }

    componentDidCatch(error, info) {
        console.error('got an error from component', error);
        console.error('got an error from component errorInfo', info);
        if (this.functionsRegistered['error']) {
            return this.callFunction('error', error)
        }
        return this.setState({'error': error})
    }


    componentWillUnmount() {
        manager.setDebug(false);
        manager.setGetPublicKeyStores(undefined);
        manager.setSaveKeyStoreFunc(undefined);
        accountStore.removeChangeListener(this.onChange);

    }

    getLangFromEnv() {
        let userLang = undefined;
        if (navigator) userLang = this.urlParams.lang || navigator.language || navigator.userLanguage;
        setLocalLang(userLang);
    }

    initialisation() {
        initLanguage();
        this.getLangFromEnv();
    }


    onChange() {
        this.setTitle();
        return this.setState({"logged": accountStore.logged});
    }

    /**
     * The configuration is set in its store.
     */
    clientConfigLoaded() {
        let theme;
        let tempTheme = this.urlParams['theme'];
        if (tempTheme) {
            theme = configStore.getTheme(tempTheme)
        } else {
            theme = configStore.getTheme(configStore.defaultTheme);
            if (theme) {
                if (!theme.primary || !theme.secondary) theme = undefined; // theme can't be loaded, too old
            }
        }

        this.setTitle();
        if (!theme) return this.setState({'configLoaded': true});
        return this.setState({'configLoaded': true, defaultTheme: theme});
    }


    changePageTitle(t) {
        if (typeof window === 'undefined') return false;
        if (!window.parent) return false;
        if (!window.parent.document) return false;
        window.parent.document.title = t;
        return true;
    }

    setTitle() {
        let title = configStore.appName;
        if (accountStore.logged) title += "-" + accountStore.loginName;
        this.changePageTitle(title);
    }

    setLastPlaceNotChatNotif(url) {
        let regexpNotif = new RegExp('/lynvictus/notifications');
        let regexpTchat = new RegExp('/lynvictus/thread');

        if (!url.match(regexpTchat)) {
            if (!url.match(regexpNotif)) {
                // eslint-disable-next-line react/no-direct-mutation-state
                this.state.lastPlace = url
            }
        }
    }

    linkTo(href) {
        if (href === "?") return this.props.history;
        if (href === "back") return this.props.history.goBack();
        return this.props.history.push(href);
    }

    logout(e) {
        if (e) e.preventDefault();
        accountStore.logout()
            .then(() => {
                return this.setState({"logged": false},
                    this.linkTo("/lynvictus/folder/" + ROOTDIR_ID));
            })
            .catch((err) => {
                return this.callFunction('error', err);
            });
    }

    /**
     * return the parameters from the location,
     * before and after the #
     */
    getQuery(props) {
        if (!props.location) return {};
        // ----- this url already treated.
        if ((this.state)
            && (this.urlParams)
            && (this.urlParams._key)
            && (props.location.key === this.urlParams._key)) return {};


        let q = props.location.query ? Object.assign({}, props.location.query) : {};
        if (props.location.search) {
            let params = new URLSearchParams(props.location.search);
            Object.assign(q, Object.fromEntries(params));
        }

        //-------- adding the pathName in the query
        q._pathname = props.location.pathname;
        // ------- Save the key to not do the query two times (action push and pop)
        if (props.location.key) q._key = props.location.key;

        return q;
    }

    renderMessage(m) {
        let message = objectGet(this.urlParams, "message");


        if (!message) message = m;

        let title = "";
        let content = "";
        let end = "";
        let id = "";
        let icon = "alert";
        let avatar = false;
        let avatarAlt = "";
        switch (message) {
            case "emailOk":
                id = "emailOk";
                title = translate("Congratulation");
                content = translate("Your email is validated");
                end = translate("You can close this tab");
                icon = "verified";
                break;
            case "emailError":
                id = "emailError";
                title = translate("Error");
                content = translate("Your email cannot be validate !");
                end = translate("You can close this tab");
                break;
            case "u2fOk":
                id = "u2fOk";
                title = translate("Congratulation");
                content = translate("Your key is registered");
                end = translate("You can close this tab");
                icon = "verified";
                break;
            case "u2fError":
                id = "u2fError";
                title = translate("Error");
                content = translate("The key cannot be registered !");
                end = translate("You can close this tab");
                break;
            case "browser":
                id = "edgeError";
                title = translate("Error");
                content = translate("browser error");
                end = translate("Please use another browser");
                break;
            case "maintenance":
                id = "maintenance";
                title = translate("Maintenance");
                content = configStore.maintenance.maintenanceMessage;
                icon = configStore.maintenance.maintenanceAvatar;
                avatar = true;
                avatarAlt = "Maintenance Logo";
                end = translate("Maintenance end : ", {elName: new Date(parseInt(configStore.maintenance.maintenanceEnd) + 7200000).toString()});
                break;
            case "help":
                id = "help";
                title = translate("Help");
                content = this.renderHelp();
                icon = "info";
                end = translate("You can close this tab");
                break;
            default:
        }

        return (

            <main className={this.props.classes.layout}>
                <Paper id={id} className={this.props.classes.paper}>
                    <Typography variant="h6" gutterBottom>{title}</Typography>
                    {avatar ?
                        <img height={64} width={64} src={icon} alt={avatarAlt}/>
                        :
                        <SvgIcon
                            color="primary"
                            fontSize='large'
                            className={this.props.classes.img64}
                        >
                            {IconManager.getIconByName(icon)}
                        </SvgIcon>
                    }

                    <Typography variant="body1" gutterBottom>{content}</Typography>
                    <Typography variant="body1" gutterBottom>{end}</Typography>
                </Paper>
            </main>
        );
    }

    renderHelp() {
        return (
            <React.Fragment>
                <Typography>You can add some URL params such as :</Typography>
                <Typography>maintainer=true to bypass maintenance mode</Typography>
                <Typography>theme=[theme name] to preview theme without changing config</Typography>
            </React.Fragment>
        )

    }


    isInMaintenanceMode(maintainer) {
        if (maintainer) return false;
        let maintenance = configStore.maintenance;
        if (!maintenance) return false;

        let modeEnabled = maintenance.maintenanceMode;
        if (!modeEnabled) return false;

        let startTime = maintenance.maintenanceStart;
        let endTime = maintenance.maintenanceEnd;
        let now = Date.now();

        if (startTime > now) return false;
        if (now > endTime) return false;

        return true;

    }

    compatBrowser(userAgent) {
        if (userAgent.match("edge")) return false
        if (userAgent.match("Trident")) return false
        return true
    }

    render() {
        this.urlParams = this.getQuery(this.props);
        this.log(`must render in debug`);
        this.log('render');

        let globalProps = {
            "changePageTitle": this.changePageTitle,
            "linkTo": this.linkTo,
            "logout": this.logout,
            "urlParams": this.urlParams,
            "logged": this.state.logged,
            "lastPlace": this.state.lastPlace,
            "registerFunction": this.registerFunction,
            "callFunction": this.callFunction,
            "registeredFunctions": this.functionsRegistered
        };

        if (this.state.error) {
            return (
                <ErrorDisplay
                    global={globalProps}
                    error={this.state.error}
                />
            )
        }


        // --- Add globalprops to childrens -------------------------------
        const childrenWithProps = React.Children.map(this.props.children, (child) => {
            return React.cloneElement(child, {"global": globalProps});
        });
        let muiTheme = this.getTheme(this.state.defaultTheme);
        let compatBrowser = this.compatBrowser(window.navigator.userAgent);
        return (
            <MuiThemeProvider theme={muiTheme}>
                {this.urlParams.message ?
                    this.renderMessage()
                    : !compatBrowser ?
                        this.renderMessage('browser')
                        :
                        this.isInMaintenanceMode(this.urlParams['maintainer']) ?
                            this.renderMessage('maintenance')
                            :
                            this.urlParams['help'] ?
                                this.renderMessage('help')
                                :
                                childrenWithProps
                }
                {
                    this.urlParams['console'] ?
                        <Console
                            key="console"
                            params={this.state.console}
                        />
                        :
                        ""
                }
                <ErrorDisplay
                    global={globalProps}
                />
                <SnackDisplay
                    global={globalProps}
                />
                <ConfirmDisplay
                    global={globalProps}
                />
                <InfoDisplay
                    global={globalProps}
                />
                <AskDisplay
                    global={globalProps}
                />
            </MuiThemeProvider>
        );
    }
}

GlobalApp.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.node
    ]),
    classes: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
};


export default withRouter(withStyles(specificStyles)(GlobalApp));
