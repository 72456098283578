import {
    LynError
} from '../../../SharedLibs/LynError.js';

const schmlux = require('../schmlux/index.js');
const ClientStore = schmlux.ClientStore;
export const WITHOUT_EMITCHANGE = schmlux.WITHOUT_EMITCHANGE;
export const WITH_EMITCHANGE = schmlux.WITH_EMITCHANGE;

// ---- For debugging ------
import Debug from '../../../SharedLibs/DebugObj';


export default class SchmluxStore extends ClientStore {

    constructor() {
        super();
        this.singleton = undefined;

        // --- Set the debug --
        this.deb = new Debug( 'Store' );
        this.l = this.deb.l;
        this.log = this.deb.log;
        this._false = this.deb._false;
        this._true = this.deb._true;
        // --- Set the debug --

        this.setDebugFunc( (type, message, data) => {
            return this.log( `${type}/${message}`, data )
        })

        this.WITH_EMITCHANGE = WITH_EMITCHANGE;
        this.WITHOUT_EMITCHANGE = WITHOUT_EMITCHANGE;

        this.workflowsDescriptions = {};

    }

    async getByKey(key, value, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        this.log(`getByKey ${key}=${value}`);
        const res = await this.select({
            [key]: value,
            [`${key}_op`] : 'eq'
        }, undefined, undefined, givenVueLevel, emitChange);
        // this.log(`getByKey ${key}=${value}`, res);
        if (!res) return undefined;
        if (typeof res !== 'object') {
            this.log(`getByKey ${key}==${value} doesnt return an object`);
        }
        const els = res.results;
        if (Array.isArray(els)) return els[0];
        return els;
    }

    getByName(name, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        return this.getByKey('name', name, givenVueLevel, emitChange);
    }

    updateObject(obj, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        if (!obj) return false;
        return this.update(obj._id, obj.getContentForServer(), givenVueLevel, emitChange);
    }

    patchObjects(objs, fields, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        if (!objs) return false;
        let _ids = [];
        for (let obj of objs) _ids.push(obj._id);
        return this.update(_ids, fields, givenVueLevel, emitChange);
    }

    /*
     *
     * ██╗  ██╗██╗███████╗██████╗  █████╗ ██████╗  ██████╗██╗  ██╗██╗   ██╗
     * ██║  ██║██║██╔════╝██╔══██╗██╔══██╗██╔══██╗██╔════╝██║  ██║╚██╗ ██╔╝
     * ███████║██║█████╗  ██████╔╝███████║██████╔╝██║     ███████║ ╚████╔╝
     * ██╔══██║██║██╔══╝  ██╔══██╗██╔══██║██╔══██╗██║     ██╔══██║  ╚██╔╝
     * ██║  ██║██║███████╗██║  ██║██║  ██║██║  ██║╚██████╗██║  ██║   ██║
     * ╚═╝  ╚═╝╚═╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝   ╚═╝
     *
     */
    getParent( /* obj, vueLevel */ ) {
        return undefined;
    }
    getParentInStore( /* obj, vueLevel */ ) {
        return undefined;
    }

    /**
     * Return an array of childs, or undefined if no sens
     */
    getChildIds( /* obj */ ) {
        return undefined;
    }

    /*
     *
     * ██████╗ ██╗ ██████╗ ██╗  ██╗████████╗███████╗
     * ██╔══██╗██║██╔════╝ ██║  ██║╚══██╔══╝██╔════╝
     * ██████╔╝██║██║  ███╗███████║   ██║   ███████╗
     * ██╔══██╗██║██║   ██║██╔══██║   ██║   ╚════██║
     * ██║  ██║██║╚██████╔╝██║  ██║   ██║   ███████║
     * ╚═╝  ╚═╝╚═╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝
     *
     */
    canCreate() {
        return false;
    }

    canManage( /* _id */ ) {
        return false;
    }

    canDelete(_id) {
        return this.canManage(_id);
    }

    /*
     *
     * ███████╗██████╗ ██████╗  ██████╗ ██████╗ ███████╗
     * ██╔════╝██╔══██╗██╔══██╗██╔═══██╗██╔══██╗██╔════╝
     * █████╗  ██████╔╝██████╔╝██║   ██║██████╔╝███████╗
     * ██╔══╝  ██╔══██╗██╔══██╗██║   ██║██╔══██╗╚════██║
     * ███████╗██║  ██║██║  ██║╚██████╔╝██║  ██║███████║
     * ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝
     *
     */

    error(message, data) {
        throw new LynError(message, data);
    }


}