import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { MyComponent } from '../../MyComponent.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Zoom from '@material-ui/core/Zoom';
import { translate } from '../../../../../SharedLibs/Langs.js';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const specificStyles = (theme) => ({
    close: {
        padding: theme.spacing.unit / 2,
    },
});


class ConfirmDisplay extends MyComponent {

	constructor(props) {
		super(props);

		this.setMessage = this.setMessage.bind(this);
		this.closeMessage = this.closeMessage.bind(this);
		this.confirmOk = this.confirmOk.bind(this);

		this.state = {
			"open": false,
			"message": undefined,
			"title": undefined,
		};
		this.callBackFunc = undefined;
	}

	closeMessage() {
		this.callBackFunc = undefined;
		this.setState({ "title": undefined, "message": undefined, 'open': false });
	}

	/**
	* Set the way to contact me
	*/
	componentDidMount() {
		this.props.global.registerFunction('confirm', this.setMessage)
	}

	/**
	* Unregister the setMessage function
	*/
	componentWillUnmount() {
		this.props.global.registerFunction('confirm', undefined)
	}


	/**
	* Add a message to display
	*
	*/
	setMessage(title, message, callBack) {
		this.callBackFunc = callBack;
		let t = title ? title : translate("Confirmation");
		this.setState({ "title": t, "message": message, 'open': true });
	}

	confirmOk() {
		return this.setState({"title": undefined, "message": undefined, 'open': false });
	}

	componentDidUpdate() {
		if (( this.callBackFunc ) && ( this.state.open === false )) {
			let c = this.callBackFunc;
			this.callBackFunc = undefined;
			return c();
		}
	}

	render() {
		return (
			<Dialog
				key="confirm"
				id="confirm"
				open={ this.state.open }
				TransitionComponent={Zoom}
				onClose={this.closeMessage}
			>
				<DialogTitle>
					{this.state.title}
				</DialogTitle>
				<DialogContent>
					<Typography dangerouslySetInnerHTML={ { "__html" : this.state.message } }></Typography>
				</DialogContent>
				<DialogActions>
					<Button 
							id="confirmCancel"
							color='secondary'
							variant="contained"
							onClick={this.closeMessage}
						>
							{ translate ("No") }
						</Button>
						<Button 
							id="confirmSubmit"
							color="primary"
							variant="contained"
							
							onClick={this.confirmOk}
						>
							{ translate ("Yes") }
						</Button>
				</DialogActions>
		</Dialog>
	);


	}
}

ConfirmDisplay.propTypes = {
	'global': PropTypes.object,
	'classes': PropTypes.object,
};

export default withStyles(specificStyles, { withTheme: true })(ConfirmDisplay)