// ---- Autogenerate by translate.js (BW) ----------

// --------------
// -------------- Part inter
// --------------

export const inter = {
    "fr": {
        "Rename fileset": "Renommer le coffre",
        "Rename file or folder": "Renommer le fichier ou le répertoire",
        "See": "Voir",
        "User not found in database": "Cet utilisateur n'existe pas dans l'application",
        "Crypting and saving fileset": "Chiffrement et sauvegarde du coffre",
        "Chats": "Discussions",
        "App config": "App config",
        "Send messages": "Discussions",
        "Status": "Statut",
        "createUser": "Créer des utilisateurs",
        "createFileset": "Créer des coffres",
        "createQuorumGroup": "Créer des quorums",
        "createGroup": "Créer des groupes",
        "Loading content": "Chargement des contenus",
        "manage": "Changer les permissions existantes",
        "Reply": "Répondre",
        "Home": "Accueil",
        "New repository": "Nouveau coffre",
        "New url": "Nouvelle invitation",
        "URLs": "Invitations",
        "GCU": "General Terms of Usage",
        "Administration": "Administration",
        "Configuration": "Configuration",
        "Quorums": "Quorums",
        "Settings": "Paramètres",
        "Size": "Taille",
        "About": "A propos",
        "Vault": "Coffre",
        "Vaults": "Coffres",
        "Help": "Aide",
        "Guest": "Invité",
        "Enter": "Entrer",
        "Decrypt": "Déchiffrer",
        "Delete": "Supprimer",
        "Logout": "Déconnexion",
        "Later": "Plus tard",
        "Refuse": "Refuser",
        "Close": "Fermer",
        "Accept": "Accepter",
        "Sort by": "Trier par",
        "Sorted by": "trié(s) par",
        "Edit": "Modifier",
        "Send a customized notification email": "Envoyer une notification personalisée par email",
        "Edit parameters": "Modifier les paramètres",
        "Name": "Nom",
        "Expire": "Expire le",
        "Properties": "Propriétés",
        "Change password": "Mots de passe",
        "Identity": "Identité",
        "Last Update": "Dernière modification",
        "Expiration date": "Date d'expiration",
        "Creation date": "Date de création",
        "Last connection": "Dernière connexion",
        "Users": "Utilisateurs",
        "Rights": "Permissions",
        "Rename": "Renommer",
        "Cut": "Couper",
        "Paste": "Coller",
        "Copy": "Copier",
        "Open": "Ouvrir",
        "File ready to be moved": "Fichier prêt à être déplacé",
        "Manage rights": "Gestion des droits de",
        "Yes": "Oui",
        "No": "Non",
        "Cancel": "Abandonner",
        "no informations": "Pas d'informations",
        "Loading": "Chargement",
        "Dataroom crypted": "Coffre chiffré",
        "Owner": "Propriétaire",
        "Details": "Détails",
        "Ok": "Ok",
        "Preferences": "Préférences",
        "Comments": "Commentaires",
        "General": "Général",
        "Date": "Date",
        "Crypted": "Chiffré",
        "Preview": "Aperçu",
        "Use secondary click to use available actions": "Utilisez le clic droit pour obtenir plus d'actions",
        "Logged": "Connecté(e)",
        "Logs": "Logs",
        "Loading and decrypting file": "Chargement et déchiffrement du fichier",
        "Crypted thread": "Conversation chiffrée",
        "Act as": "Agir comme",
        "Go back": "Retour",
        "Next": "Suivant",
        "Share": "Partager",
        "Shares": "Partages",
        "Select people": "Sélectionner les personnes",
        "Add custom email": "Ajouter un email personnalisé",
        "Subject": "Sujet",
        "Copy to me": "Me mettre en copie",
        "No action required": "Aucune action requise",
        "Prev": "Précédent",
        "Message": "Message",
        "Share with people(s)": "Partager avec des personnes",
        "Invite": "Inviter",
        "Theme": "Thème",
        "Themes": "Thèmes",
        "Tchat": "Discussions",
        "There is currently no selected thread": "Aucune discussion sélectionnée",
        "Chose someone in the list below": "Choisissez quelqu'un dans la liste ci-dessous",
        "Select a friend": "Sélectionnez un ami",
        "No member(s)": "La sélection est vide",
        "Select a user": "Sélectionnez un utilisateur",
        "Dashboard": "Dashboard",
        "Delete my account ?": "Supprimer mon compte ?",
        "Delete my account": "Supprimer mon compte",
        "Delete the fileset": "Supprimer le coffre",
        "New password": "Nouveau mot de passe",
        "Change": "Changer",
        "Everybody": "Tout le monde",
        "No deposit limit": "Pas de limite de coffre",
        "No limit": "Pas de limites",
        "Me": "Moi",
        "Deposit remains": "Coffres restants",
        "Drop here your files or press the '+' button to add them": "Glissez des fichiers ci dessous ou appuyez sur le bouton '+' pour ajouter des fichiers",
        "Subject of the deposit": "Sujet du coffre",
        "For": "Pour",
        "Number of deposits": "Nombre de coffres",
        "Send Link to User ?": "Envoyer directement le lien ?",
        "Create": "Créer",
        "Url deleted": "Invitation supprimée",
        "Saved": "Sauvegardé",
        "Downloaded": "Téléchargé",
        "Decyphering ok": "Déchiffrement réussi",
        "Quorum group deleted": "Group à quorum supprimé",
        "Send": "Envoyer",
        "Encrypt and send": "Chiffrer et envoyer",
        "Refuse access": "Refuser l'accès",
        "Accept access": "Donner l'accès",
        "Accept access with quorum": "Accepter l'accès avec le quorum \"{name}\"",
        "Refuse access with quorum": "Refuser l'accès avec le quorum \"{name}\"",
        "Ask for access": "Demander un accès",
        "Cancel request": "Abandonner la demande",
        "Remove": "Supprimer",
        "Fileset deleted": "\"{name}\" supprimé",
        "Are you sure to delete fileset ?": "Etes-vous sûr de vouloir supprimer \"{name}\" ?",
        "Are you sure to delete file ?": "Etes-vous sûr de vouloir supprimer le fichier \"{name}\" ?",
        "Are you sure to delete QuorumGroup ?": "Etes-vous sûr de vouloir supprimer le groupe à quorum \"{name}\" ?",
        "Password": "Mot de passe",
        "Password confirmation": "Confirmation du mot de passe",
        "Password for this key": "Mot de passe pour cette clef",
        "You need to change your password": "Il est temps de changer votre mot de passe",
        "User created": "Utilisateur créé",
        "New user": "Nouvel utilisateur",
        "Username": "Identifiant",
        "No account yet ?": "Pas encore de compte ?",
        "Login": "Se connecter",
        "Login LDAP": "Se connecter  (LDAP)",
        "Welcome to lynvictus": "Bienvenue sur Lynvictus",
        "Please follow this steps for creating an account. It takes only few seconds.": "Veuillez suivre ces quelques étapes pour créer un compte. Cela ne prendra que quelques secondes.",
        "Choose a password": "Choisissez un mot de passe",
        "Email": "Adresse mail",
        "OR": "OU",
        "By": "Par",
        "CREATE AN ACCOUNT": "CREATION D'UN COMPTE",
        "Create an account": "Création d'un compte",
        "Back to login": "Retour au formulaire de connexion",
        "Back to home": "Retour a l'accueil",
        "LOGIN TO YOUR ACCOUNT": "CONNEXION A VOTRE COMPTE",
        "I read and I accept terms and conditions": "J'ai lu et j'accepte les conditions générales d'utilisation",
        "Already have an account ?": "Vous avez déjà un compte ?",
        "Verify your identity by Email": "Vérification de votre identité",
        "Log and work": "Connectez-vous",
        "A email has been sent": "Un mail a été envoyé à ",
        "Please check your email and click on the verification link.": "Surveillez vos courriers et cliquez sur le lien de vérification. Si vous n'avez pas reçu cet email, veuillez dans un premier temps, consulter votre dossier Courrier indésirable.",
        "Congratulation": "Bravo",
        "Your email is validated. Log to start !": "Votre mail a été vérifié. Connectez vous maintenant !",
        "Error": "Erreur",
        "Your email cannot be validate !": "Votre mail ne peut pas être vérifié !",
        "Never": "Jamais",
        "You can paste some text that will be encrypted too": "Vous pouvez coller un texte qui sera également chiffré",
        "Send a confirmation email to me": "M'envoyer un mail de confirmation",
        "To": "Adresse",
        "Anonymous files transfered achieved": "Transfert anonyme vers \"{name}\" terminé",
        "Ready": "Prêt(e)",
        "Sharing": "Partage",
        "Share with": "Partager avec",
        "Secreting": "Secrets",
        "Global information": "Informations générales",
        "Select administrators": "Choix des administrateurs",
        "Quorum": "Quorum",
        "Quorum group created": "Groupe à quorum créé",
        "Group name": "Nom du groupe",
        "User login": "Identifiant de l'utilisateur",
        "User email": "Email de l'utilisateur",
        "User password": "Mot de passe de l'utilisateur",
        "User passwordConfirmation": "Confirmation du mot de passe",
        "Description": "Description",
        "Send files to": "Envoyer à \"{name}\"",
        "Actor": "Acteur",
        "Files": "Fichiers",
        "Object": "Objet",
        "All users": "Tous les utilisateurs",
        "All groups": "Tous les groupes",
        "All quorum groups": "Tous les groupes à quorum",
        "All filesets": "Toutes les coffres",
        "Application": "Application",
        "Generic": "Générique",
        "All": "Tout",
        "Sorry, quorum group not found": "Désolé, groupe à quorum non trouvé",
        "Save": "Enregistrer",
        "Untitle": "Sans titre",
        "Untitled": "Sans titre",
        "User": "Utilisateur",
        "user": "Utilisateur",
        "Group": "Groupe",
        "Quorum group": "Groupe à quorum",
        "Fileset": "Dépôt",
        "Invite guest(s)": "Ajout d'invité(s)",
        "Are you sure to destroy your account ?": "Etes-vous sûr de vouloir détruire votre compte ?",
        "Unknown": "Inconnu",
        "Unknown fileset": "Coffre inconnu",
        "Sorry, unable to display this file": "Désolé, pas de moyens d'afficher ce fichier",
        "Give rights": "Donner les droits",
        "Manage": "Tous",
        "Download": "Télécharger",
        "Add file": "Ajouter ou modifier des fichiers",
        "Add comments": "Ajouter ou modifier des commentaires",
        "Add share": "Partager",
        "Add Guest": "Inviter un utilisateur",
        "Create users": "Créer des utilisateurs",
        "Create filesets": "Créer des coffres",
        "Create groups": "Créer des groupes",
        "Create quorum groups": "Créer des groupes à quorum",
        'Create a new share link': 'Créer un nouveau lien de partage',
        "Lock": "Verrouiller",
        "UnLock": "Déverrouiller",
        "Migrate": "Migrer",
        "Add users": "Gérer les utilisateurs",
        "This is this link to send": "Voici le lien à copier dans un email",
        "Confirmation": "Confirmation",
        "Link sent": "Lien envoyé",
        "Link": "Lien",
        "Notification deleted": "Message suppimée",
        "Problems or questions ? call us at": "Un problème ou une question ? Appelez nous au tel : \"{tel}\" ou par email : \"{email}\"",
        "Are you sure to delete this thread ?": "Etes-vous sûr de vouloir supprimer cette discussion ?",
        "All threads deleted": "Toutes les discussions ont été supprimées",
        "Are you sure to delete all threads ?": "Etes-vous sûr de vouloir supprimer toutes les discussions ?",
        "Delete the current thread": "Supprimer cette discussion",
        "Delete all threads": "Supprimer toutes les discussions",
        "Share removed": "Partage supprimé",
        "Access requested": "Accès demandé",
        "Access request canceled": "Demande d'accès supprimée",
        "Access refused": "Demande d'accès refusée",
        "Access accepted": "Demande d'accès acceptée",
        "Download file": "Téléchargement de fichier",
        "Name of the fileset": "Nom du coffre",
        "Name of the user": "Nom de l'utilisateur",
        "Name of the file": "Nom du fichier",
        "File(s) added or modified": "Fichier(s) ajouté(s) ou modifié(s)",
        "Fileset created": "Coffre créé",
        "Are you sure to delete directory ?": "Etes-vous sûr de vouloir supprimer le répertoire \"{name}\" et son contenu ?",
        "Are you sure to delete folder ?": "Etes-vous sûr de vouloir supprimer le répertoire de coffres \"{name}\" ?",
        "New folder": "Nouveau répertoire de coffres",
        "Folder created": "Répertoire de coffres créé",
        "Folder": "Répertoire de coffres",
        "Number of files": "{files} fichier(s)",
        "Share(s) added": "Partage(s) ajouté(s)",
        "Automatic ask for access": "Demande automatique d'accès",
        "Answer to a access request": "Réponse à une demande d'accès",
        "Answer the access request in the contextual menu": "Vous pouvez également accéder à davantage d'option dans le menu contextuel",
        "Automatic Ask for cryptographic access": "Demande à \"{giverName}\" pour l'accès au coffre \"{filesetName}\" ?",
        "User wants cryptographic access": "L'utilisateur \"{wanterName}\" demande à \"{wantedName}\" un accès au coffre \"{filesetName}\".",
        "Ask cryptographic access": "Demander à \"{giverName}\" un accès au coffre \"{filesetName}\" ?",
        "Do you agree, please verify his identify ?": "Acceptez-vous (Merci de vérifier son identité avant d'accepter) ?",
        "Or go directly in the fileset for this operation": "Ou aller directement dans le coffre pour effectuer cette opération..",
        "Quorum Group initialisation phase 1": "Initialisation du groupe à quorum (phase 1)",
        "initialise your cryptographic secret": "En temps qu'administrateur du groupe à quorum \"{name}\", vous devez initialiser vos secrets cryptographiques. C'est parti ?",
        "Quorum Group initialisation phase 2": "Initialisation du groupe à quorum (phase 2)",
        "you must do the last step of initialisation": "En temps qu'administrateur du groupe à quorum \"{name}\", vous devez effectuer une dernière étape d'initialisation. C'est parti ?",
        "Wrong passphrase": "Mauvaise phrase secrète",
        "Change passphrase": "Changer la phrase secrète",
        "Passphrase close to be strong": "La phrase secrète est proche d'être forte",
        "Passphrase good but not enough": "La phrase secrète est presque suffisante",
        "Passphrase not so bad... ok": "La phrase secrète n'est pas si mal... Ok",
        "Passphrase too weak, do better": "La phrase secrète est trop faible, faites mieux",
        "Passphrase too weak": "La phrase secrète est trop faible",
        "Passphrase realy too weak": "La phrase secrète est vraiment trop faible",
        "Passphrase cannot be empty": "La phrase secrète ne peut pas être vide",
        "Passphrase initialization": "Initialisation de la phrase secrète",
        "Passphrase dropped": "phrase secrète verrouillée",
        "Forgot my passphrase": "J'ai oublié ma phrase secrète",
        "New passphrase": "Nouvelle phrase secrète",
        "Current passphrase": "Phrase secrète actuelle",
        "Current password": "Mot de passe actuel",
        "Initialize passphrase": "Initialisation de la phrase secrète",
        "Keys initialized": "Les clefs sont initialisées",
        "Set": "Initialiser",
        "The list is empty": "La liste est vide",
        "You are not allowed to see this content": "Vous n'êtes pas autorisé à voir ce contenu",
        "Create a new user account": "Créer un nouveau compte d'utilisateur",
        "Create a new quorum group": "Créer un nouveau groupe à quorum",
        "Created by": "Par {name}",
        "Shared with": "Partagé avec {name}",
        "You dont want another account ?": "Vous ne voulez pas d'un nouveau compte ?",
        "Authenticate with google": "Vous pouvez vous authentifier avec votre compte Google !",
        "Auth with Google": "Connexion avec Google",
        "No, I am sorry": "Non, désolé",
        "I remember": "Je me souviens",
        "Enter a new password": "Entrer un nouveau mot de passe",
        "Enter a name": "Entrer un nom",
        "You will be able to change this name at anytime in the vault properties": "Vous pourrez changer ce nom à tout moment, dans les propriétés du coffre",
        "I forget my password": "J'ai oublié mon mot de passe",
        "Password reinitialization": "Réinitialisation du mot de passe",
        "Few steps to reinitialize your password": "Veuillez suivre ces étapes pour réinitialiser votre mot de passe",
        "A email has been sent. Please check your email and click on the link to choose a new password": "Un mail a été envoyé à \"{email}\". Veuillez consulter votre boite aux lettre et cliquer sur le lien pour choisir un nouveau mot de passe.",
        "Password reinitialized": "Mot de passe réinitialisé",
        "Enter your email": "Entrez votre adresse mail",
        "reinitConfirm": "Attention, en effectuant un changement de mot de passe, tous les coffres que vous n'avez pas partagés seront inaccessibles ",
        "Well done": "C'est fait",
        "Add": "Ajouter",
        "Chat": "Discuter",
        "Emails": "Mails",
        "Need Passphrase": "Besoin de votre phrase de passe",
        "Need Password": "Besoin de votre mot de passe",
        "I_Need_Passphrase": "Merci de saisir votre mot de passe pour déchiffrer vos données. Nous utiliserons l'une de vos clefs suivante :",
        "I_Need_Password": "Merci de saisir votre mot de passe pour déchiffrer vos données. Nous utiliserons l'une de vos clefs suivante :",
        "Your email is validated": "Votre mail a été verifié avec succès.",
        "You can close this tab": "Vous pouvez fermer cet onglet ou cette fenêtre.",
        "Valid": "Valider",
        "In progress": "En cours",
        "Cancel in progress": "Annulation en cours",
        "actions required": "action(s) requise(s)",
        "Email deleted": "Mail {email} supprimé",
        "Are you sure to drop email ?": "Etes vous sûr de vouloir supprimer \"{email}\" ?",
        "User deleted": "Utilisateur \"{login}\" supprimé",
        "User locked": "Utilisateur \"{login}\" verrouillé",
        "User unlocked": "Utilisateur \"{login}\" déverrouillé",
        "An email has been sent": "Un email a été envoyé à \"{email}\"",
        "Are you sure to delete User ?": "Etes-vous sûr de supprimer l'utilisateur \"{login}\" ?",
        "Are you sure to lock User ?": "etes-vous sûr de verrouiller l'utilisateur \"{login}\" ?",
        "Are you sure to unlock User ?": "Etes-vous sûr de déverrouiller l'utilisateur \"{login}\" ?",
        "locked": "Verrouillé",
        "LoginName": "Utilisateur",
        "Group deleted": "Groupe \"{name}\" supprimé",
        "File deleted": "Fichier \"{name}\" supprimé",
        "Groups": "Groupes",
        "Request in progress": "Demande en cours",
        "Are you sure to delete Group ?": "Etes-vous sûr de vouloir éffacer le groupe \"{name}\" ?",
        "Sorry, group not found": "Désolé, le groupe n'a pas été trouvé",
        "Removed user from group ?": "Etes-vous sûr de vouloir enlever l'utilisateur \"{login}\" du groupe \"{group}\" ?",
        "User removed from group": "L'utilisateur \"{login}\" a été supprimé du groupe \"{group}\"",
        "Click to chat with...": "Cliquez pour discuter avec...",
        "Click to add a user": "Cliquez pour ajouter un utilisateur",
        "Click to add a friend": "Cliquez pour ajouter un correspondant",
        "New quorum": "Nouveau groupe à quorum",
        "New group": "Nouveau groupe",
        "Last modification": "Date de dernière modification",
        "Group created": "Groupe créé",
        "See logs": "Voir les logs",
        "Add a user": "Ajouter un utilisateur",
        "User added to group": "Utilisateur \"{login}\" ajouté au groupe \"{group}\"",
        "Group name changed": "Nom changé en \"{name}\"",
        "Name changed": "Nom changé en \"{name}\"",
        "Description changed": "Description changée en \"{name}\"",
        "Group description changed": "Description du groupe modifiée",
        "Fileset description changed": "Description du coffre modifié",
        "Expiration motified": "Expire dans {expire}",
        "Share expiration motified": "expiration de partage modifié",
        "Devices": "Appareils",
        "Selected": "Sélectionné",
        "Display Name": "Nom affiché",
        "DisplayName changed": "Nom affiché changé pour \"{displayName}\"",
        "The key cannot be registered !": "La clef ne peut pas être enregistré !",
        "No u2f key named": "Pas de clef U2F \"{name}\"",
        "Passphrase": "Phrase de passe",
        "Fido autentication successfull": "Authentification Fido réussie",
        "Avatar changed": "Avatar modifié",
        "Avatar": "Avatar",
        "u2f key registered": "clef U2F enregistrée",
        "u2f key deleted": "clef U2F \"{deviceName}\" supprimée",
        "u2f key selected": "clef U2F \"{deviceName}\" sélectionnée",
        "Are you sure to drop u2f key ?": "Etes-vous sûr de vouloir supprimer la clef U2F \"{deviceName}\" ?",
        "New u2f device": "Nouvelle clef U2F",
        "Your key is registered": "Votre clef est enregistrée",
        "Empty": "Vide",
        "This field can't be empty": "Ce champs ne peut pas être vide",
        "Fido autentication": "Authentification Fido",
        "Email not verified": "Email non vérifié",
        "Fido device not confirmed": "Clef U2F non confirmée",
        "Access validation in progress": "Validation de votre identité et de vos accès en cours",
        "Your access is under validation": "Votre identité et votre autorisation d'accès à ce coffre doivent être validées par",
        "You may ask somebody else to access this repository": "Si vous ne voulez pas attendre, vous avez la possibilité de demander l'accès à une autre personne (via l'onglet 'Partages')",
        "Dataroom not decypherable": "Coffre non déchiffrable",
        "Please ask someone for access": "Merci de demander l'accès à ce coffre",
        "Please ask an administrator": "Merci de demander à un administrateur",
        "Decrypt and enter": "Déchiffrer et entrer",
        "You must agree to the terms of use": "Vous devez accepter les conditions générales d'utilisation",
        "I agree to the terms of use": "J'accepte les conditions générales d'utilisation",
        "Write here a new message": "Ecrivez un nouveau message ci-dessous :",
        "as_send_you_crypted_notification": "vous a envoyé {n} notification(s) chiffrée(s)",
        "Edit the current message": "Changer le message suivant :",
        "Reinitialisation": "Réinitialisation",
        "crypted notification": "Notification chiffrée",
        "crypted message": "Message chiffré",
        "List": "Liste",
        "small": "Petit",
        "medium": "Moyen",
        "big": "Grand",
        "View list": "Vue liste",
        "View tiles": "Vue icônes",
        "Sort ascending": "Trier par ordre croissant",
        "Sort descending": "Trier par ordre décroissant",
        "Start date": "Date de début",
        "End date": "Date de fin",
        "You can chose a period to show between theses two dates": "Vous pouvez choisir une période entre ces deux dates",
        "You need more rights to see this content": "Vous n'avez pas les permissions pour voir ce contenu",
        "Elements count to show": "Nombre d'élements à afficher",
        "Show more elements": "Afficher plus d'élements",
        "The start date must be prior to the end date": "La date de début doit être antérieure à la date de fin",
        "Unlimited": "Illimité",
        "Storage utilization on your instance": "Utilisation de l'espace de stockage sur votre instance",
        "Usage statistics": "Statistiques d'usage",
        "Percent": "Pourcentage",
        "Users with ": "Utilisateurs utilisant le plus de stockage",
        "Heaviest filesets": "Plus gros coffres",
        "Last created users": "Derniers utilisateurs créés",
        "Users with largest data storage": "Utilisateurs qui utilisent le plus de stockage",
        "Wanted Accepted": "Accès accordé",
        "Wanted Refused": "Ok. Accès refusé",
        "Wanted Asked": "Demande d'accès transmise",
        "New file": "Nouveau fichier",
        "Open logs": "Ouvrir les logs",
        "A quorum need an action": "Un quorum nécessite une action",
        "A fileset need an action": "Un coffre nécessite une action",
        "Number of use": "Nombre d'utilisations",
        "Search": "Rechercher",
        "No result found": "Aucun résultat trouvé",
        "My emails": "Mes emails",
        "My logs": "Mes logs",
        "My password": "Mon mot de passe",
        "My account": "Mon compte",
        "Device without name, you may had a new one": "Appareil sans nom, vous devriez en ajouter un",
        "Change name": "Modifier le nom",
        "Change description": "Modifier la description",
        "When the keystore is set on `don't care`, you will be able to encrypt data for a receiver even if you don't know him": "Lorsque le gestionnaire de clef est reglé sur `Ne pas se soucier`, vous pourrez chiffrer vos données pour un correspodant même si vous ne le connaissez pas.",
        "Keys": "Clefs",
        "Signed keys": "Clefs signées",
        "Signed by": "Signé(es) par",
        "Signed since": "Signé(es) depuis",
        "The keys you signed": "Les clefs que vous avez signées",
        "Private key is in memory ?": "La clef privée est en mémoire ?",
        "Private key in memory up to": "La clef privée est en mémoire jusqu'a",
        "Release": "Libérer",
        "Finger print": "Empreinte numérique",
        "Drop": "Déposer",
        "Click to upload a new file": "Cliquez pour envoyer un fichier",
        "Create a new Quorum with these devices": "Creer un nouveau quorum avec ces appareils",
        "You are on this device": "Vous êtes sur cet appareil",
        "Unknown Key Or Removed Key": "Clef inconnue ou supprimée",
        "Unknown user": "Utilisateur inconnu",
        "Unknown type": "Type inconnu",
        "An old key which isn't existing anymore": "Une ancienne clef, qui n'existe plus",
        "Signature not found": "Signature non trouvée",
        "Creation date not found": "Date de création non trouvée",
        "Don't care": "Ne pas se soucier",
        "Normal": "Normal",
        "Paranoid": "Paranoiac",
        "Show debug functions": "Montrer les fonctions de debug",
        "Should be greater than the application trust level": "Doit être plus grand que le niveau de confiance par défaut",
        "Use care when changing the following settings": "Faites attention en modifiant les paramètres suivants, vous pourriez rendre votre compte inutilisable, contactez le support si vous avez des questions",
        "Advanced search on users": "Recherche avancée sur les utilisateurs",
        "Advanced search on vaults": "Recherche avancée sur les coffres",
        "No item(s) selected yet": "Pas d'élément(s) selectionné(s)",
        "Vaults shared with": "Coffres partagés avec",
        "Remove existing filters": "Supprimer les filtres existants",
        "Send a custom email": "Envoyer un email d'invitation personnalisé",
        "My friends": "Mes relations",
        "Friends": "Relations",
        "New friend": "Nouvelle relation",
        "Trusted connection": "Relation confirmée",
        "Which keys will be used to sign ?": "Quelles clefs vont être utilisées pour signer ?",
        "Sign friend": "Confirmer une relation",
        "Are you sure to signed this friend ?": "Etes-vous sûr de vouloir confirmer cette relation ?",
        "Friend deleted": "Relation supprimée",
        "Are you sure to delete Friend ?": "Etes-vous sûr de vouloir supprimer cette relation ?",
        "Add friend": "Ajouter une relation",
        "AAdvanced search on groups": "Recherche avancée sur les groupes",
        "Friend signed": "Relation confirmée",
        "Continue": "Continuer",
        "Signed By": "Confirmé par",
        "Back to": "Retour vers \"{elName}\"",
        "must be a string": "Doit être du texte",
        "Create a new url": "Créer une nouvelle invitation",
        "Link name": "Nom du lien",
        "must be filled": "Doit être complété",
        "Recipient email": "Email du destinataire",
        "must be an email": "Doit être un email",
        "must be a number": "Doit être un nombre",
        "Current folder": "Dossier courant",
        "Are you sure to delete Url": "Etes-vous sûr de vouloir supprimer cette invitation ?",
        "Folder deleted": "Répertoire supprimé",
        "No notifications": "Aucune notification",
        "You will be able to change this name at anytime in the folder properties": "Vous aurez la possibilité de changer ce nom à tout moment dans les propriétés du dossier",
        "No share user selected": "Aucun utilisateur sélectionné avec lequel partager",
        "Rename folder": "Renommer le répertoire de coffre",
        "Fileset renamed": "Dépôt renommé",
        "by": "Par",
        "by ": "Par ",
        "Rename fileSet": "Renommer le coffre",
        "fileset closed": "Coffre fermé",
        "fileset open": "Coffre ouvert",
        "Move": "Déplacer",
        "FileSet Moved": "Coffre Déplacé",
        "validate": "Valider",
        "file validated": "Fichier validé",
        "Type": "Type",
        "Role": "Rôle",
        "share deleted": "Partage supprimé",
        "Share deleted": "Partage supprimé",
        "Are you sure to delete share ?": "Etes-vous sûr de vouloir supprimer ce partage ?",
        "QuorumGroup deleted": "Groupe à quorum supprimé",
        "Are you sure to delete quorum group ?": "Etes-vous sûr de vouloir supprimer ce groupe à quorum ?",
        "Login name": "Nom de connexion",
        "lock": "Bloquer",
        "Are you sure to lock": "Etes-vous sûr de bloquer ?",
        "Are you sure to delete user ?": "Etes-vous sûr de vouloir supprimer cet utilisateur ?",
        "Update": "Mise à jour",
        "Member of": "Membre de",
        "No group(s)": "Aucun groupe(s)",
        "Quorum groups": "Groupes à quorum",
        "Advanced search on quorums": "Recherche avancée sur les quorums",
        "Quorum Group name": "Nom du groupe à quorum",
        "Threshold": "Seuil",
        "Members": "Membres",
        "Are you sure to delete group ?": "Etes-vous sûr de vouloir supprimer ce groupe ?",
        "IP limitation": "Limitation d'IP",
        "Email limitation for invitation": "Limitation d'email pour les invitations",
        "Groups of Managers": "Groupe de gestionnaires",
        "Add configuration for a new group": "Ajouter une configuration pour un nouveau groupe",
        "password for verification": "Mot de passe pour vérification",
        "Your trust level": "Votre niveau de confiance",
        "Default": "Défaut",
        "Send test mail": "Envoyer un email de test",
        "Add a new mail": "Ajouter un email",
        "Folder name": "Nom du dossier",
        "Visibility": "Visibilité",
        "Not visible if empty": "Non visible si vide",
        "Visible for members below": "Visible pour les membres ci-dessous",
        "Clear": "Ouvert",
        "Click": "Cliquez",
        "Or drop your files here": "Ou glissez vos fichiers ici",
        "Rename file": "Renommer le fichier",
        "Move to": "Déplacer \"{elName}\" vers",
        "Fileset Moved": "Coffre déplacé",
        "Element moved": "Elément déplacé",
        "Advanced search on files": "Recherche avancée sur les fichiers",
        "Create a new group": "Créez un nouveau groupe",
        "No group selected": "Aucun groupe sélectionné",
        "Select a group": "Sélectionnez un groupe",
        "Deleting done": "Suppression effectuée",
        "Repository created": "Coffre créé",
        "New fileset": "Nouveau coffre",
        "Rename url": "Renommer l'invitation",
        "Are you sure to delete Url ?": "Etes-vous sûr de vouloir supprimer cette invitation ?",
        "Number of available deposits": "Nombre de coffre(s) disponible(s)",
        "Given by": "Accordé par",
        "in folder": "Dans le répertoire de coffres",
        "Not authorized": "Non autorisé",
        "Can create new filesets": "Vous pouvez créer des coffres",
        "Can create directories": "Vous pouvez créer des répertoires de coffres",
        "Can modify this rights": "Vous pouvez modifier ces droits",
        "Can delete this directory": "Vous pouvez supprimer ce répertoire de coffres",
        "Can modify subdir rights": "Vous pouvez modifier les droits des sous-répertoires de coffres",
        "Your current rights": "Vos droits actuels",
        "Avoid validation for": "Evite la validation pour",
        "Validators for new shares": "Validateurs pour les nouveaux partages",
        "Validators for content": "Validateurs de contenu",
        "Recovering friends": "Membres du recouvrement",
        "Back": "retour",
        "New share": "Nouveau partage",
        "New": "Nouveau",
        "Url created & Link sent": "Invitation et email envoyés",
        "browser error": "Votre navigateur n'est pas à jour ou n'est pas compatible avec l'application.",
        "Please use another browser": "Nous vous invitons à installer la dernière version de Firefox / Chrome / Edge(chromium)",
        "Critical error": "Erreur critique",
        "Show previous path": "Afficher les chemins précédents",
        "More infos": "Plus d'informations",
        "Protect your key": "Protéger votre clef",
        "Not Permitted to login at this time": "Il n'est pas permis de se connecter sur cette tranche horaire",
        "Not permited to logon at this workstation": "Il n'est pas permis de se connecter sur ce poste",
        "Password expired": "Mot de passe expiré",
        "Account disabled": "Compte désactivé",
        "Account expired": "Compte expiré",
        "User must reset password": "Vous devez changer votre mot de passe LDAP",
        "User account locked": "Compte bloqué",
        "Invalid username/password": "Mauvais login ou mot de passe",
        "Invalid password": "Mauvais mot de passe",
        "Fake": "Action simulée",
        "Did something": "A fait quelque chose",
        "Your account is created and ready to use": "Votre compte est créé et prêt",
        "Your account is activated and ready to use": "Votre compte est activé et prêt à être utilisé",
        "You can give access for recovering": "Vous pouvez donner un accès pour recouvrement",
        "recover": "Recouvrement",
        "Some notifications": "Des notifications",
        "Decrypt in progress": "Déchiffrement en cours",
        "Adding share(s)": "Partage en cours",
        "My Two Factors": "Mes validations à deux étapes",
        "TwoFactors": "Validations en deux étapes",
        "Add a new Phone": "Ajouter un téléphone",
        "New device": "Nouvel appareil",
        "Device created": "Appareil créé",
        "You are going to remove a 2FA device, are you sure?": "Vous êtes sur le point de supprimer un moyen d'authentification, êtes vous sûr ?",
        "Your phone has been added.": "Votre téléphone a été ajouté.",
        "Confirm phone": "Confirmez votre téléphone",
        "Add a phone to your account": "Ajoutez un téléphone",
        "Add a key to your account": "Ajoutez une clef",
        "Invalid code": "Code invalide",
        "Two Factor Authentication": "Validation à deux étapes",
        "Please follow the instructions to ensure your identity": "Veuillez suivre les instructions suivantes pour assurer votre identité.",
        "No Two Factor Authentication is set, please add one": "Aucune authentification à deux facteurs n'est définie, veuillez en ajouter une.",
        "Enter the code": "Saisissez le code",
        "Too many attempts": "Trop de tentatives, veuillez réessayer plus tard",
        "Device already set": "Appareil déjà enregistré",
        "Please plug in your key to register": "Merci d'insérez votre clef pour l'ajouter",
        "Register": "Ajouter",
        "Error when registering device": "Erreur lors de l'ajout de la clef",
        "Invalid key": "Clef non valide",
        "Please plug in and active your key": "Merci d'insérer votre clef et de l'activer",
        "Advanced search on folders": "Recherche avancée sur les répertoires de coffres",
        "First page": "Première page",
        "Previous page": "Page précédente",
        "Next page": "Page suivante",
        "Last page": "Dernière page",
        "Number of items to display": "Nombre d'éléments à afficher",
        "multiConfirm": "Etes vous sûr de vouloir {actionLabel} ces éléments ?",
        "User already exist": "Ce compte existe déjà.",
        "Select": "Selectionner",
        "Suppression": "Suppression",
        "Friend signing": "Signature de relation",
        "cancelWanted": "Annulation de demande",
        "This is not a valid String": "Chaîne non valide",
        "Add a device": "Ajouter un appareil",
        "No device set, please add one": "Aucun appareil configuré, merci d'en ajouter un",
        "Personal Settings": "Menu personnel",
        "Maintenance": "Maintenance",
        "Maintenance end : ": "Fin de la maintenance : \"{elName}\"",
        "Logs have been downloaded": "Les journaux ont été téléchargés",
        "Decrypt ok": "Déchiffrement ok",
        "Canceled": "Annulé",
        "My devices": "Mes appareils",
        "New Fileset": "Nouveau coffre",
        "New Folder": "Nouveau répertoire de coffre",
        "Rename FileSet": "Renommer le coffre",
        "Decrypt ok ": "Déchiffrement ok",
        "already exist": "Ce nom existe déjà",
        "New directory": "Nouveau répertoire",
        "Directory created": "Répertoire créé",
        "File added created": "Fichier ajouté",
        "Selection mode": "Mode sélection",
        "Select Display": "Sélection de vue",
        "Show filesets where I'm": "Montrer les coffres où je suis",
        "In Shared with": "En partage avec",
        "Content Validator only": "Validateur de contenu uniquement",
        "In Quorum only": "Quorum uniquement",
        "Show": "Montrer les",
        "Filesets": "Coffres",
        "Folders": "Répertoires de coffres",
        "Login already exist": "Ce login existe déjà",
        "Reading file": "Lecture d'un fichier",
        "Downloading": "En cours de chargement",
        "email": "email",
        "Message deleted": "Message supprimé",
        "add": "Ajouter",
        "your message is too long": "Votre message est trop long ({length} / {maxLength})",
        "Fileset name": "Nom du coffre",
        "Updated": "Mise à jour effectuée",
        "Copied to clipboard": "Copié dans le presse-papiers",
        "Add an email to your account": "Ajouter un email à votre compte",
        "30 chars max": "30 caractères maximum",
        "Url renamed": "Invitation renommée",
        "Already exists": "Ce nom existe déjà",
        "Are you sure to give access to ?": "Êtes-vous sûr de vouloir donner accès à {name}?",
        "All logs decrypted": "Tous les logs ont été déchiffrés",
        "finished": "Terminé",
        "move finished": "Déplacement terminé",
        "content validator": "Valideur de contenus",
        "Are you sure to delete thoses elements ?": "Etes vous sûr de vouloir supprimer ces éléments ?",
        "File downloaded": "Fichier chargé",
        "File renamed": "Fichier renommé",
        "Share created": "Partage ajouté",
        "Share updated": "Partage mis à jour",
        "roles": "rôles",
        "rights": "droits",
        "Are you sure to delete thoses shares ?": "Etes vous sûr de vouloir supprimer ces partages ?",
        "Ip address": "Adresse IP",
        "Owner name": "Nom du propriétaire",
        "Object Name": "Nom de l'objet",
        "Advanced search on logs": "Recherche avancée dans les logs",
        "Ip adress": "Adresse IP",
        "validator": "Validateur",
        "upload file": "Fichier en cours de chargement",
        "Encrypted notification": "Notification chiffrée",
        "unread": "non lu",
        "Notifications": "Notifications",
        "There is no message, write an new one in text field below": "Aucun message, vous pouvez en écrire un dans l'entrée de texte ci-dessous",
        "validated": "Validé",
        "quorumGroups": "Groupes à quorum",
        "New quorum group": "Nouveau groupe à quorum",
        "Quorum Group created": "Groupe à quorum créé",
        "Quorum deleted": "Quorum supprimé",
        "Are you sure to delete thoses quorum groups ?": "Êtes-vous sûr de vouloir détruire ces groupes à quorum ?",
        "Are you sure to delete thoses users ?": "Êtes-vous sûr de vouloir détruire ces utilisateurs ?",
        "Are you sure to delete thoses groups ?": "Êtes-vous sûr de vouloir détruire ces groupes ?",
        "Group updated": "Groupe mis à jour",
        "Decrypt all logs": "Déchiffrer tous les logs",
        "Decrypt all": "Déchiffrer tout",
        "All logs that you have right to decrypt are so": "Tous les logs que vous avez le droit de déchiffrer sont ainsi",
        "Configuration updated": "Configuration mise à jour",
        "verification mismatch": "Les mots de passe ne correspondent pas",
        "No todos": "Aucune action restante",
        "No quorum group": "Aucun quorum",
        "no log": "Aucun log",
        "No group": "Aucun groupe",
        "No friend": "Aucune relation",
        "No file": "Aucun fichier",
        "No device registered": "Aucun appareil enregistré",
        "Creating Directory": "Creating Directory",
        "Folder renamed": "Répertoire de coffres renommé",
        "Quorum group updated": "Groupe à quorum mis à jour",
        "Quorumgroups": "Groupes à quorum",
        "The threshold cannot be more than then number of admins": "Le seuil ne peut être supérieur au nombre de membres",
        "password": "Mot de passe",
        "Create a new user": "Créez un nouvel utilisateur",
        "User updated": "Utilisateur mis à jour",
        "Please remove quorum group from folders configuration before deleting it": "Retirez le quorum des configurations de repertoires de coffres avant de le supprimer, merci.",
        "Are you sure to delete url ?": "Etes-vous sûr de vouloir supprimer cette invitation ?",
        "Validated": "Validé",
        "Download all logs": "Télécharger tous les logs",
        "See shares": "Voir les partages",
        "You don't have the right to see shares": "Vous n'avez pas le droit de visualiser les partages",
        "You don't have the right to see logs": "Vous n'avez pas le droit de visualiser les journaux",
        "Access granted": "Accès autorisé",
        "This url is no longer available": "Cette invitation n'est plus disponible",
        "Please ask the sender to send you a new one.": "Veuillez demander à l'expéditeur de vous en envoyer une nouvelle"

    }
};

// --------------
// -------------- Part errors
// --------------
export const errors = {
    "fr": {
        "Warning": "Attention",
        "Failed": "Echec",
        "Administrative size exceeded": "L'espace maximum a été atteint. Votre fichier n'a pas pu être sauvegardé. Contactez votre administrateur",
        "IEDetect": "Les performances d'Internet Explorer / Edge ne sont aujourd'hui pas suffisantes pour des calculs cryptographiques. Nous vous conseillons d'utiliser un autre navigateur (par example, Firefox ou Chrome).",
        "IEOK": "Néanmoins, je veux continuer avec I.E.",
        "Must be a valid email": "Doit être une adresse mail valide",
        "Password cannot be empty": "Ne doit pas être vide",
        "Password close to be strong": "Proche d'être bon",
        "Password good but not enough": "Bon, mais vous pouvez faire mieux",
        "Password not so bad... ok": "Pas si mal... ok",
        "Password too weak, do better": "trop faible, faites mieux",
        "Password too weak": "trop faible",
        "Password realy too weak": "vraiment trop faible",
        "Must be filled": "Ne peut pas être vide",
        "Must be filled (1-10)": "doit contenir (1-10)",
        "Sorry, object not found": "Désolé, objet non trouvé",
        "Sorry, file not found": "Désolé, fichier non trouvé",
        "Sorry, quorum group not found": "Désolé, groupe à quorum non trouvé",
        "Sorry, URL not found": "Désolé, invitation non trouvée",
        "Sorry, thread not found": "Désolé, la conversation n'existe pas ou plus",
        "A chunk is missing": "Un morceau est manquant",
        "Authentication failed": "L'authentification a échoué",
        "Auth type not yet supported": "Type d'authentification non supportée",
        "Cannot add files to a crypted fileset": "Impossible d'ajouter des fichiers à un coffre actuellement chiffré",
        "Cannot add shares in a non init quorumGroup": "Impossible d'ajouter les partages à un groupe à quorum non initialisé",
        "Cannot anonymously modify a fileSet": "Ne peut pas anonymement modifier un coffre",
        "Cannot change text of a crypted fileset": "Ne peut pas changer le texte d'un coffre chiffré",
        "Cannot contact database": "Impossible de contacter la database",
        "Cannot create an existing user": "L'utilisateur existe déjà",
        "Cannot delete an empty element": "Ne peut pas supprimer un élément vide",
        "Cannot delete non initialised notification": "Ne peut pas supprimer une notification non initialisée",
        "Cannot delete not my notification": "Impossible de détruire la notification, elle ne vous appartient pas.",
        "Cannot change password for non local account": "Impossible de modifier le mot de passe d'un compte tiers",
        "Cannot flush from an empty element": "impossible d'écrire un élément vide",
        "Cannot get data from an empty element": "Impossible de lire des données d'un élément vide",
        "Cannot mark non initialised notification": "Impossible de marquer une notification non initialisée",
        "Cannot mark, not to me": "Impossible de marquer, elle ne vous appartient pas",
        "Cannot migrate a non ready quorumGroup": "Impossible de migrer un group à quorum non prêt",
        "Cannot remove files from a crypted fileset": "Impossible de supprimer des fichiers d'un coffre chiffré",
        "Confirmation string mismatch": "La chaine de caractère de confirmation ne correspond pas",
        "Credential changement failed": "Les changement de parametres de compte ont échoués",
        "Credential type not implemented": "Type d'authentification non supporté",
        "Current user not set": "Utilisateur courant vide",
        "Database error": "Erreur de base de donnée",
        "Database not open": "Base de donnée non ouverte",
        "Element not found in Store": "Elémet non trouvé en mémoire",
        "Email already exist": "L'email existe déjà",
        "Error CRUD": "Erreur CRUD",
        "Error while deleting element": "Une erreur est survenue durant l'éffacement",
        "Error while flushing element": "Une erreur est survenue durant la sauvegarde",
        "File not found": "Fichier non trouvé",
        "File reader not done": "Lecteur de fichier non trouvé",
        "FileSet crypted": "Coffre chiffré",
        "FileSet not found": "Coffre non trouvé",
        "FileSet not in store": "Le coffre n'existe pas en mémoire",
        "FileSet object not filled": "L'objet coffre est vide",
        "Group already exist": "Le groupe existe déjà",
        "Group object not filled": "L'objet groupe est vide",
        "Must be auth first": "Vous devez être authentifié",
        "Must have valid emails before": "Vous devez avoir une adresse mail valide",
        "No credentials defined for user": "Pas de shema d'autentification pour cet utilisateur",
        "Cannot delete actually connected device": "Impossible de supprimer le device depuis lequel vous êtes connecté",
        "Error registration u2f key": "Erreur d'enregistrement de la clef U2F",
        "No u2f key registered": "Pas de clef U2F enregistrée",
        "U2f Authentication Failed": "Echec d'authentification U2F",
        "The selected File mut be an image": "Le fichier sélectionné doit être une image",
        "No credentials type defined for user": "Pas de type d'authentification définie pour l'utilisateur",
        "No fileSet _id to save": "Pas d'id de coffre à sauvegarder",
        "Not admin of quorumGroup": "Pas administrateur du groupe à quorum",
        "Not an admin": "Pas un administrateur",
        "Notification not found in database": "Notification non trouvée dans la database",
        "QuorumGroup already exist": "Le groupe à quorum existe déjà",
        "QuorumGroup no shares for me": "Groupe à quorum pas de partage pour moi",
        "QuorumGroup not ready for compute Secret": "Groupe à quorum pas prêt pour calculer le secret",
        "QuorumGroup number of admins too small": "Le nombre d'administrateur est trop petit",
        "QuorumGroup object not filled": "L'objet Groupe à quorum n'est pas rempli",
        "You need to add more users": "Vous devez ajouter au moins {count} utilisateurs de plus",
        "Set or select the minimun number of users": "Definissez ou selectionnez le nombre minimal d'utilisateurs",
        "Security violation": "Violation de sécurité, \"{login}\" ne peut pas donner le droit \"{right}\" car il ne l'a pas lui-même.",
        "Share not for a group for me": "Partage pas pour un groupe pour moi",
        "No admin privatekey for me in this quorumGroup": "",
        "My keys": "Mes clés",
        "Crypting key": "Clef de chiffrement",
        "Signing key": "Clef de signature",
        "Quorum key": "Clef de quorum",
        "Quorum member key": "Clef de membre de quorum",
        "Revoked": "Revoqué(e)",
        "InProgress1": "Cette clef est en attente d'une première validation de quorum",
        "InProgress2": "Cette clef est en attente d'une seconde validation de quorum"
    }
};

// --------------
// -------------- Part notifications
// --------------
export const notifications = {
    "fr": {
        "Sorry": "Désolé",
        "Cool": "",
        "Hey": "",
        "fileset_updated": "a modifié le coffre",
        "fileset_updateFiles": "a ajouté ou modifié les fichiers {fileNames} dans ",
        "fileset_removeFile": "a supprimé {path} dans",
        "fileset_createPath": "a créé le dossier {path} dans",
        "fileset_removePath": "a supprimé le dossier {path} et son contenu dans ",
        "fileset_commentAdd": "a ajouté un commentaire dans",
        "fileset_commentRemove": "a supprimé un commentaire dans",
        "fileset_commentChange": "a modifié un commentaire existant dans",
        "fileset_deleted": "a supprimé le coffre",
        "fileset_dropFromShare": "vous a supprimé l'accès au coffre",
        "fileset_newShare": "a partagé avec vous le coffre",
        "fileset_addGuest": "vous invite à accéder au coffre",
        "fileset_wanted": "souhaite accéder au coffre",
        "fileset_acceptWanted": "a accepté et vous donne accès au coffre",
        "fileset_cancelWanted": "a refusé de vous donner accès au coffre",
        "url_to_fileset": "Une de vos invitations a été utilisée pour créer le coffre \"{name}\".",
        "welcome": "Bienvenue à bord de la part de toute notre équipe !",
        "Account locked": "Votre compte est verouillé !",
        "Account unlocked": "Votre compte est déverouillé",
        "email_confirmed": "Votre email \"{email}\" a été confirmé, merci !",
        "mail_error": "Désolé, une erreur est survenue durant l'envoie d'un mail à \"{email}\"",
        "unknown_notif": "Message inconnu!",
        "fileset_updated_text": "\"{user}\" a modifié le coffre \"{name}\".",
        "fileset_deleted_text": "Désolé, mais \"{user}\" a supprimé le coffre \"{name}\".",
        "fileset_dropFromShare_text": "Désolé, mais \"{user}\" vous a supprimé l'accès au coffre \"{name}\".",
        "fileset_newShare_text": "\"{user}\" a partagé avec vous le coffre \"{name}\".",
        "fileset_addGuest_text": "\"{user}\" vous invite à accéder au coffre \"{name}\".",
        "fileset_wanted_text": "\"{user}\" souhaite accéder au coffre \"{name}\".",
        "fileset_acceptWanted_text": "\"{user}\" a accepté et vous donne accès au coffre \"{name}\".",
        "fileset_cancelWanted_text": "Désolé, mais \"{user}\" a refusé de vous donner accès au coffre \"{name}\".",
        "welcome_text": "Bienvenue à bord de la part de toute notre équipe !",
        "locked_text": "Votre compte est verouillé!",
        "unlocked_text": "Votre compte est déverouillé",
        "email_confirmed_text": "Votre email \"{email}\" a été confirmé, merci !",
        "mail_error_text": "Désolé, une erreur est survenue durant l'envoie d'un mail à \"{email}\"",
        "unknown_notif_text": "Message inconnu!"
    }
};

export const mail = {
    "fr": {
        "A new crypted message": "Un nouveau message sécurisé de {name}",
        "You have received a crypted message": "Vous venez de recevoir un message chiffré de {name}",
        "Hi": "Bonjour {name},",
        "Confirm": "Confirmer",
        "This is the revival number": "Relance numéro {revivalNumber}...",
        "Copy past the following link into your browser": "Copiez le lien suivant dans votre navigateur",
        "Click the button to confirm": "Cliquez pour confirmer",
        "Here to direct access": "Ici pour un accès direct",
        "Here to confirm": "Cliquez ici pour valider",
        "You have received crypted messages": "Vous avez reçu {n} messages chiffrés",
        "new crypted messages": "{n} messages chiffrés pour vous",
        "one message from": "Un message de {name} le, {date}",
        "FileSet Modifications": "{n} modifications de coffres chiffrés",
        "FileSet Modification": "Le coffre \"{fname}\" a été modifié",
        "FileSet Modification content": "{name} a modifié le coffre \"{fname}\" le {date}",
        "FileSet Modifications content": "{n} modifications de coffres chiffrés ont été effectuées récemment",
        "one fileset Modification": "Le coffre \"{fname}\" a été modifié par {name} le {date}",
        "Email validation": "Validation d'adresse mail \"{email}\"",
        "Last step to register this email into you Lynvictus account": "Nous avons besoin d'une confirmation pour l'adresse \"{email}\".",
        "This email is erased from your lynvictus account": "Le mail \"{email}\" a été effacé de votre compte",
        "Your email is not longer valid for your account": "Votre mail n'est plus valide pour votre compte \"{login}\".",
        "This is a test email": "Ceci est un mail de test.",
        "You have nothing to do": "Vous n'avez rien à faire. Le lien est juste pour vous permettre de vérifier que les accès fonctionnent.",
        "Link": "Lien",
        "Account nearly created": "Votre compte \"{login}\" est bientot prêt",
        "Last step to create your account": "Derniere étape pour créer votre compte",
        "Please confirm your email with the following link": "Veuillez confirmer votre email en suivant le lien",
        "Reinitialisation of your Password": "Réinitialisation de votre mot de passe",
        "It seems that you have forgotten your password...": "Il me semble que vous avez oublié votre mot de passe...",
        "Please confirm your new password by clicking the following link": "Veuillez confirmer votre mot de passe en cliquant sur le lien suivant.",
        "FIDO registration key": "Enregistrement de la clef FIDO \"{deviceName}\"",
        "Last step to register this FIDO Key": "Dernière étape pour enregistrer la clef FIDO \"{deviceName}\" pour votre compte \"{login}\".",
        "Please confirm the key": "Veuillez confirmer la clef FIDO",
        "Direct access": "Accès direct",
        "Some modifications": "Des modifications ont été apporté à \"{fName}\"",
        "Subject_WELCOME": "Bienvenue à {name}",
        "Content_WELCOME_1": "Bienvenue à bord !",
        "Content_WELCOME_2": "Votre compte \"{name}\" est prêt  !",
        "Content_ENCRYPTED": "Des informations chiffrées sont disponibles.",
        "Content_FILESET_DIRADD": "{ownerName} a créé le dossier \"{path}\" dans le coffre \"{fName}\"",
        "Content_FILESET_FILEADD": "{ownerName} a ajouté les fichiers \"{fileNames}\" dans le coffre \"{fName}/{path}\"",
        "Content_FILESET_FILEREMOVE": "{ownerName} a supprimé \"{path}\" dans le coffre \"{fName}\"",
        "Subject_NEWSHARE": "Un nouveau coffre \"{fName}\" est disponible",
        "Content_NEWSHARE": "{ownerName} a partagé le coffre \"{fName}\" avec vous.",
        "Subject_ADDGUEST": "Des informations confidentielles pour vous ({fName})",
        "Content_ADDGUEST_1": "\"{ownerName}\" a partagé de l'information avec vous. Le nom du fichier partagè est \"{fName}\".",
        "Content_ADDGUEST_2": "Pour accepter le partage, connectez vous ici",
        "Subject_ADDREGISTEREDGUEST": "\"{ownerName}\" vous propose une vue du coffre \"{fName}\"",
        "Content_ADDREGISTEREDGUEST": "\"{ownerName}\" vous propose de demander l'accès au coffre \"{fName}\".",
        "Subject_TESTEMAIL": "Email test",
        "Content_TESTEMAIL_1": "This is an email test for you.",
        "Content_TESTEMAIL_2": "You have nothing to do",
        "Subject_DROPFROMSHARE": "Votre accès au coffre \"{fName}\" a été révoqué",
        "Content_DROPFROMSHARE": "Votre accès au coffre \"{fName}\" a été révoqué par \"{ownerName}\".",
        "Subject_WANTED": "\"{ownerName}\" souhaite un accès au coffre \"{fName}\"",
        "Content_WANTED": "\"{ownerName}\" souhaite un accès au coffre \"{fName}\".",
        "Subject_ACCEPTWANTED": "Accès au coffre \"{fName}\" accepté par \"{ownerName}\"",
        "Content_ACCEPTWANTED": "\"{ownerName}\" a accepté votre requête et vous donne l'acces au coffre \"{fName}\".",
        "Subject_CANCELWANTED": "Accès au coffre \"{fName}\" refusé par \"{ownerName}\"",
        "Content_CANCELWANTED": "\"{ownerName}\" a annulé votre demande d'acces au coffre \"{fName}\".",
        "Subject_ACCOUNTCREATED": "votre compte \"{login}\" est créé",
        "Content_ACCOUNTCREATED": "votre compte \"{login}\" est créé. Bienvenue !",
        "Subject_ACCOUNTLOCKED": "votre compte \"{login}\" est bloqué",
        "Content_ACCOUNTLOCKED": "votre compte \"{login}\"a été bloqué. Contactez votre administrateur",
        "Content_ACCOUNTUNLOCKED": "votre compte \"{login}\" a été débloqué. Ravis de vous revoir",
        "Subject_REINITPASS": "Réinitialisation de mot de passe pour \"{name}\"",
        "Content_REINITPASS": "Votre mot de passe et vos clefs cryptographiques ont été supprimés. Merci de cliauer sur ce lien pour en générer de nouveaux",
        "Subject_EMAILDELETED": "L'email \"{email}\" est effacé de votre compte",
        "Content_EMAILDELETED": "Votre email désormais plus valide pour votre compte \"{name}\".",
        "Subject_CONFIRMEMAIL": "Votre email \"{email}\" est bientôt créé",
        "Content_CONFIRMEMAIL_1": "Dernière étape pour ajouter cet email à votre compte \"{login}\".",
        "Content_CONFIRMEMAIL_2": "Merci de confirmer votre email en cliquant sur le lien suivant : ",
        "Subject_CONFIRMEDEMAIL": "Votre email \"{email}\" est confirmé",
        "Content_CONFIRMEDEMAIL": "Votre email \"{email}\" est prêt pour votre compte \"{login}\".",
        "Subject_SIGNUP": "Votre compte \"{login}\" est presque créé",
        "Content_SIGNUP_1": "Dernière étape pour créer votre compte.",
        "Content_SIGNUP_2": "Merci de confirmer votre email en cliquant sur le lien suivant : ",
        "Subject_NEW_ACCOUNT_PASSWORD": "Votre compte est prêt",
        "Content_NEW_ACCOUNT_PASSWORD": "Un nouveau compte a été créé pour vous, votre mot de passe temporaire est le suivant : \"{password}\". ",
        "Subject_DROPGUESTFROMSHARE": "Des informations confidentielles pour vous ont été annulées ({fName})",
        "Content_DROPGUESTFROMSHARE": "\"{ownerName}\" a finalement refusé votre demande d'accès au coffre \"{fName}\"",
        "Subject_NEWURL": "Une invitation pour envoyer des fichiers",
        "Content_NEWURL": "\"{ownerName}\" vous invite à lui déposer des fichiers en utilisant le lien que vous trouverez ci-dessous.",
        "Subject_NEWCHAT": "Un nouveau message chiffré pour vous \"{ownerName}\"",
        "Content_NEWCHAT": "\"{ownerName}\" vous a envoyé un message, merci de suivre le lien ci-dessous pour le consulter.",
    }
};

export const sms = {
    "fr": {
        "Verification code": "Voici votre code de validation : \"{otp}\"",
        "A code has been sent to your phone": "Un code a été envoyé sur votre téléphone"
    }
};