export const WITH_EMITCHANGE = true;
export const WITHOUT_EMITCHANGE = false;

export default class ClientStore {

    constructor() {
        this.storeName = 'generic';
        this.options = {
            defaultVueLevel: 0,
            maxVueLevel: 1,
            defaultRange: [0, 50]
        }
        this.syncMaster = undefined;
        this.data = {};
        this.emitChangeInfos = this.initEmitchangeInfos();
        this.onSyncInfo = this.onSyncInfo.bind(this);
        this.reInit = this.reInit.bind(this);
        this.clear = this.clear.bind(this);

        // --- To check if the store is filled or empty
        this.isFilled = false;

        // --- List of listeners on changements
        this.listeners = [];

        // --- cache for select ---
        this.selectCache = {};

        // --- to prepare requests ---
        this.ask = {};

        // --- isWorking ?
        this.isWorking = false;

        // --- Debug in case of ---
        this.debugFunc = undefined;
    }


    setDebugFunc(f) {
        this.debugFunc = f;
    }

    debug(type, message, data) {
        if (!this.debugFunc) return false;
        this.debugFunc(type, message, data)
    }

    /**
     * Return true if the store is initialised
     *
     */
    get filled() {
        return this.isFilled;
    }

    /**
     * return true if the store is filled
     * but without any data
     *
     */
    get empty() {
        if (this.isFilled === false) return true;
        if (Object.keys(this.data).length == 0) return true;
        return false;
    }

    get count() {
        return Object.keys(this.data).length;
    }
    /**
     *
     * add a Listener on all changements, new ids, or updates
     *
     * @param {function} func
     *
     */
    addListener(func) {
        this.debug('Store', `Adding listener to ${this.storeName}`);
        this.listeners.push(func);
    }
    addChangeListener(func) {
        return this.addListener(func);
    }

    removeListener(func) {
        const index = this.listeners.indexOf(func);
        if (index > -1) this.listeners.splice(index, 1);
    }
    removeChangeListener(func) {
        return this.removeListener(func);
    }

    emitChange() {
        // --- No changements ???
        if ((this.emitChangeInfos['delete'].length == 0) &&
            (this.emitChangeInfos['update'].length == 0) &&
            (this.emitChangeInfos['new'].length == 0)) return false;

        const change = {
            'delete': this.emitChangeInfos['delete'].slice(0),
            'update': this.emitChangeInfos['update'].slice(0),
            'new': this.emitChangeInfos['new'].slice(0),
        }

        // this.debug('Store', `${this.storeName} emitchange`, this.listeners);

        this.debug('Store', `${this.storeName} emitchange`, change);
        this.emitChangeInfos = this.initEmitchangeInfos();

        for (const func of this.listeners) {
            func(change);
        }

        return true;
    }

    /**
     * called by the SyncMaster when some updates comes
     * from another user.
     *
     * @param {object} syncInfos
     */
    async onSyncInfo(syncInfos) {
        this.debug('Store', `Store ${this.storeName} got update from others`, syncInfos);

        /* if ((syncInfos.delete.length == 0) &&
            (syncInfos.update.length == 0) &&
            (syncInfos.new.length == 0)) return false; */

        // --- delete _ids ---
        if (syncInfos.delete) {
            for (const _id of syncInfos.delete) {
                delete(this.data[_id]);
                this.clearAllCacheForId(_id);
                this.addDeleteToEmitChange(_id);
            }
        }

        // --- new _ids ---
        if (syncInfos.new) {
            for (const _id of syncInfos.new) {
                if (( syncInfos.delete ) && ( syncInfos.delete.indexOf(_id) >=0  )) continue;
                if (( syncInfos.update ) && ( syncInfos.update.indexOf(_id) >=0 )) continue;
                this.addNewToEmitChange(_id);
                this.clearCache();
            }
        }

        // --- sync updates -----
        if (syncInfos.update) {
            // --- Sort updates by vueLevel --
            const getByVue = {};
            for (const _id of syncInfos.update) {
                if (( syncInfos.delete ) && ( syncInfos.delete.indexOf(_id) >=0 )) continue;
                const vueLevel = this.data[_id] ? this.data[_id].vue : this.options.defaultVueLevel;
                if (!getByVue[vueLevel]) getByVue[vueLevel] = [];
                getByVue[vueLevel].push(_id);
            }

            // --- ask the server for the element ---
            // --- if the element is not returned, drop it from localstore. it means
            // --- this update make the element not visible for me.
            let found = {};
            for (const vueLevel in getByVue) {
                const els = await this.httGetIds(getByVue[vueLevel], vueLevel);
                if (els) {
                    if (Array.isArray(els)) {
                        for (const el of els) {
                            let _id = this.getId(el);
                            found[_id] = true;
                            await this.updateStore(el, vueLevel);
                        }
                    } else {
                        let _id = this.getId(els);
                        found[_id] = true;
                        await this.updateStore(els, vueLevel);
                    }
                    for (const _id of syncInfos.update) {
                        if (found[_id]) continue;
                        await this.drop(_id);
                        this.addDeleteToEmitChange(_id);
                    }
                } else {
                    await this.drop(getByVue[vueLevel]);
                    for ( const _id of getByVue[vueLevel]) this.addDeleteToEmitChange(_id);
                }

            }
        }

        // --- Dont add new because not sure the app want them.
        this.debug(`syncInfo  ${this.storeName} do emitChange`, this.emitChangeInfos);
        this.emitChange();
    }

    /**
     * link with the syncMaster to get updates
     * from server.
     *
     * @param {SyncMaster} syncMaster
     */
    registerTo(syncMaster) {
        this.syncMaster = syncMaster;
        this.syncMaster.onSyncInfo[this.storeName] = this.onSyncInfo;
        this.syncMaster.onLeave[this.storeName] = this.clear;
        this.syncMaster.stores[this.storeName] = this;
    }

    getStoreByName(name) {
        return this.syncMaster.stores[name];
    }

    /**
     * erase thoses elements from store
     *
     * @param {string|[string]} _ids
     */
    async drop(_ids, emitChange = WITHOUT_EMITCHANGE) {

        if (!_ids) {
            this.clearCache();
            await this.syncMaster.httpGet(`/schmlux/${this.storeName}`);
            const currentIds = Object.keys(this.data);
            this.data = {};
            if (emitChange) {
                if (currentIds.length > 0) {
                    for (const _id of currentIds) {
                        this.addDeleteToEmitChange(_id);
                    }
                }
            }
            if (emitChange) this.emitChange();
            return true;
        }

        const sIds = Array.isArray(_ids) ? _ids.join(',') : _ids;
        await this.syncMaster.httpGet(`/schmlux/${this.storeName}/${sIds}`);
        if (Array.isArray(_ids)) {
            for (const _id of _ids) {
                delete(this.data[_id]);
                this.clearAllCacheForId(_id);
                if (emitChange) this.addDeleteToEmitChange(_id);
            }
        } else {
            delete(this.data[_ids]);
            this.clearAllCacheForId(_ids);
            if (emitChange) this.addDeleteToEmitChange(_ids);
        }
        if (emitChange) this.emitChange();
        return true;
    }


    clear() {
        this.data = {};
        this.clearCache();
    }

    /**
     * re initialisation of the store
     */
    async reInit() {
        await this.drop()
            .catch(() => {});
        this.isFilled = false;
        this.isWorking = false;
        return true;
    }

    httGetIds(_ids, vueLevel) {
        if (!this.syncMaster) throw new Error(`${this.storeName} not registerd to a syncManager !`)
        const sIds = Array.isArray(_ids) ? _ids.join(',') : _ids;
        return this.syncMaster.httpGet(`/${this.storeName}/${sIds}`, {
                vue: vueLevel
            })
            .catch((e) => {
                this.isFilled = true;
                this.isWorking = false;
                throw e;
            });
    }

    httpPatchIds(_ids, fields, vueLevel) {
        if (!this.syncMaster) throw new Error(`${this.storeName} not registerd to a syncManager !`)
        const sIds = Array.isArray(_ids) ? _ids.join(',') : _ids;

        return this.syncMaster.httpPut(`/${this.storeName}/${sIds}`, {
            vue: vueLevel
        }, fields);
    }

    httpCreate(el, vueLevel) {
        if (!this.syncMaster) throw new Error(`${this.storeName} not registerd to a syncManager !`)

        return this.syncMaster.httpPost(`/${this.storeName}`, {
            vue: vueLevel
        }, el);
    }

    httpAction(d, vueLevel) {
        if (!this.syncMaster) throw new Error(`${this.storeName} not registerd to a syncManager !`)
        const sIds = Array.isArray(d._ids) ? d._ids.join(',') : d._ids;

        const url = d._ids ? `/schmluxaction/${this.storeName}/${d.name}/${sIds}` : `/schmluxaction/${this.storeName}/${d.name}`;

        switch (d.method) {
            case 'post':
                return this.syncMaster.httpPost(url, {
                    vue: vueLevel
                }, d.data, d.auth);
            case 'get':
                return this.syncMaster.httpGet(url, {
                    vue: vueLevel
                }, d.auth);
            case 'put':
                return this.syncMaster.httpPut(url, {
                    vue: vueLevel
                }, d.data, d.auth);
            case 'delete':
                return this.syncMaster.httpDelete(url, d.auth);
            default:
                throw new Error(`Unknown method ${d.method}`)
        }
    }

    httpDeleteIds(_ids) {
        if (!this.syncMaster) throw new Error(`${this.storeName} not registerd to a syncManager !`)

        const sIds = Array.isArray(_ids) ? _ids.join(',') : _ids;
        return this.syncMaster.httpDelete(`/${this.storeName}/${sIds}`);
    }


    httpPost(url, data, auth) {
        return this.syncMaster.httpDirectPost(url, {
            vue: 0
        }, data, auth);
    }
    httpGet(url, data, auth) {
        return this.syncMaster.httpDirectGet(url, {
            vue: 0
        }, data, auth);
    }
    httpPut(url, data, auth) {
        return this.syncMaster.httpDirectPut(url, {
            vue: 0
        }, data, auth);
    }
    httpDelete(url, auth) {
        return this.syncMaster.httpDirectDelete(url, auth);
    }



    httpSelect(filter, range, order, vueLevel) {
        if (!this.syncMaster) throw new Error(`${this.storeName} not registerd to a syncManager !`)
        const query = {
            'search': true,
            'vue': vueLevel,
            'range': range,
        }

        // --- Build the order query ---
        if (order) {
            const field = Object.keys(order)[0];
            const direction = order[field];
            const o = direction == -1 ? 'desSort' : 'ascSort';
            query[o] = field;
        }

        if (range) query.range = range;

        for (const f in filter) {
            query[f] = filter[f];
        }

        return this.syncMaster.httpGet(`/${this.storeName}`, query);
    }

    initEmitchangeInfos() {
        return ({
            'delete': [],
            'update': [],
            'new': [],
        });
    }
    addNewToEmitChange(_id) {
        this.debug('Store', ` ${this.storeName} addNewToEmitChange ${_id}`);
        if (this.emitChangeInfos.new.indexOf(_id) == -1) this.emitChangeInfos.new.push(_id);
    }
    addDeleteToEmitChange(_id) {
        this.debug('Store', ` ${this.storeName} addDeleteToEmitChange ${_id}`);
        if (this.emitChangeInfos.delete.indexOf(_id) == -1) this.emitChangeInfos.delete.push(_id);
    }
    addUpdateToEmitChange(_id) {
        this.debug('Store', ` ${this.storeName} addUpdateToEmitChange ${_id}`);
        if (this.emitChangeInfos.update.indexOf(_id) == -1) this.emitChangeInfos.update.push(_id);
    }

    getId(element) {
        return element._id;
    }

    addToStore(_id, e, vueLevel, emitChange = true) {
        this.isFilled = true;
        const v = vueLevel ? vueLevel : this.options.defaultVueLevel;
        const update = this.data[_id] ? true : false;
        this.data[_id] = {
            'vue': v,
            'el': e
        }

        if (emitChange) {
            if (update) {
                this.addUpdateToEmitChange(_id);
            } else {
                this.addNewToEmitChange(_id);
            }
        }
        return e;
    }


    async updateStore(element, vueLevel, emitChange = true) {
        this.isFilled = true;
        const _id = this.getId(element);
        const v = vueLevel ? vueLevel : this.options.defaultVueLevel;

        let e = undefined;
        // --- A new element ---
        if (!this.data[_id]) {
            e = await this.append(element);
            this.data[_id] = {
                'vue': v,
                'el': e
            }
            this.debug('Store', `update Store  ${this.storeName} (append) ${_id} ${emitChange}`);

            if (emitChange) this.addNewToEmitChange(_id);
            return e;
        }

        // --- An existing element --
        const oldVueLevel = this.data[_id].vue;
        const newVueLevel = (v > oldVueLevel) ? v : oldVueLevel;
        e = await this.merge(this.data[_id].el, element, oldVueLevel, newVueLevel);
        this.data[_id] = {
            'vue': newVueLevel,
            'el': e
        }
        this.debug('Store', `update Store  ${this.storeName} (merge) ${_id}`, emitChange);
        if (emitChange) this.addUpdateToEmitChange(_id);
        return e;
    }

    append(el, /* vueLevel */ ) {
        return (el);
    }

    // eslint-disable-next-line require-await
    async merge(oldEl, newEl, /* oldVueLevel, newVueLevel */ ) {
        return newEl;
    }

    /*
        this.ask['select'][selectionId] = {
            "_id": selectionId,
            "vueLevel": vueLevel,
            "filter": filter,
            "range": r,
            "descSort": d,
        }
    */

    goForSelect(slist) {
        const proms = [];
        for (const selectId in slist) {
            const s = slist[selectId];
            proms.push(
                this.httpSelect(s.filter, s.range, s.descSort, s.ascSort, s.vueLevel)
                .then(async (results) => {
                    if (!results) return false;

                    this.addSelectToCache(s.filter, s.range, s.descSort, s.vueLevel,
                        results.results, results.count, results.suggestions);

                    for (const el of results.results) {
                        await this.updateStore(el, s.vueLevel, WITH_EMITCHANGE);
                    }
                }))

        }
        return proms;
    }

    goForDelete(_ids) {
        const proms = [];
        proms.push(
            this.httpDelete(_ids)
            .then(async (deletedIds) => {
                if (deletedIds) {
                    await this.collateralDelete(deletedIds, true);
                    for (const _id of deletedIds) {
                        delete(this.data[_id]);
                        this.clearAllCacheForId(_id);
                        this.addDeleteToEmitChange(_id);
                    }
                }
            })

        );
        return proms;
    }

    goForIds(sList) {
        const proms = [];
        for (const vueLevel in sList) {
            const _ids = sList[vueLevel].slice(0);
            proms.push(
                this.httGetIds(_ids, vueLevel)
                .then(async (el) => {
                    this.isFilled = true;
                    if (el) {
                        await this.updateStore(el, vueLevel, WITH_EMITCHANGE);
                    }
                })
            );
        }
        return proms;
    }

    goForUpdate(uList) {
        const proms = [];

        for (const update of uList) {
            proms.push(
                this.httpPatchIds(update._ids, update.fields, update.vueLevel)
                .then(async (elements) => {
                    if (elements) {
                        for (const element of elements) {
                            await this.updateStore(element, update.vueLevel, WITH_EMITCHANGE);
                        }
                    }
                })
            );
        }

        return proms;
    }

    go() {
        let proms = [];
        for (const type in this.ask) {
            let p = undefined;

            switch (type) {
                case 'select':
                    p = this.goForSelect(this.ask.select)
                    break;
                case 'update':
                    p = this.goForUpdate(this.ask.update)
                    break;
                case 'ids':
                    p = this.goForIds(this.ask.ids)
                    break;
                case 'delete':
                    p = this.goForDelete(this.ask.delete)
                    break;
                default:
                    break;
            }
            if (p) proms = proms.concat(p);
        }

        this.ask = {};
        if (proms.length == 0) return true;

        this.isWorking = true;

        Promise.all(proms)
            .then(() => {
                this.isWorking = false;
                this.isFilled = true;
                this.emitChange();
            })
            .catch((e) => {
                this.isWorking = false;
                this.isFilled = true;
                throw e;
            })
        return true;
    }

    getInStoreIds() {
        return Object.keys(this.data ? this.data : {})
    }

    get _ids() {
        return this.getInStoreIds();
    }

    /**
     *  Get an element by _id in the store
     *  if not found return undefined
     *
     * @param {string} _id
     * @param {int} givenVueLevel
     */
    getInStoreById(_id, givenVueLevel) {
        const vueLevel = givenVueLevel === undefined ? this.options.defaultVueLevel : givenVueLevel;
        // --- Got the element in the store, return it;

        if ((this.data[_id]) && (this.data[_id].vue >= vueLevel)) return this.data[_id].el;
        return undefined;
    }

    /**
     *
     * Get an element from the store
     * if the element is not in the store,
     * get from the server, wait for the answer
     * and return the element
     *
     * @param {string} _id
     * @param {int} givenVueLevel
     * @param {boolan} emitChange if want to emit a changement or not
     */
    async getById(_id, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {

        const vueLevel = givenVueLevel === undefined ? this.options.defaultVueLevel : givenVueLevel;

        // --- Got the element in the store, return it;
        if ((this.data[_id]) && (this.data[_id].vue >= vueLevel)) {
            this.debug('Store', `getById  ${this.storeName}/${_id} in cache emitChange ${emitChange}`);
            if (emitChange) this.emitChange();
            return this.data[_id].el;
        }
        this.debug('Store', `getById  ${this.storeName}/${_id} start http with emitchange=${emitChange}`);

        this.isWorking = true;

        // --- ask the server for the element ---
        const el = await this.httGetIds(_id, vueLevel);
        this.isFilled = true;
        let e = undefined;
        if (el) {
            this.debug('Store', `getById  ${this.storeName}/${_id} download emitChange ${emitChange}`);
            e = await this.updateStore(el, vueLevel, emitChange);
            if (emitChange) this.emitChange();
        }
        this.isWorking = false;
        return e;
    }

    /**
     *
     * Get an element from the store
     * if the element is not in the store,
     * get from the server, wait for the answer
     * and return the element
     *
     * @param {string} _id
     * @param {int} givenVueLevel
     * @param {boolan} emitChange if want to emit a changement or not
     */
    async getByIds(_ids, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {

        const vueLevel = givenVueLevel === undefined ? this.options.defaultVueLevel : givenVueLevel;

        const myIds = Array.isArray(_ids) ? _ids : [_ids];
        const _idsToSearch = [];
        const elements = [];

        // --- Got the element in the store, return it;
        for (const _id of myIds) {
            if ((this.data[_id]) && (this.data[_id].vue >= vueLevel)) {
                elements.push(this.data[_id].el);
                continue;
            }
            _idsToSearch.push(_id);
        }

        if (_idsToSearch.length == 0) {
            if (emitChange) this.emitChange();
            return elements;
        }

        this.isWorking = true;
        // --- ask the server for the element ---
        const els = await this.httGetIds(_idsToSearch, vueLevel);
        this.isFilled = true;

        const myEls = Array.isArray(els) ? els : [els];
        for (const el of myEls) {
            if (el) {
                this.debug('Store', `getByIds  ${this.storeName} download emitChange ${emitChange}`);

                const e = await this.updateStore(el, vueLevel, emitChange);
                if (e) elements.push(e);
            }

        }

        this.isWorking = false;

        if (elements.length > 0) {
            if (emitChange) this.emitChange();
        }
        return elements;
    }



    getByIdsToStore(_ids, givenVueLevel) {
        const vueLevel = givenVueLevel === undefined ? this.options.defaultVueLevel : givenVueLevel;

        if (!this.ask['ids']) this.ask['ids'] = {};
        if (!this.ask['ids'][vueLevel]) this.ask['ids'][vueLevel] = [];
        if (Array.isArray(_ids)) {
            for (const _id of _ids) {
                if (this.ask['ids'][vueLevel].indexOf(_id) == -1) this.ask['ids'][vueLevel].push(_id);
            }
        } else {
            if (this.ask['ids'][vueLevel].indexOf(_ids) == -1) this.ask['ids'][vueLevel].push(_ids);
        }
        return true;
    }



    /**
     *
     * @param {string|[strings]} _ids
     * @param {object} fields
     */
    async update(_ids, fields, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        this.isFilled = true;
        this.isWorking = true;
        const vueLevel = givenVueLevel === undefined ? this.options.maxVueLevel : givenVueLevel;

        const elements = await this.httpPatchIds(_ids, fields, vueLevel)
            .catch((e) => {
                this.isWorking = false;
                this.isFilled = true;
                throw e;
            });
        if (!elements) {
            this.isWorking = false;
            this.isFilled = true;
            return false;
        }
        const r = [];
        for (const element of elements) {
            this.debug('Store', `update  ${this.storeName} download emitChange ${emitChange}`);

            const e = await this.updateStore(element, vueLevel, emitChange);
            if (e) r.push(e);
        }
        this.isWorking = false;
        this.isFilled = true;
        if (emitChange) this.emitChange();
        return Array.isArray(_ids) ? r : r[0];
    }

    updateToStore(_ids, fields, givenVueLevel) {
        const vueLevel = givenVueLevel === undefined ? this.options.defaultVueLevel : givenVueLevel;

        if (!this.ask['update']) this.ask['update'] = [];
        this.ask['update'].push({
            "vueLevel": vueLevel,
            "_ids": _ids,
            "fields": fields
        })
        return true;
    }



    /**
     *
     * Create an object
     *
     * @param {object} element
     * @param {int} givenVueLevel The vue for the get after create
     */
    async create(element, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        this.isFilled = true;
        this.isWorking = true;
        const vueLevel = givenVueLevel === undefined ? this.options.maxVueLevel : givenVueLevel;

        const el = await this.httpCreate(element, vueLevel)
            .catch((e) => {
                this.isWorking = false;
                this.isFilled = true;
                throw e;
            });

        if (!el) {
            this.isWorking = false;
            this.isFilled = true;
            return false;
        }
        const e = await this.updateStore(el, vueLevel, WITH_EMITCHANGE);

        // --- Clear all cache because an new element can be potentialy
        // --- in any current select
        this.clearCache();

        this.isWorking = false;
        this.isFilled = true;
        if (emitChange) this.emitChange();
        return e;
    }

    /**
     *
     * delete an object
     *
     * @param {string|[strings]} _ids
     */
    async delete(_ids, emitChange = WITHOUT_EMITCHANGE) {
        this.isFilled = true;
        this.isWorking = true;

        const deletedIds = await this.httpDeleteIds(_ids)
            .catch((e) => {
                this.isWorking = false;
                this.isFilled = true;
                throw e;
            });

        if (!deletedIds) {
            this.isWorking = false;
            this.isFilled = true;
            return false;
        }
        await this.collateralDelete(deletedIds, emitChange);
        for (const _id of deletedIds) {
            delete(this.data[_id]);
            this.clearAllCacheForId(_id);
            if (emitChange) this.addDeleteToEmitChange(_id);
        }
        this.isWorking = false;
        this.isFilled = true;
        if (emitChange) this.emitChange();
        return deletedIds;
    }

    collateralDelete( /* _id,  emitChange = WITHOUT_EMITCHANGE */ ) {
        return true;
    }

    deleteToStore(_ids) {
        if (!this.ask['delete']) this.ask['delete'] = []
        if (Array.isArray(_ids)) {
            for (const _id of _ids) {
                if (this.ask['ids'].indexOf(_id) == -1) this.ask['ids'].push(_id);
            }
        } else {
            if (this.ask['ids'].indexOf(_ids) == -1) this.ask['ids'].push(_ids);
        }
        return true;
    }


    selectToStore(filter, range, descSort, givenVueLevel, givenSelectionId) {

        const selectionId = givenSelectionId === undefined ? 1 : givenSelectionId;
        const vueLevel = givenVueLevel === undefined ? this.options.defaultVueLevel : givenVueLevel;
        let r = undefined;
        if (range != 'all') {
            r = Array.isArray(range) ? `${range[0]}-${range[1]}` : `${this.options.defaultRange[0]}-${this.options.defaultRange[1]}`;
        }
        const d = Array.isArray(descSort) ? descSort.join(',') : descSort;

        if (!this.ask['select']) this.ask['select'] = {};

        this.ask['select'][selectionId] = {
            "_id": selectionId,
            "vueLevel": vueLevel,
            "filter": filter,
            "range": r,
            "descSort": d,
        }
        return true;
    }

    async select(filter, range, order, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        this.isFilled = true;
        this.isWorking = true;
        const vueLevel = givenVueLevel === undefined ? this.options.defaultVueLevel : givenVueLevel;

        let r = undefined;
        if (range != 'all') {
            r = Array.isArray(range) ? `${range[0]}-${range[1]}` : `${this.options.defaultRange[0]}-${this.options.defaultRange[1]}`;
        }
        const d = order;

        // --- Got in cache ? ---
        const resultInCache = this.getFromCache(filter, r, d, vueLevel);
        if (resultInCache) {
            this.isWorking = false;
            this.isFilled = true;
            if (emitChange) this.emitChange();
            return resultInCache;
        }

        const result = await this.httpSelect(filter, r, d, vueLevel)
            .catch((e) => {
                this.isWorking = false;
                this.isFilled = true;
                throw e;
            });

        if (!result) {
            this.isWorking = false;
            this.isFilled = true;
            if (emitChange) this.emitChange();
            return false;
        }

        const rr = [];
        for (const el of result.results) {
            this.debug('Store', `select  ${this.storeName} download emitChange ${emitChange}`);

            const e = await this.updateStore(el, vueLevel, emitChange);
            if (e) rr.push(e);
        }

        // --- Add to the cache ---
        this.addSelectToCache(filter, r, d, vueLevel, rr, result.count, result.suggestions);

        this.isWorking = false;
        this.isFilled = true;
        if (emitChange) this.emitChange();
        return ({
            'count': result.count,
            'results': rr,
            'suggestions': result.suggestions
        });
    }



    /*

     ██████╗ █████╗  ██████╗██╗  ██╗███████╗
    ██╔════╝██╔══██╗██╔════╝██║  ██║██╔════╝
    ██║     ███████║██║     ███████║█████╗
    ██║     ██╔══██║██║     ██╔══██║██╔══╝
    ╚██████╗██║  ██║╚██████╗██║  ██║███████╗
     ╚═════╝╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝╚══════╝

    */

    buildCacheIndex(select, range, order, givenVueLevel) {
        return JSON.stringify({
            's': select,
            'r': range,
            'o': order,
            'g': givenVueLevel
        });
    }

    // eslint-disable-next-line max-params
    addSelectToCache(select, range, order, givenVueLevel, elements, count, suggestions) {
        if (!elements) return false;
        if (count == 0) return false;
        if (!Array.isArray(elements)) return false;
        if (elements.length == 0) return false;

        const index = this.buildCacheIndex(select, range, order, givenVueLevel);
        const _ids = []
        for (const el of elements) {
            _ids.push(el._id);
        }
        this.selectCache[index] = {
            "_ids": _ids,
            "count": count,
            "givenVueLevel": givenVueLevel,
            "suggestions": suggestions
        };
        return true;
    }

    getFromCache(select, range, order, givenVueLevel) {
        const index = this.buildCacheIndex(select, range, order, givenVueLevel);
        if (!this.selectCache[index]) return false;
        const els = [];
        const vueLevel = this.selectCache[index].givenVueLevel;

        for (const _id of this.selectCache[index]._ids) {
            const el = this.getInStoreById(_id, vueLevel);
            if (!el) return false;
            els.push(el);
        }
        // --- return elements as a select ---
        return ({
            count: this.selectCache[index].count,
            results: els,
            suggestions: this.selectCache[index].suggestions
        })
    }

    deleteFromCache(select, range, order, givenVueLevel) {
        const index = this.buildCacheIndex(select, range, order, givenVueLevel);
        delete(this.selectCache[index]);
        return true;
    }

    clearAllCacheForId(_id) {
        for (const index in this.selectCache) {
            if (this.selectCache[index]._ids.indexOf(_id) >= 0) delete this.selectCache[index];
        }
    }

    clearCache() {
        this.selectCache = {}
    }

    /*
     █████╗  ██████╗████████╗██╗ ██████╗ ███╗   ██╗███████╗
    ██╔══██╗██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
    ███████║██║        ██║   ██║██║   ██║██╔██╗ ██║███████╗
    ██╔══██║██║        ██║   ██║██║   ██║██║╚██╗██║╚════██║
    ██║  ██║╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║███████║
    ╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
    */
    postAction(name, _ids, data, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        return this.action({
            'auth': true,
            'method': 'post',
            'name': name,
            '_ids': _ids,
            'data': data,
        }, givenVueLevel, emitChange);
    }
    postActionNoUpdate(name, _ids, data, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        return this.action({
            'auth': true,
            'method': 'post',
            'name': name,
            '_ids': _ids,
            'data': data,
            'noUpdateStore': true,
        }, givenVueLevel, emitChange);
    }
    putAction(name, _ids, data, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        return this.action({
            'auth': true,
            'method': 'put',
            'name': name,
            '_ids': _ids,
            'data': data,
        }, givenVueLevel, emitChange);
    }
    putActionNoUpdate(name, _ids, data, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        return this.action({
            'auth': true,
            'method': 'put',
            'name': name,
            '_ids': _ids,
            'noUpdateStore': true,
            'data': data,
        }, givenVueLevel, emitChange);
    }
    getAction(name, _ids, data, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        return this.action({
            'auth': true,
            'method': 'get',
            'name': name,
            '_ids': _ids,
            'data': data,
        }, givenVueLevel, emitChange);
    }
    getActionNoUpdate(name, _ids, data, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        return this.action({
            'auth': true,
            'method': 'get',
            'name': name,
            '_ids': _ids,
            'noUpdateStore': true,
            'data': data,
        }, givenVueLevel, emitChange);
    }
    deleteAction(name, _ids, data, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        return this.action({
            'auth': true,
            'method': 'delete',
            'name': name,
            '_ids': _ids,
            'data': data,
        }, givenVueLevel, emitChange);
    }

    postAnonAction(name, _ids, data, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        return this.action({
            'auth': false,
            'method': 'post',
            'name': name,
            '_ids': _ids,
            'data': data,
        }, givenVueLevel, emitChange);
    }
    putAnonAction(name, _ids, data, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        return this.action({
            'auth': false,
            'method': 'put',
            'name': name,
            '_ids': _ids,
            'data': data,
        }, givenVueLevel, emitChange);
    }
    getAnonAction(name, _ids, data, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        return this.action({
            'auth': false,
            'method': 'get',
            'name': name,
            '_ids': _ids,
            'data': data,
        }, givenVueLevel, emitChange);
    }
    deleteAnonAction(name, _ids, data, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        return this.action({
            'auth': false,
            'method': 'delete',
            'name': name,
            '_ids': _ids,
            'data': data,
        }, givenVueLevel, emitChange);
    }


    async action(d, givenVueLevel, emitChange = WITHOUT_EMITCHANGE) {
        this.isFilled = true;
        this.isWorking = true;
        const vueLevel = givenVueLevel === undefined ? this.options.defaultVueLevel : givenVueLevel;

        const elements = await this.httpAction(d, vueLevel)
            .catch((e) => {
                this.isWorking = false;
                this.isFilled = true;
                throw e;
            });


        // --- just get some data without updating store
        if (d.noUpdateStore === true) {
            this.isWorking = false;
            this.isFilled = true;
            return elements;
        }

        if (elements === true) return true;
        if (elements === false) return false;

        // --- A list of elements ---
        if (Array.isArray(elements)) {
            const r = [];
            for (const element of elements) {
                this.debug('Store', `action  ${this.storeName} download emitChange ${emitChange}`);

                const e = await this.updateStore(element, vueLevel, emitChange);
                if (e) r.push(e);
            }
            this.isWorking = false;
            this.isFilled = true;
            if (emitChange) this.emitChange();
            return r;
        }

        // --- One element ---
        if (elements) {
            this.debug('Store', `action  ${this.storeName} download emitChange ${emitChange}`);

            const e = await this.updateStore(elements, vueLevel, emitChange);
            this.isWorking = false;
            this.isFilled = true;
            if (emitChange) this.emitChange();
            return e;
        }

        this.isWorking = false;
        this.isFilled = true;
        return undefined;
    }

}