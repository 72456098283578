import stream from 'stream-browserify'
require('socket.io-client');
import React from 'react';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import Loadable from 'react-loadable';
import Loading from './components/M1/Lib/Loading.jsx'


import { AppContainer } from 'react-hot-loader';
import GlobalApp from './components/GlobalApp.jsx';


/*
const GlobalApp = Loadable({
  loader: () => import('./components/GlobalApp.jsx'),
  loading(props) {
    return (<Loading message="Loading general"  {...props} />);
  },
});
*/


const Lynvictus = Loadable({
  loader: () => import('./components/Lynvictus.jsx'),
  loading(props) {
    console.log(`loading Lyn`);
    return (<Loading message="Loading application"  {...props} />);
  }
});

let element = document.createElement('div');
element.id = "lynvictus";
element.style.width = '100%'
element.className = "lynvictusLoading";
document.body.appendChild(element);

const render = (Component) => {
  ReactDOM.render((
    <AppContainer>
      <BrowserRouter basename="/">
          <GlobalApp >
            <Component />
          </ GlobalApp>
      </BrowserRouter>
    </AppContainer>
  ), element);
};


render(Lynvictus);

// Hot Module Replacement API
if (module.hot) {
  console.log("hot module replacement enable");
  module.hot.accept('./components/Lynvictus.jsx', () => {
    console.log("Reload Lynvictus.jsx");
    render(Lynvictus);
  });
}
