import { LynError } from "../../../SharedLibs/LynError";

var myObjectPath = require("object-path");

import SingleStore from './SingleStore';

import {
    sMaster
} from './SyncMaster.js';

import {
    Hash,
    KeyStore
} from '@lybero/lybcrypt';

import {
    Checker
} from '../../../SharedLibs/Checker.js';
import {
    lang
} from '../../../SharedLibs/Langs.js';
import {
    AUTH,
    OBJECT_TYPE,
    USER_CREATION_STATES
} from '../../../SharedLibs/Constants.js';

class AccountStore extends SingleStore {
    constructor() {
        super();

        // --- Set the debug name --
        this.deb.setNames('AccountStore', 'account');

        this.storeName = 'account';
        this.objectType = OBJECT_TYPE.USER;

        this.saveKeyStore = this.saveKeyStore.bind(this);
        this.refresh = this.refresh.bind(this);
        this.uiPreference = undefined;
    }

    async signUp(data) {

        this.log('signUp', data);
        new Checker({
            "email": "string+", // ---------- The login
            "password": "string+",
            "filesetId": "string",
            "_pathname": "string",
            "confirmString": "string",
            "presetId": "string", // ---- The _id already Set by the host user
            "expire": "expire",
        }, 'signUp').checkThrow(data);

        let hash = new Hash(undefined, undefined, "sha256");
        let passphrase = data.password;

        data.login = data.email;
        data.lang = lang;
        if (!data.authType) data.authType = AUTH.LOCAL;
        if (data.authType === AUTH.LOCAL) {
            data.password = await hash.hash(passphrase);
        }

        return await this.postAnonAction('signup', undefined, data);
    }


    async wantReinitPassword(data) {
        new Checker({
            "email": "string+", // ---------- The login
            "_pathname": "string",
        }, 'wantReinitPassword').checkThrow(data);

        await this.postAnonAction('reinitlocal', undefined, data);
        return true;
    }

    async login(data) {
        this.log("login");

        new Checker({
            "login": "string+",
            "password": "string+"
        }, 'login').checkThrow(data);

        this.initGenericProgress("account", "Login in progress");
        let h = new Hash(undefined, undefined, "sha256");
        let derivedPassword = await h.hash(data.password);

        await this.httpPost("/auth/local", {
            "username": data.login,
            "password": derivedPassword
        });

        await this.getElement();
        await this.getOrCreateKeyStore(data.password);

        this.stopProgress("account");
        this.emitChange();
        sMaster.startSocket();
        return true;
    }


    async checkForAlreadyLogged() {
        await this.getElement()
            .catch(() => {});
        if (this.logged) sMaster.startSocket();
        return true;


    }

    async loginLdap(data) {
        this.log("Try Login LDAP");

        new Checker({
            "login": "string+",
            "password": "string+"
        }, 'login').checkThrow(data);

        this.initGenericProgress("account", "Login in progress");

        await this.httpPost("/auth/ldap", {
            "username": data.login,
            "password": data.password
        });

        await this.getElement();

        this.stopProgress("account");
        this.emitChange();
        sMaster.startSocket();
        return true;
    }


    async logout() {
        this.log("logout");
        await this.httpGet("/logout");
        let leavingStores = [];
        this.syncMaster.leaveStores(leavingStores);
        await Promise.all(leavingStores);
        return this.localLogout();
    }

    localLogout() {
        this.log("clear accountStore");
        delete this.data;
        delete this.keyStore;
        this.uiPreference = undefined;
        this.emitChange();
        return true;
    }

    /**
     * Suicide
     */
    async delete() {
        // ------------ Used for debbuging ----------------------------
        this.log("suicide user");
        this.initGenericProgress("account", "Suicide");
        await this.deleteAction('suicide');
        this.stopProgress("account");

        return this.logout();
    }

    /**
     * Send an test email for a user
     *
     */
    async testEmail(data) {
        this.log("testEmail", arguments);
        new Checker({
            "email": "email+",
        }, 'testEmail').checkThrow(data);
        this.initGenericProgress("account", "test Email");
        await this.postAction('sendemail', undefined, data);
        this.stopProgress("account");
        return true;
    }


    /**
     * Changing the passphrase
     * only used in case of AUTH.LOCAL
     *
     */
    async changePassword(data) {
        this.log("changePassword", arguments);

        new Checker({
            "password": "string+",
            "oldPassphrase": "string+",
        }, 'changePassword').checkThrow(data);

        this.log("changePassword");
        this.initGenericProgress("account", "change password");

        let passphrase = data.password;
        let hash = new Hash(undefined, undefined, "sha256");
        let password = await hash.hash(passphrase);
        let successfullChange = true;
        if (this.keyStore) {
            await this.keyStore.dropPrivateKeys();
            successfullChange = await this.keyStore.changePassphrase(passphrase, undefined, data.oldPassphrase);
        }
        if (successfullChange === false) {
            this.stopProgress("account");
            throw new LynError("Invalid password");
        }
        this.log("Sending new credentials to server", this.content);
        await this.postAction('changepassword', undefined, {
            "type": AUTH.LOCAL,
            "login": this.login,
            "password": password,
        });
        await this.refresh();
        this.stopProgress("account");
        return this.logout();
    }

    async refresh() {
        if (!this.syncMaster) throw new Error(`${this.storeName} not registerd to a syncManager !`)
        await this.syncMaster.httpGet(`/${this.storeName}`);
    }

    async reinitPassphrase(passphrase) {

        // TODO -----

        this.log("reinitPassphrase", arguments);

        if (!passphrase)
            return this.error("Need Passphrase");

        /*
        this.initGenericProgress("account", "Reinitialisation");
        let hash = new Hash(undefined, undefined, "sha256");
        let hashedPassphrase = await hash.hash(passphrase);

        await this.reinitKeyStore(passphrase);

        await this.send(ACTIONS.ACCOUNT.REINITKEYS_WHEN_LOGGED_IN, {
            "passphrase": hashedPassphrase,
            "login": accountStore.loginName
        })
        this.stopProgress("account");
*/

        return true;
    }

    async reinitPassword(data) {
        this.log("reinitPassword", arguments);
        new Checker({
            "password": "string+",
            "confirmString": "string+",
            "login": "string+",
        }, 'reinitPassword').checkThrow(data);

        this.initGenericProgress("account", "Password reinitialisation");

        let passphrase = data.password;
        let hash = new Hash(undefined, undefined, "sha256");
        let password = await hash.hash(passphrase);

        let d = {
            "password": password,
            "login": data.login,
            "authType": AUTH.LOCAL,
            "confirmString": data.confirmString,
        };

        await this.httpPost("/auth/local", d);

        await this.getElement();

        let keyStoreCreated = await this.getOrCreateKeyStore(passphrase);

        if (!keyStoreCreated) {
            await this.reinitKeyStore(passphrase);
        }

        await this.postAction('reinitkey', undefined, {
            "password": password,
            "login": data.login,
            "authType": AUTH.LOCAL,
            "confirmString": data.confirmString,
        });

        this.stopProgress("account");


        return true;
    }

    /**
     * return true if this user is member of one group
     * given in params.
     *
     * @param {[string]} gIds
     */
    isMemberOfOneOfThoseGroups(gIds = []) {
        if (!this.data) return [];
        for (let gId of gIds) {
            if (this.data.memberOf[gId]) return true;
        }
        return false;
    }

    /**
     * return true if the user is connected only one time
     * and with double auth
     */
    withSecondAuth() {
        if (this.data.loggedInformation.length === 0) return false;
        for (let s of this.data.loggedInformation) {
            if (!s.secondAuth) return false;
        }
        return true;
    }


    /*
    ██╗  ██╗███████╗██╗   ██╗███████╗████████╗ ██████╗ ██████╗ ███████╗
    ██║ ██╔╝██╔════╝╚██╗ ██╔╝██╔════╝╚══██╔══╝██╔═══██╗██╔══██╗██╔════╝
    █████╔╝ █████╗   ╚████╔╝ ███████╗   ██║   ██║   ██║██████╔╝█████╗
    ██╔═██╗ ██╔══╝    ╚██╔╝  ╚════██║   ██║   ██║   ██║██╔══██╗██╔══╝
    ██║  ██╗███████╗   ██║   ███████║   ██║   ╚██████╔╝██║  ██║███████╗
    ╚═╝  ╚═╝╚══════╝   ╚═╝   ╚══════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝
    */

    async getOrCreateKeyStore(password) {
        if (this.keyStore) {
            return false;
        }
        let keyStoreContent = await this.getAction('getmykeystore');
        this.log("getOrCreateKeyStore ", this._id);
        this.keyStore = new KeyStore(this._id, this.displayName);
        let keyStoreCreated = false;
        if (keyStoreContent) {
            await this.keyStore.setContent(keyStoreContent);
            if (password) await this.keyStore.releaseAllKeysWithPassphrase(password);
        } else {
            console.log("Create keystore");
            await this.keyStore.create(password);
            keyStoreCreated = true;
        }
        await this.keyStore.checkMemberQuorumAction()
            .catch((e) => {
                console.error(e);
            });
        return keyStoreCreated;
    }

    saveKeyStore(userId, keyStoreContent, keyStorePublicContent) {
        this.log("save keyStore for " + this.displayName);
        return this.postAction('updatekeystore', undefined, {
            "userId": userId,
            "keyStore": {
                "content": keyStoreContent,
                "publicContent": keyStorePublicContent,
            }
        });
    }

    async reinitKeyStore(passphrase) {
        this.log(`Reinit keystore for : ${accountStore._id} - ${accountStore.login}`);
        if (this.keyStore) {
            await this.keyStore.revokeKeys();
            let signKeyId = await this.keyStore.createSignKey(passphrase);
            await this.keyStore.protectPrivateKeyWithPassphrase(signKeyId, passphrase);
            let elGamalKeyId = await this.keyStore.createElGamalKey(passphrase);
            await this.keyStore.protectPrivateKeyWithPassphrase(elGamalKeyId, passphrase);
        } else {
            this.keyStore = new KeyStore(accountStore._id, accountStore.login);
            await this.keyStore.create(passphrase);
        }
        await this.keyStore.save();
    }

    /**
     *
     *  ██████╗ ███████╗████████╗████████╗███████╗██████╗ ███████╗
     * ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔════╝██╔══██╗██╔════╝
     * ██║  ███╗█████╗     ██║      ██║   █████╗  ██████╔╝███████╗
     * ██║   ██║██╔══╝     ██║      ██║   ██╔══╝  ██╔══██╗╚════██║
     * ╚██████╔╝███████╗   ██║      ██║   ███████╗██║  ██║███████║
     *  ╚═════╝ ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚═╝  ╚═╝╚══════╝
     *
     */

    get logged() {
        return this.data ? true : false;
    }

    get quorumInfos() {
        if (!this.keyStore) return {};
        return this.keyStore.quorumInfos;
    }

    get quorumIds() {
        return Object.keys(this.quorumInfos);
    }

    get memberOf() {
        if (this.data == null) return {};
        return (this.data.memberOf);
    }

    get memberOfList() {
        if (this.data == null) return {};
        return (Object.keys(this.data.memberOf));
    }

    get displayName() {
        return this.getField('displayName');
    }

    get loginName() {
        return this.getField('login');
    }

    get emails() {
        return this.getField("contacts.emails", []);
    }

    get phones() {
        return this.getField("contacts.phones", []);
    }

    get avatar() {
        return this.getField("avatar", {});
    }

    get authType() {
        return this.getField("authType", undefined);
    }

    get mustChangePass() {
        /* Version BW Bertrand without workflows for user creation */
        /* To be put in place */
        /* return this.getField("flags.mustChangePass", false); */
        if (this.authType === AUTH.LOCAL) {
            /* AL, 03/03/2021 - when starting, property state does not exist ()
               it should be initialized but isn't then I test if it exists */
            if (this.data.workflows.creation) {
                if (this.data.workflows.creation.state) {
                    return this.data.workflows.creation.state === USER_CREATION_STATES.PASSWORD;
                }
            }
            return (false);
        }
        // ---- Oauth and no keys set ? set keys before ! ---
        if (this.authType === AUTH.GOOGLE) {
            // TODO
        }
        return false;
    }

    get allU2fDevices() {
        return false;
    }

    get clientId() {
        // TODO usable ?
        return undefined;
    }

    get owner() {
        if (this.data == null) return {
            _id: undefined,
            "login": undefined
        };
        return ({
            _id: this.data._id,
            "login": this.data.login
        });
    }

    get ctime() {
        return this.data.ctime
    }

    get expire() {
        return this.data.expire
    }

    /**
     *
     * ██╗   ██╗██╗    ██████╗ ██████╗ ███████╗███████╗
     * ██║   ██║██║    ██╔══██╗██╔══██╗██╔════╝██╔════╝
     * ██║   ██║██║    ██████╔╝██████╔╝█████╗  █████╗
     * ██║   ██║██║    ██╔═══╝ ██╔══██╗██╔══╝  ██╔══╝
     * ╚██████╔╝██║    ██║     ██║  ██║███████╗██║
     *  ╚═════╝ ╚═╝    ╚═╝     ╚═╝  ╚═╝╚══════╝╚═╝
     */


    /**
     *
     * @param {*} key
     * @param {*} value
     */
    setUiPreference(key, value) {
        if (!this.uiPreference) this.loadPrefFromLocalStorage();
        myObjectPath.set(this.uiPreference, key, value);
        // --------- save into the localStorage ------
        if ((typeof(localStorage) !== 'undefined') && (this.logged)) {
            localStorage.setItem("LynUiPreference_" + this._id, JSON.stringify(this.uiPreference));
            this.log("saving preference into the localStorage ", JSON.stringify(this.uiPreference));
        }
    }


    getUiPreference(key, valueByDefault = undefined) {
        if (!this.uiPreference) this.loadPrefFromLocalStorage();
        return myObjectPath.get(this.uiPreference, key, valueByDefault);
    }


    loadPrefFromLocalStorage() {
        this.uiPreference = {};
        if ((typeof(localStorage) !== 'undefined') && (this.logged)) {
            let storageString = localStorage.getItem("LynUiPreference_" + this._id);
            if (storageString !== null) {
                this.uiPreference = JSON.parse(storageString);
                this.log("Loading uiPreference from localStorage", this.uiPreference);
            }
        }

    }

}

export var accountStore = new AccountStore();
accountStore.registerTo(sMaster);