import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Zoom from '@material-ui/core/Zoom';
import PropTypes from 'prop-types';
import React from 'react';
import { MyComponent } from '../../MyComponent.jsx';
import { translate } from '../../../../../SharedLibs/Langs.js';

const styles = {
	errorTitle: {
		fontSize: 35,
		fontWeight: "bold"
	}
};

class AskDisplay extends MyComponent {

	constructor(props) {
		super(props);

		this.setMessage = this.setMessage.bind(this);
		this.closeMessage = this.closeMessage.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onClick = this.onClick.bind(this);

		this.state = {
			"open": false,
			"label": undefined,
			"title": undefined,
			"text": "",
			"errorMessage": undefined,
			"helperText": undefined,
		};
		this.verifFunc = undefined;
		this.onChange = undefined;

	}

	closeMessage() {
		this.setState({ "text": "", "title": undefined, "label": undefined, 'open': false });
	}

	/**
	* Set the way to contact me
	*/
	componentDidMount() {
		this.verifFunc = undefined;
		this.onChange = undefined;
		this.props.global.registerFunction('ask', this.setMessage)
	}

	/**
	* Unregister the setMessage function
	*/
	componentWillUnmount() {
		this.props.global.registerFunction('ask', undefined)
	}


	/**
	* Add a label to display
	*
	*/
	// eslint-disable-next-line max-params
	setMessage(title, label, okLabel, onChange, verifFunc, defaultValue = undefined) {
		this.log(`setMessage from askDisplay`, arguments)
		let t = title ? title : translate("Ask");
		this.verifFunc = verifFunc;
		this.onChange = onChange;
		this.setState({ "title": t, "label": label, 'okLabel': okLabel, 'open': true, 'text' : defaultValue });
	}

	handleChange(event) {
		let v = event.target.value;
		if(this.verifFunc){
			this.verifFunc(v)
				.then((helperText) => {
					this.setState({helperText: helperText })
				})
		}
		return this.setState({text: event.target.value})
	}

	onClick() {
		let t = this.state.text;
		this.closeMessage();
		return this.onChange(t)
	}

	render() {
		return (
			<Dialog className="askDir"
				key="path"
				open={this.state.open}
				TransitionComponent={Zoom}
			>
				<DialogTitle>{<span className="confirmTitle">{this.state.title}</span>}</DialogTitle>
				<DialogContent>
					<form id="mkdirForm" key="mkdirFormKey" onSubmit={(event) => {
						event.preventDefault();
						this.onClick();
					}}>
						<TextField
							key="dir"
							id="dir"
							name="dir"
							label={this.state.label}
							value={this.state.text}
							onChange={this.handleChange}
							helperText={this.state.helperText}
							autoFocus
						/>
					</form>
				</DialogContent>
				<DialogActions>
					[<Button id="dirCancel" key="cancel"
						variant="contained"
						color="secondary"
						disabled={false}
						onClick={this.closeMessage}

					>{translate("Cancel")}</Button>,
				<Button id="dirSubmit" key="ok"
						variant="contained"
						color="primary"
						disabled={this.state.helperText ? true : this.state.text ? false : true}
						onClick={this.onClick}
					>
						{this.state.okLabel}
					</Button>
					]
			</DialogActions>
			</Dialog>
		);


	}
}

AskDisplay.propTypes = {
	'global': PropTypes.object,
	'classes': PropTypes.object,
};

export default withStyles(styles)(AskDisplay)